import React, { Component } from "react";
import { Header, Table, Button, Modal, Input } from "semantic-ui-react";
import _ from "underscore";
import "./AdminUnit.css";
import Errorpop from "../error/Errorpop";

export default class AdminUnit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      unitData: [],
      addUnitOpen: null,
      newUnit: "",
      newCode: "",
      maxkey:null
    };
    this.handleAddUnit = this.handleAddUnit.bind(this);
    this.handleDelUnit = this.handleDelUnit.bind(this);
    this.submitNewUnit = this.submitNewUnit.bind(this);
    this.unitChange = this.unitChange.bind(this);
    this.codeChange = this.codeChange.bind(this);
    this.errorpop = React.createRef();
    this.submitAdd=this.submitAdd.bind(this);
    this.submitDel=this.submitDel.bind(this);
  }

  
  openAddUnit = () => {
    this.setState({ addUnitOpen: true });
  };

  closeAddUnit = () => {
    this.setState({ addUnitOpen: false });
  };

  handleAddUnit = () => {
    this.openAddUnit();
  };

  unitChange(event) {
    this.setState({ newUnit: event.target.value });
  }

  codeChange(event) {
    this.setState({ newCode: event.target.value });
  }
  
  submitAdd=(result)=>{
    if (result.status==='ERROR') 
    {
      console.log('error');
              this.errorpop.current.handleOpen();
      }
      else {
  var olddata = this.state.unitData;
  olddata.push({ key:this.state.maxkey+1, value: 'u'+(this.state.maxkey+1), text: this.state.newUnit  });
  
  this.setState({ unitData: olddata , newUnit: "", newCode: "", maxkey: this.state.maxkey+1 });
  console.log(olddata);
  this.closeAddUnit();
      }
  }


    
  submitDel=(result,key)=>{
    var unitList = this.state.unitData;
    if (result.status==='ERROR') 
    {
      console.log('error');
              this.errorpop.current.handleOpen();
      }
      else {
    unitList = _.reject(unitList, function(data) {
      return data.key === key;
    });
    this.setState({ unitData: unitList });
  }
  }
  


  submitNewUnit() {
    console.log(this.state.newUnit);
    this.addUnits(this.state.maxkey+1,'u'+(this.state.maxkey+1), this.state.newUnit)
    .then((result)=>this.submitAdd(result))
  }

  handleDelUnit = key => {
   this.delUnits(key).then((result)=>this.submitDel(result,key))
  //  console.log(this.state.testData)
  };


  getUnits = async () => {
    const response = await fetch("/api/getUnit", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  delUnits = async (key) => {
    const response = await fetch("/api/delUnit?key="+key, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  addUnits = async (key,unit,text) => {
    const response = await fetch("/api/addUnit?key="+key+"&unit="+unit+"&text="+text, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
//    if (response.status !== 200) throw Error(body.message);
    return body;
  };



  componentDidMount() {
    this.getUnits().then(res => {
      if (res.status==='ERROR'){
console.log('error');
        this.errorpop.current.handleOpen();
      }
      else {
      console.log(res);
      this.setState({ unitData: res });
      var maxkeynow=parseInt(_.max(res,function(ele){return ele.key}).key);
      if (maxkeynow) this.setState({maxkey:maxkeynow})
      else this.setState({maxkey:0});
      }
    });
  }

  render() {
    var unitTableRows = this.state.unitData.map((data, index) => {
      return (
        <Table.Row key={"u" + index}>
          <Table.Cell>{data.text}</Table.Cell>
          <Table.Cell>
            <Button onClick={() => this.handleDelUnit(data.key)}>
              {" "}
              Remove Unit{" "}
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });


    var AddUnitModal = (
      <Modal
        size="small"
        className="modal"
        open={this.state.addUnitOpen}
        onClose={this.closeAddUnit}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Create New Unit</Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Header as="h3"> Please Add New Unit </Header>{" "}
          </div>
          <div>
            {" "}
            <Input
              label="Unit Name"
              className="nameField"
              placeholder="Name"
              value={this.state.newUnit}
              onChange={this.unitChange}
            />
          </div>
          <div>
            {" "}
            <Button primary onClick={this.submitNewUnit}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeAddUnit}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    return (
      <div className='UnitBlock'>
        <Header as="h1">Unit Management</Header>
        <Button onClick={this.handleAddUnit}> Add Unit</Button>
        {AddUnitModal}
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine> Unit Name </Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{unitTableRows}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
      </div>
    );
  }
}
