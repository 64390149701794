import React, { Component } from "react";
var request = require('request');

class Report extends Component {
  constructor(props) {
    super(props);
    this.state={filename:props.match.params.rptid}
  }

  downloadReport = (data ) =>{
    this.getReport(atob(decodeURIComponent(data))).then ( res => {var file=new Blob([new Uint8Array(res)],{type:'application/blob'});
    console.log("res"+file);
    // .then(
    //     res=>{
          if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob(file, atob(decodeURIComponent(data)));
        } else {
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL, "_blank");
            setTimeout(function() {
              URL.revokeObjectURL(fileURL);
             }, 100);
        }
         }
     )
  }

  // getReport = async (filename) => {
  //   const response = await fetch("/api/getPDF?filename="+filename, {
  //       method: "GET",
  //       headers: {
  //         Accept: "application/blob",
  //         "Content-Type": "application/json"
  //       }
  //   })
  //   const body = await response.blob();
  //   if (response.status !== 200) throw Error(body.message);
  //   // console.log(body);
  //   return body;
  // }

  getReport = (data) => {
    var arr = window.location.href.split("/");
    var url='';
    if (window.location.port!==80)
    url=arr[0]+"//"+arr[2]+"/api/getPDF?filename="+atob(decodeURIComponent(data));
    else 
    url=arr[0]+"//"+window.location.hostname+"/api/getPDF?filename="+atob(decodeURIComponent(data));

    const opt={
            method: "GET",
            uri: url,
            headers: {
              Accept: "application/blob",
            },
            encoding: null
        };
    request(opt, function (error, response, body) {
      console.log('error:', error); // Print the error if one occurred
      console.log('statusCode:', response && response.statusCode); // Print the response status code if a response was received
      //const body = await response.blob();
    if (response.statusCode !== 200) throw Error(response);
    //  console.log(body);
     var file=new Blob([new Uint8Array(body)],{type:'application/pdf'});
    //  console.log("res"+file);
     // .then(
     //     res=>{
           if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
             window.navigator.msSaveOrOpenBlob(file, atob(decodeURIComponent(data)));
         } else {
             const fileURL = URL.createObjectURL(file);
             window.open(fileURL, "_self");
             setTimeout(function() {
               URL.revokeObjectURL(fileURL);
              }, 5000);
         }
    });

  }


 componentDidMount(){
     console.log(this.state.filename)
  this.getReport(this.state.filename);
 }

  render() {
    return (
      <div>
          
      </div>
    );
  }
}

export default Report;
