import React, { Component } from "react";
import { Checkbox, Header, Table, Button, Modal, Icon, Dropdown, Label , Popup,Segment,Dimmer,Loader, Input, Form,
Grid, Divider,TextArea} from "semantic-ui-react";

import "./CreateReport.css";
import _ from "underscore";
import moment from "moment";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";

// import Cookies from "universal-cookie";
// const cookies = new Cookies();

export default class CreateReport extends Component {
  constructor(props) {
    super(props);
    this.state = { completedSamples: [],
       modalOpen: false, 
       reportSamples:[], 
       generated:false,
       reportstep:1,
       sort_col: null,
       sort_dir: null,
       rptComment:'',
       newSeq: '',
       resOptions:[
        { key: 1, text: "Positive", value: "Positive" },
        { key: 2, text: "Negative", value: "Negative" },
        { key: 3, text: "Presumptive Positive", value: "PP" }
      ] ,
      cfmOptions:[
        { key: 1, text: "Positive", value: "Positive" },
        { key: 2, text: "Negative", value: "Negative" },
        { key: 3, text: "Pending", value: "Pending" },
        { key: 4, text: "Listeria fleischmannii", value: "Listeria fleischmannii" },
        { key: 5, text: "Listeria grayi", value: "Listeria grayi" },
        { key: 6, text: "Listeria innocua", value: "Listeria innocua" },
        { key: 7, text: "Listeria ivanovii", value: "Listeria ivanovii" },
        { key: 8, text: "Listeria marthii", value: "Listeria marthii" },
        { key: 9, text: "Listeria monocytogenes", value: "Listeria monocytogenes" },
        { key: 10, text: "Listeria rocourtiae", value: "Listeria rocourtiae" },
        { key: 11, text: "Listeria seeligeri", value: "Listeria seeligeri" },
        { key: 12, text: "Listeria weihenstephanensis", value: "Listeria weihenstephanensis" },
        { key: 13, text: "Listeria welshimeri", value: "Listeria welshimeri" }
      ],
      loadingProceedsButton: false,
      loadingSaveButton: false,
    loadingData: false,
    RptDt: moment(),
     TimeCheck: true,
     rptsufix:'',
    rptToAppend:'',
    appendDisabled:true,
    checkall:true};
    this.getAllCompleted= this.getAllCompleted.bind(this);
    this.pickSeq=this.pickSeq.bind(this);
    this.handleOpenPop=this.handleOpenPop.bind(this);
    this.handleClosePop=this.handleClosePop.bind(this);
    this.errorpop = React.createRef();
    this.duppop = React.createRef();
  }

  toggleTimeCheck = () => 
  { 
    this.state.reportSamples.forEach((data)=>{data.showtime=!this.state.TimeCheck});
    this.setState({ TimeCheck: !this.state.TimeCheck }) 
  }

  toggletime = (data,index) =>{
    console.log(data);
    data.showtime=!data.showtime;
    this.setState({reportSamples:this.state.reportSamples});
  }

  pickReport = (event,data) => {
    console.log(data.value);
this.setState({rptToAppend:data.value, appendDisabled:false});
  }
  handleSort = clickedColumn => () => {
    const { sort_col, completedSamples, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        completedSamples: _.sortBy(completedSamples, [clickedColumn]),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      completedSamples: completedSamples.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };

  handleOpen = () => this.setState({ modalOpen: true })

  handleClose = () => 
  {  if (this.state.reportstep>1) 
    this.getAllCompleted().then(res=>{
        if (res.status !== "ERROR")  this.setState({completedSamples:res})
    })

      this.setState({ modalOpen: false, generated:false , reportstep: 1,rptsufix:'', reportSamples:[],
          appendDisabled:true, checkall:true,rptComment:''}) }


  getAllCompleted = async () => {
    const response = await fetch("/api/getAllCompleted", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getSampleForReport = async(data,type) =>{
    const response = await fetch("/api/getSampleForRpt", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({data:data,type:type})        
      });
      const body = await response.json();
      // console.log(body);
      if (response.status !== 200) throw Error(body.message);
      return body;
  } 

  updSampleForReport = async(data) =>{
    const response = await fetch("/api/updSampleForRpt", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)        
      });
      const body = await response.json();
      // console.log(body);
      if (response.status !== 200) throw Error(body.message);
      return body;
  } 

  addReport = async(data) =>{
    const response = await fetch("/api/addReport", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(data)        
      });
      const body = await response.json();
      // console.log(body);
      if (response.status !== 200) throw Error(body.message);
      return body;
  } 

  getReportDetail = async (rptid) => {
    const response = await fetch(
      "/api/getReportDtl?rptid=" + rptid,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

  CreateReport=(data,index,type)=>{
  this.setState({curr_rpt_row:index,loadingData:true,rptToAppend:''})
  this.handleOpen();
  //  console.log(data);
  this.getSampleForReport(data,type).then(res=>{
      //    for (var i in res)
      // if (res[i].tst_result.substr(0,2)==='PP') res[i].tst_result= 'Presumptive Positive';
      //  console.log(res);
      res=_.sortBy(_.sortBy(_.sortBy(res, 'test_seq'),'seqid'),'group_sample_id');
      var newOptions=this.state.resOptions;
      res.map((data,index)=>{if (!res[index].tst_result) 
        res[index].tst_result='Negative';
        if (!res[index].raw_result) res[index].raw_result='';
        res[index].popupOpen=false;
        res[index].selected=true;
        res[index].showtime=true;
        if (!res[index].area_size) res[index].area_size=1;
        if (_.where(newOptions,{value:data.tst_result}).length===0)
               newOptions.push({text:data.tst_result, value:data.tst_result });
        })
      var seqList=_.uniq(_.pluck(res,'seqid'));
      var optList=[];
      for (var i in seqList) 
      if (seqList[i]%1===0) optList.push({key:seqList[i],value:seqList[i],text:seqList[i]})
    //  console.log(res);
      this.setState({resOptions:newOptions,reportSamples:res,seqOptions:optList,loadingData:false});
      // console.log(res);

  })
  }

componentDidMount() {
this.getAllCompleted().then(res=>{
    // console.log(res)
    this.getAllTests().then(res => {
      this.setState({ testOptions: res });
    })
    if (res.status !== "ERROR")  
    {    
          this.setState({completedSamples:res}) }
})
}

getReport = async(filename) => {
    const response = await fetch("/api/getPDF?filename="+filename, {
        method: "GET",
        headers: {
          Accept: "application/blob",
          "Content-Type": "application/json"
        }
    })
    const body = await response.blob();
    if (response.status !== 200) throw Error(body.message);
    console.log(body);
    return body;
}

downloadReport = () =>{
    this.getReport(this.state.filename).then(
        res=>{

          if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
            window.navigator.msSaveOrOpenBlob(res, this.state.filename);
        } else {
            const fileURL = URL.createObjectURL(res);
            var anchor = document.createElement('a');
            document.body.appendChild(anchor);
            anchor.setAttribute("type", "hidden");
            anchor.download = this.state.filename;
            anchor.href=fileURL;
            anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
             anchor.click();
        }
        }
    )
}

commitResult = async payload => {
  // console.log(payload);
  const response = await fetch("/api/commitTestResult", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(payload)
  });
  const body = await response.json();
  //console.log(body);
  if (response.status !== 200) throw Error(body.message);
  return body;
};


getPDFoutput = async payload => {
    const response = await fetch("/api/genrptpdf", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

  getAllTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getReportSummary = async (cust,rcvDT) => {
    
    var URLstring= "/api/getReportSummary?"+
    "cust=" + cust +
    "&minrcvdt=" +
    rcvDT +
    "&maxrcvdt=" +
    rcvDT;
   console.log(URLstring);
      const response = await fetch(
       URLstring,
        {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json"
          }
        }
      );
      const body = await response.json();
      if (response.status !== 200) throw Error(body.message);
       console.log(body);
      return body;
    };

  saveSamples = () => {
    var newData = this.state.reportSamples;
    this.setState({loadingProceedsButton:true});
  // console.log(newData);
  var saveAll = newData.map((data, index) => {
    return this.commitResult(data);
  });
  Promise.all(saveAll)
    .then(res => {
      var rev=0;
    if (_.where(res,{status:"ERROR"}).length===0) {
      this.getReportSummary(this.state.reportSamples[0].cust_id,moment(this.state.reportSamples[0].receive_date).format('YYYY-MM-DD'))
      .then (res=>{
        if (res.STATUS!=='ERROR')
      this.setState({existRpt: res.RESULT, loadingProceedsButton:false,reportstep:2});
      })
  }
})
  }

  saveOnly = () => {
    var newData = this.state.reportSamples;
    this.setState({loadingSaveButton:true});
  // console.log(newData);
  var saveAll = newData.map((data, index) => {
    return this.commitResult(data);
  });
  Promise.all(saveAll)
    .then(res => {
           this.setState({loadingSaveButton:false});
      })
  }


  saveUnit = (index,data) => {
    this.state.reportSamples[index].unit=data.value;
    this.setState({confirmDelOpen:false});
   }

   saveArea = (index,data) => {
    if (data.value==='' || !isNaN(parseInt(data.value))) {  
    this.state.reportSamples[index].area_size=data.value;
    this.setState({confirmDelOpen:false});
    }
   }

   saveRaw = (index,data) => {
    this.state.reportSamples[index].raw_result=data.value;
    this.setState({confirmDelOpen:false});
   }

   calcResult = (index,data) => {
     console.log(isNaN(this.state.reportSamples[index].raw_result))
     console.log(isNaN(this.state.reportSamples[index].area_size))
     console.log(this.state.reportSamples[index].raw_result.startsWith('<'))
    if (this.state.reportSamples[index].raw_result &&
      this.state.reportSamples[index].area_size &&
      !isNaN(this.state.reportSamples[index].raw_result) &&
        !isNaN(this.state.reportSamples[index].area_size)) 
        {
          this.state.reportSamples[index].tst_result=
          parseFloat((this.state.reportSamples[index].raw_result)/(this.state.reportSamples[index].area_size)).toFixed(2) ;
          console.log(this.state.reportSamples[index].tst_result)
          this.setState({confirmDelOpen:false});
        }
        else
        if ((this.state.reportSamples[index].raw_result &&
          this.state.reportSamples[index].area_size &&
          this.state.reportSamples[index].raw_result.startsWith('<') &&
          !isNaN(this.state.reportSamples[index].raw_result.replace('<','')) &&
            !isNaN(this.state.reportSamples[index].area_size)) )
          {
          this.state.reportSamples[index].tst_result='<'+
          parseFloat((this.state.reportSamples[index].raw_result.replace('<',''))/(this.state.reportSamples[index].area_size)).toFixed(2).toString() ;
          this.setState({confirmDelOpen:false});
          }
        else 
        {
          this.state.reportSamples[index].tst_result=''
          this.setState({confirmDelOpen:false});
        }
   }
   overrideResult = (index,data) => {
    this.state.reportSamples[index].tst_result=data.value;
    this.setState({confirmDelOpen:false});
   }


   saveTest = (d,data) => {
    console.log(d);
    console.log(data);
    data.test=d.value;
    data.analysisNM=_.where(this.state.testOptions,{value:d.value})[0].text;
    data.test_method=_.where(this.state.testOptions,{value:d.value})[0].test_method;
    data.confirm_method=_.where(this.state.testOptions,{value:d.value})[0].confirm_method;
    this.setState({confirmDelOpen:false});
  }

  appendReport = () => {
    this.getReportDetail(this.state.rptToAppend).then (res=>{
      if (res.STATUS==="SUCCESS") {
        console.log(this.state.existRpt);
        console.log(res)
       var rev=parseInt(_.where(this.state.existRpt,{ReportID:this.state.rptToAppend})[0].CURR_REV)+1;
       console.log(rev);
       var rptData =_.where(this.state.reportSamples,{selected:true});
       res.RESULT.map((data,index)=>{
         rptData.push({
          analysisCD: data.test,
          analysisNM: data.testNM,
          confirm_method: data.confirm_method,
          confirm_tst_result: data.confirm_tst_result,
          custNM: _.where(this.state.existRpt,{ReportID:this.state.rptToAppend})[0].custNM,
          cust_id: data.cust_id,
          custaddr: rptData[0].custaddr,
          custcode:  rptData[0].custcode,
          custcont:  rptData[0].custcont,
          desc: data.desc,
          dupindex: data.dupindex, 
          fax: rptData[0].fax,
          group_sample_id: data.group_sample_id,
          popupOpen: false,
          receive_date: data.receive_date,
          report_id: null,
          sample_ID: data.sample_id,
         sample_date: data.sample_date,
          selected: true,
          seqid: data.seqid,
          status: data.status,
          tel: rptData[0].tel,
          temp: data.temp,
          temp_id: data.temp_id,
          test: data.test,
          test_method: data.test_method,
          test_user_id: data.test_user_id,
          tst_end_date: data.tst_end_date,
          tst_result: data.tst_result,
          tst_schd_end_date: data.tst_schd_end_date,
          tst_start_date: data.tst_start_date,
          unit: data.unit,
          unit_id: data.unit_id,
          user_id: data.user_id
         });
       })
       rptData=_.sortBy(rptData, ['seqid']);
      //  console.log(rptData)
       var reportID=this.state.rptToAppend;
       var filename =
       reportID+
         "-R"+rev.toString()+
         ".pdf";
   
       var pdfPayload = {
         filename: filename,
         rpt_id:reportID+"-R"+rev.toString(),
         rptDt: this.state.RptDt,
         TimeCheck: this.state.TimeCheck,
         Detail: rptData,
         Comment: this.state.rptComment
       };

       this.getPDFoutput(pdfPayload).then(res => {
        if (res.STATUS==='SUCCESS') {
         this.setState({reportstep:3, filename:filename,
           linkified: window.location.href+"report/"+encodeURIComponent(btoa(filename))
         });
        }

       });
      }
      else 
      { console.log("error" + JSON.stringify(res));
      this.errorpop.current.handleOpen();
     }
    })
  }

  generateReport = () => {
    this.setState({rptToAppend:''});
    var rptData =_.where(this.state.reportSamples,{selected:true});
    var reportID=rptData[0].custcode+'-'+moment(rptData[0].receive_date).format('MMDDYY')
    +'-'+rptData[0].cust_id+'-'+this.state.rptsufix;
    if (_.where(this.state.existRpt,{ReportID:reportID}).length>0)
    this.duppop.current.handleOpen();
    else {
      // console.log(res);
      var rev=0;
    //    if (this.state.completedSamples[this.state.curr_rpt_row].CURR_REV!==-1)
    // rev=parseInt(this.state.completedSamples[this.state.curr_rpt_row].CURR_REV)+1
    var filename =
    reportID+
      "-R"+rev.toString()+
      ".pdf";

    var pdfPayload = {
      filename: filename,
      rpt_id:reportID+"-R"+rev.toString(),
      rptDt: this.state.RptDt,
      TimeCheck: this.state.TimeCheck,
      Detail: rptData,
      Comment: this.state.rptComment
    };
    // console.log(pdfPayload);
    this.getPDFoutput(pdfPayload).then(res => {
     if (res.STATUS==='SUCCESS') {
      this.setState({reportstep:3, filename:filename,
        linkified: window.location.href+"report/"+encodeURIComponent(btoa(filename))
      });
     }
    //   window.open(fileURL, "_blank");
    });
  }
  }

  changeOrder = (data, index) => {
  
    console.log(data);

  }

  updReportSummary = async payload => {
    const response = await fetch("/api/uptReportSummary", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };


  confirmReport = () => {
    var rptData =this.state.reportSamples;
    // console.log(this.state.rptToAppend);
    var reportID='';
    if (this.state.rptToAppend==='')
    reportID=rptData[0].custcode+'-'+moment(rptData[0].receive_date).format('MMDDYY')+'-'+rptData[0].cust_id
    +'-'+this.state.rptsufix; // New Report 
    else reportID=this.state.rptToAppend; // Existing Report
    var condition=_.pluck(_.where(this.state.reportSamples,{selected:true}),'sample_ID').join("','");
    this.updSampleForReport({condition:condition, reportid:reportID}).then(
        res => {
          if (res.status !== "ERROR")  {
            if (this.state.rptToAppend===''  )
              { var payload={};
              payload.reportID=reportID;
              var rev=0;
              // if (this.state.completedSamples[this.state.curr_rpt_row].CURR_REV!==-1)
              // rev=parseInt(this.state.completedSamples[this.state.curr_rpt_row].CURR_REV)+1
              payload.rev = rev;
              payload.revDT = moment().format('YYYY-MM-DD');
              payload.cntct = rptData[0].custcont+"\n"+rptData[0].custNM + "\n" + rptData[0].custaddr;
              payload.rcvDT = moment(rptData[0].receive_date).format('YYYY-MM-DD');
              payload.reportDT =moment(this.state.RptDt).format('YYYY-MM-DD');
              payload.comment=this.state.rptComment;
              // console.log("add");
              // console.log(payload);
              this.addReport(payload).then(
                  res=> { this.setState({reportstep: 4})})
                  .catch ( err => {console.log(err)})  
              } // New Report


              else {
                var currRpt=_.where(this.state.existRpt,{ReportID:this.state.rptToAppend})[0]
                var payload = {};
                payload.curr_rev = parseInt(currRpt.CURR_REV) + 1;
                payload.rev_date = moment().format("YYYY-MM-DD");
                payload.cntct_dtl = currRpt.CNTCT_DTL;
                payload.rcvDt = moment(currRpt.RECEIVE_DATE).format("YYYY-MM-DD");
                payload.testDt = moment(currRpt.TEST_DATE).format("YYYY-MM-DD");
                payload.reportDt = moment(currRpt.REPORT_DATE).format("YYYY-MM-DD");
                payload.ReportID = currRpt.ReportID;
                payload.newReportID = currRpt.ReportID;
                payload.comment=this.state.rptComment;
                // console.log("update");
                // console.log(payload);
                this.updReportSummary(payload).then(res => {
                  if (res.STATUS !== "FAIL") {
                    this.setState({reportstep: 4})
                    }
                  })
              } // Existing Report
          }
        }
    )
  }

  AddResult = (e, { value }) => {
    var oldResult = this.state.resOptions;
    oldResult.push({ text: value, value: value });
    console.log(oldResult);
    this.setState({
      resOptions: oldResult
    });
  };


  AddCfmResult = (e, { value }) => {
    var oldResult = this.state.cfmOptions;
    oldResult.push({ text: value, value: value });
    // console.log(oldUnits);
    this.setState({
      cfmOptions: oldResult
    });
  };

  saveRptDt = (data) =>{
    // console.log(data);
   // console.log(d);
    // console.log(newDataElem);
   
   this.setState({RptDt:data});
  // console.log(data.value)
  }
   
  suffixUpdate = (data) => {
    this.setState({rptsufix:data.value.replace('/','')})
  }

  saveTestRes = (d,data) =>{
    // console.log(data);
   // console.log(d);
    // console.log(newDataElem);
   var newData = this.state.reportSamples;
   
   for (var i in newData) {
                if (newData[i].sample_ID===data.sample_ID)
  //       newData[i].custNM===newDataElem.custNM &&
  //       newData[i].recvDT===newDataElem.recvDT)
         { newData[i].tst_result=d.value;
          if (d.value==='PP') newData[i].confirm_tst_result='Pending';
         }
         
      }
      // console.log(newData);
   this.setState({reportSamples:newData});
  // console.log(data.value)
  }

  saveCfmRes = (d,data) =>{
    // console.log(data);
   // console.log(d);
    // console.log(newDataElem);
   var newData = this.state.reportSamples;
   
   for (var i in newData) {
                if (newData[i].sample_ID===data.sample_ID)
  //       newData[i].custNM===newDataElem.custNM &&
  //       newData[i].recvDT===newDataElem.recvDT)
         { newData[i].confirm_tst_result=d.value;
           }
         
      }
   this.setState({reportSamples:newData});
  // console.log(data.value)
  }


  backToEdit = () => {
    this.setState({reportstep:1});
  }

  handleClosePop(data,index)
  {
   return (e) => {
     e.preventDefault();
   var newData = this.state.reportSamples;
   newData[index].popupOpen=false;
   this.setState({reportSamples:newData, newSeq:''});
   }
  }

  handleOpenPop(data,index)
  {
    // console.log(index);
    // console.log(data);
    return (e) => {
      e.preventDefault();
    var newData = this.state.reportSamples;
    for (var i in newData) newData[i].popupOpen=false;
   newData[index].popupOpen=true;
   this.setState({reportSamples:newData});
    }
   }
   
   pickSeq = (event,value,sourceseq,index) => {
    //  console.log(this.state.reportSamples);
    var newData=this.state.reportSamples;
    // console.log(sourceseq);
    newData[index].popupOpen=false;
    if (value.value>sourceseq) {
      console.log("move down")
      for (var i in newData) 
       { if (Math.floor(newData[i].seqid)>sourceseq && Math.floor(newData[i].seqid)<=value.value) 
        newData[i].seqid=newData[i].seqid-1;
       else if (Math.floor(newData[i].seqid)===sourceseq) 
       newData[i].seqid=value.value+newData[i].seqid%1;  
    }
  }
    else if (value.value<sourceseq) {console.log("move up");
    for (var i in newData) 
    {if (Math.floor(newData[i].seqid)<sourceseq && Math.floor(newData[i].seqid)>=value.value)
       newData[i].seqid=newData[i].seqid+1;
    else if (Math.floor(newData[i].seqid)===sourceseq ) newData[i].seqid=value.value+newData[i].seqid%1;}
  }
    else {console.log("no move")}
    // console.log(value);
    // console.log(_.sortBy(_.sortBy(newData, 'seqid'),'testid'));
    
   this.setState({reportSamples:_.sortBy(_.sortBy(newData, 'seqid'),'group_sample_id')});

    return true;
   }

 
   checked = (data,index) =>{
    for (var i in this.state.reportSamples)
    if (this.state.reportSamples[i].seqid===data.seqid && this.state.reportSamples[i].group_sample_id===data.group_sample_id) 
    this.state.reportSamples[i].selected=!this.state.reportSamples[i].selected;
    this.setState({reportSamples: this.state.reportSamples});
   }

  checkall = () => {
   if (this.state.checkall) {
    _.map(this.state.reportSamples,function(item){item.selected=false});
    this.setState({checkall:false});
   }
   else
   {
    _.map(this.state.reportSamples,function(item){item.selected=true});
    this.setState({checkall:true});
   }
  }

  updateComment=(event,data)=>{
    if (data.value.length<500&&data.value.split("\n").length<=6)
    this.setState({rptComment:data.value});
  }

  render() 
  {

    const { sort_col, sort_dir } = this.state;

    var nmIcon = null;
    if (sort_col !== "custNM") nmIcon = <Icon name='sort' />;

    var rcvDTIcon = null;
    if (sort_col !== "recvDT") rcvDTIcon = <Icon name='sort' />;

  
    var databody=this.state.completedSamples.map((data,index)=>{
      var rptButton=<Button onClick={() => this.CreateReport(data,index,"N")}>
      {" "}
      Create Report{" "}
    </Button>
     
    //   if (data.REPORT_ID) 
    //   rptButton=<div><Button onClick={() => this.CreateReport(data,index,"N")}>
    //   {" "}
    //   Create Report{" "}
    // </Button>
    //  <Button onClick={() => this.CreateReport(data,index,"A")}>
    //   {" "}
    //   Add to Report{" "}
    // </Button>  </div> 
        return (
            <Table.Row key={"c" + index}>
              <Table.Cell>{data.custNM}</Table.Cell>
              <Table.Cell>{moment(data.receive_date).format('YYYY-MM-DD')}</Table.Cell>
              <Table.Cell>{data.CNT}</Table.Cell>
              <Table.Cell>
              {rptButton}
              </Table.Cell>
            </Table.Row>
          );
    })
var seqid=0;
var sampleid='';
var newid=false;
    var samplebody=this.state.reportSamples.map((data,index)=>{
      var pickResultCell=""
      console.log(data);
     if (data.raw_result && data.raw_result!=='' && (!isNaN(data.raw_result)  || !isNaN(data.raw_result.replace('<','')) ) )
     { console.log('1');
     pickResultCell=<Input size="mini" value={data.tst_result} onChange={(event,d)=>this.overrideResult(index,d)}/>;
    }
     else 
      pickResultCell=(<Dropdown
        placeholder="Select Result"
        selection
        search
        allowAdditions
        size='small'
        options={this.state.resOptions}
        value={data.tst_result}
        onAddItem={this.AddResult}
        onChange={(e,d) => this.saveTestRes(d,data)}
      />);
      var pickConfirmLabl="";

      var pickConfirmCell="";
      if (data.tst_result==='PP') 
      {
      pickConfirmCell=(<Dropdown
        placeholder="Select Confirmation Result"
        selection
        search
        allowAdditions
        size='small'
        options={this.state.cfmOptions}
        value={data.confirm_tst_result}
        onAddItem={this.AddCfmResult}
        onChange={(e,d) => this.saveCfmRes(d,data)}
      />);
      pickConfirmLabl=(<Label className='cfmLabel' pointing='below'>Please choose confirmation result</Label>)
     }
     

     var popup= ""
     if (data.seqid%1===0)   popup=<Popup
     trigger={<Button icon circular size='mini' compact>
     <Icon rotated='clockwise' name='exchange'/>
          </Button>}
     content={
     <Dropdown search selection options={this.state.seqOptions}
     onChange={(event,value)=>this.pickSeq(event,value,data.seqid,index)} />
      }
     on='click'
     position='top left'
     hideOnScroll
     open={data.popupOpen}
     onClose={this.handleClosePop(data,index)}
     onOpen={this.handleOpenPop(data,index)}
   />
// console.log(data);
      if (data.seqid!==seqid || data.group_sample_id!==sampleid) 
      {seqid=data.seqid;sampleid=data.group_sample_id;newid=true}
      else newid=false;
      var maxrow=_.where(this.state.reportSamples,{seqid:data.seqid,group_sample_id:data.group_sample_id}).length;
      if (newid)  
      return (
            <Table.Row key={"d" + index}>
            <Table.Cell rowSpan={maxrow} > 
            <Checkbox
                checked={data.selected}
                onChange={(e, d) => this.checked(data, index)}
              /> </Table.Cell>
            <Table.Cell rowSpan={maxrow} >
            {/* <Icon className='exchangeicon' rotated='clockwise' name='exchange' 
            onClick={() => this.changeOrder(data,index)}/> */}
            {/* {popup} */}
             {data.seqid}   </Table.Cell>
            <Table.Cell rowSpan={maxrow}>{data.group_sample_id}</Table.Cell>
            <Table.Cell rowSpan={maxrow}>
            {moment(data.sample_date).format('YYYY-MM-DD')} <br/>
            <Checkbox checked={data.showtime} onChange={(e,d)=>this.toggletime(data,index)}/>
            {moment(data.sample_date).format('hh:mm')}
            </Table.Cell>
            <Table.Cell rowSpan={maxrow}><p className='description'> {data.desc} </p>
            </Table.Cell>
            <Table.Cell>  <Table.Cell><Dropdown
          placeholder="Select Test"
          selection
          size='tiny'
          className='miniinput' 
          options={this.state.testOptions}
          value={data.test}
          onChange={(e,d) => this.saveTest(d,data)}
        />
        </Table.Cell></Table.Cell>
            <Table.Cell><Input className='miniinput' size="mini" value={data.unit} onChange={(event,d)=>this.saveUnit(index,d)}/></Table.Cell>
            <Table.Cell><Input className='tinyinput' size="mini" value={data.area_size} onChange={(event,d)=>this.saveArea(index,d)} /></Table.Cell>
            <Table.Cell><Input className='tinyinput' size="mini" value={data.raw_result} onChange={(event,d)=>this.saveRaw(index,d)}  
            onBlur={(event,d)=>this.calcResult(index,d)}/></Table.Cell>
            <Table.Cell>{pickResultCell}{pickConfirmLabl}{pickConfirmCell}</Table.Cell>
          </Table.Row>
        )
      else 
      return (
        <Table.Row key={"d" + index}>
        <Table.Cell>  <Table.Cell><Dropdown
          placeholder="Select Test"
          selection
          className='miniinput' 
          size='tiny'
          options={this.state.testOptions}
          value={data.test}
          onChange={(e,d) => this.saveTest(d,data)}
        />
        </Table.Cell></Table.Cell>
        <Table.Cell><Input className='miniinput' size="mini" value={data.unit} onChange={(event,d)=>this.saveUnit(index,d)}/></Table.Cell>
        <Table.Cell><Input className='tinyinput'  size="mini" value={data.area_size} onChange={(event,d)=>this.saveArea(index,d)} /></Table.Cell>
        <Table.Cell><Input className='tinyinput' size="mini" value={data.raw_result} onChange={(event,d)=>this.saveRaw(index,d)} 
          onBlur={(event,d)=>this.calcResult(index,d)}/></Table.Cell>
        <Table.Cell>{pickResultCell}{pickConfirmLabl}{pickConfirmCell}</Table.Cell>
      </Table.Row>
    )
    })
    var confirmModal='';
    if (this.state.reportstep===1)
   confirmModal= <Modal size='fullscreen' open={this.state.modalOpen}
    onClose={this.handleClose}
    closeOnDimmerClick={false}>
    <Modal.Header>{this.state.reportSamples.length>0?this.state.reportSamples[0].custNM:''} - Confirm Report Content</Modal.Header>
    <Modal.Content scrolling>
       <Modal.Description>
        <Header></Header>
        <Grid verticalAlign='middle'>
        <Grid.Row >
            <Grid.Column width={3}>
            <Form>
                <Form.Group>
                  <Form.Field
                    control={Flatpickr}
                    label="Report Date"
                    options={{
                      defaultDate: moment(this.state.RptDt).format(
                        "YYYY-MM-DD"
                      )
                    }}
                    value={moment(this.state.RptDt).format("YYYY-MM-DD")}
                    onChange={(e, data) => this.saveRptDt(data)}
                  />
                  </Form.Group>
         </Form>
              </Grid.Column>
              <Grid.Column width={4}>
              <Checkbox toggle label='Show Sample Time on Report'  onChange={this.toggleTimeCheck} checked={this.state.TimeCheck} />
              </Grid.Column>
           <Grid.Column width={9}>
           </Grid.Column>
          </Grid.Row>
          </Grid>
           <Form>
          <Form.Group >
                    <Form.Field
                    label="Report Comments (Maximum Size : 500 Characters, 6 Lines):"
                    control={TextArea} 
                    width={16}
                    autoHeight
                    value={this.state.rptComment}
                    onChange={this.updateComment}
                    />
                  </Form.Group>
                  </Form>
                          <p>The following are the samples included in the report, please update the test result</p>
        <Segment>
        <Dimmer active={this.state.loadingData} inverted>
        <Loader inverted>Loading</Loader>
      </Dimmer>
        <Table celled size='small'>
        <Table.Header>
          <Table.Row>
          <Table.HeaderCell  className='tinyheader' width={1}>            <Checkbox
                checked={this.state.checkall}
                onChange={(e, d) => this.checkall()}
              /> Selected </Table.HeaderCell>
          <Table.HeaderCell className='tinyheader' width={1}>No.</Table.HeaderCell>
            <Table.HeaderCell width={1}>Sample ID</Table.HeaderCell>
            <Table.HeaderCell width={1}>Sample Date</Table.HeaderCell>
            <Table.HeaderCell width={8}>Description</Table.HeaderCell>
            <Table.HeaderCell width={1}>Test</Table.HeaderCell>
            <Table.HeaderCell width={1}>Unit</Table.HeaderCell>
            <Table.HeaderCell width={1}>Test Area</Table.HeaderCell>
            <Table.HeaderCell width={1}>Raw Result (For Calculation)</Table.HeaderCell>
            <Table.HeaderCell width={1}>Test Result</Table.HeaderCell>
           </Table.Row>
        </Table.Header>
        <Table.Body>
      {samplebody}
     </Table.Body>
      </Table>
      </Segment>
      </Modal.Description>
      <div className="spacer" />
    </Modal.Content>
    <Modal.Actions>
    <Button secondary onClick={this.handleClose} >
        Cancel <Icon name='delete' />
      </Button>
      <Button color='green' onClick={this.saveOnly} loading={this.state.loadingSaveButton} >
        Save <Icon name='save' />
      </Button>
      <Button primary onClick={this.saveSamples} loading={this.state.loadingProceedsButton}>
        Proceed <Icon name='chevron right' />
      </Button>
    </Modal.Actions>
  </Modal>;

     else     if (this.state.reportstep===2)

     {
       var namePicker='';
       var rptData =this.state.reportSamples;
       var reportID=rptData[0].custcode+'-'+moment(rptData[0].receive_date).format('MMDDYY')+'-'+rptData[0].cust_id;
    if (this.state.existRpt.length>0)  {
      var rptOption=this.state.existRpt.map((data,index)=>{
        return {text: data.ReportID, value: data.ReportID }
      })
      // console.log(rptOption);
       namePicker=     <Segment >
       <Grid columns={2} stackable textAlign='center'>
         <Divider vertical>Or</Divider>
   
         <Grid.Row verticalAlign='middle'>
           <Grid.Column>
             <Header>
             Add to Existing Report 
             </Header>
             <Dropdown placeholder='Select Report' selection options={rptOption} 
              onChange={(event,value)=>this.pickReport(event,value)}/>  
             <div>     <Button primary className='rptButton' onClick={this.appendReport} 
             loading={this.state.loadingProceedsButton} disabled={this.state.appendDisabled}> 
        Add <Icon name='chevron right' />
      </Button> </div> 
           </Grid.Column>
   
           <Grid.Column>
             <Header>
              Creat New Report
             </Header>
             <Input labelPosition='right' type='text' placeholder='Suffix' value={this.state.rptsufix} 
         onChange={(e, data) => this.suffixUpdate(data)}>
    <Label basic>{reportID}</Label>
    <input />
  </Input>
  <div>  <Button primary className='rptButton' onClick={this.generateReport} loading={this.state.loadingProceedsButton}>
        Create <Icon name='chevron right' />
      </Button>
      </div> 
           </Grid.Column>
         </Grid.Row>
       </Grid>
     </Segment>
    }
   else 
   namePicker=<Segment placeholder>
         <Header>
          Creat New Report
         </Header>
         <Input labelPosition='right' type='text' placeholder='Suffix' value={this.state.rptsufix} 
         onChange={(e, data) => this.suffixUpdate(data)}>
    <Label basic>{reportID}</Label>
    <input />
  </Input>
  <div> <Button className='rptButton' primary onClick={this.generateReport} loading={this.state.loadingProceedsButton}>
        Create <Icon name='chevron right' />
      </Button> </div>
 </Segment>

     confirmModal= <Modal size='fullscreen' open={this.state.modalOpen}
      onClose={this.handleClose}  closeOnDimmerClick={false}>
      <Modal.Header>{this.state.reportSamples.length>0?this.state.reportSamples[0].custNM:''} - Pick Report Name</Modal.Header>
      <Modal.Content scrolling>
         <Modal.Description>
          <Header></Header>
          <p>Please choose a report name</p>
        </Modal.Description>
      {namePicker}
      </Modal.Content>
      <Modal.Actions>
      <Button secondary onClick={this.handleClose} >
          Cancel <Icon name='delete' />
        </Button>
        <Button color='green' onClick={this.backToEdit} >
          <Icon name='chevron left' /> Back
        </Button>
      </Modal.Actions>
    </Modal>;
     }
    else     if (this.state.reportstep===3)
    confirmModal= <Modal size='fullscreen' open={this.state.modalOpen}
     onClose={this.handleClose}  closeOnDimmerClick={false}>
     <Modal.Header>{this.state.reportSamples.length>0?this.state.reportSamples[0].custNM:''} - Preview Report</Modal.Header>
     <Modal.Content scrolling>
        <Modal.Description>
         <Header></Header>
         <p>Please preview report</p>
       </Modal.Description>
       <iframe className='pdframe' d='pdfframe' type="application/pdf" src={this.state.linkified}> 
      <p style={{ 'font-size' : '110%'}}><em><strong>ERROR: </strong>  
An iframe should be displayed here but your browser version does not support iframes. </em>Please update your browser to its most recent version and try again.</p>
    </iframe>
     </Modal.Content>
     <Modal.Actions>
     <Button secondary onClick={this.handleClose} >
         Cancel <Icon name='delete' />
       </Button>
       <Button color='green' onClick={this.backToEdit} >
         <Icon name='chevron left' /> Back
       </Button>
       <Button primary onClick={this.confirmReport}>
         Confirm <Icon name='chevron right' />
       </Button>
     </Modal.Actions>
   </Modal>;
   else 
    confirmModal= <Modal open={this.state.modalOpen}
    onClose={this.handleClose}  closeOnDimmerClick={false}>
    <Modal.Header>{this.state.reportSamples.length>0?this.state.reportSamples[0].custNM:''} - Download Report</Modal.Header>
    <Modal.Content scrolling>
       <Modal.Description>
        <Header></Header>
        <p>The report has been successfully generated.</p>
        <p>Please use the download button below to download the report.</p>
        <p>The report can also be safely shared through the following link. Use "Copy" Button to save to clipboard</p>
      </Modal.Description>
      <a href={this.state.linkified} target="_blank">
{this.state.linkified}</a>
    </Modal.Content>
    <Modal.Actions>
    <Button secondary onClick={this.handleClose} >
        Close <Icon name='delete' />
      </Button>
      <CopyToClipboard text={this.state.linkified}
          onCopy={() => this.setState({copied: true})}>
      <Button onClick={this.handleClose} color='green'>
    Copy <Icon name='copy' />
  </Button>
        </CopyToClipboard>

      <Button primary onClick={this.downloadReport}>
        Download <Icon name='download' />
      </Button>
    </Modal.Actions>
  </Modal>;
      return (
          <div>
            <Table sortable celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell   sorted={sort_col === "custNM" ? sort_dir : null}
                onClick={this.handleSort("custNM")} >Customer   {nmIcon} </Table.HeaderCell>
            <Table.HeaderCell
                            sorted={sort_col === "recvDT" ? sort_dir : null}
                            onClick={this.handleSort("recvDT")}
            >
              Receive Date 
            {rcvDTIcon}
            </Table.HeaderCell>
            <Table.HeaderCell>Sample Count</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
      {databody}
     </Table.Body>
      </Table>
      {confirmModal}
      <Errorpop ref={this.errorpop} />
      <Errorpop ref={this.duppop} header='This Report Name is already used' message='Please Select Another Name' />
           </div>
      )
  }
}