import React, { Component } from "react";
import {
  Header,
  Table,
  Button,
  Modal,
  Icon,
  Dropdown,
  Input,
  Form,
  TextArea,
  Label,
  Popup,
  Checkbox,
  Confirm,
  Segment,
  Dimmer,
  Loader
} from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./ReviewRpt.css";
import _ from "underscore";
import moment from "moment";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";

export default class ReviewRpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReportSum: [],
      modalOpen: false,
      ReportDtl: [],
      selCust: null,
      selTest: null,
      selMinRcvDate: '2001-01-01',
      selMaxRcvDate: '2199-01-01',
      selMinRptDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
      selMaxRptDate: moment().format('YYYY-MM-DD'),
      emailLoader:false,
      testOptions: [],
      custOptions: [],
      selRptID: null,
      emailSent:false,
      rptComment:'',
      resOptions:[
        { key: 1, text: "Positive", value: "Positive" },
        { key: 2, text: "Negative", value: "Negative" },
        { key: 3, text: "Presumptive Positive", value: "PP" }
      ] ,
      cfmOptions:[
        { key: 1, text: "Positive", value: "Positive" },
        { key: 2, text: "Negative", value: "Negative" },
        { key: 3, text: "Pending", value: "Pending" },
        { key: 4, text: "Listeria fleischmannii", value: "Listeria fleischmannii" },
        { key: 5, text: "Listeria grayi", value: "Listeria grayi" },
        { key: 6, text: "Listeria innocua", value: "Listeria innocua" },
        { key: 7, text: "Listeria ivanovii", value: "Listeria ivanovii" },
        { key: 8, text: "Listeria marthii", value: "Listeria marthii" },
        { key: 9, text: "Listeria monocytogenes", value: "Listeria monocytogenes" },
        { key: 10, text: "Listeria rocourtiae", value: "Listeria rocourtiae" },
        { key: 11, text: "Listeria seeligeri", value: "Listeria seeligeri" },
        { key: 12, text: "Listeria weihenstephanensis", value: "Listeria weihenstephanensis" },
        { key: 13, text: "Listeria welshimeri", value: "Listeria welshimeri" }
      ],
      sort_col: null,
      sort_dir: null,
      previewURL: new Blob(),
      TimeCheck : true,
      confirmDelOpen:false,
      confirmDelRptOpen:false,
      loadingSave:false,
      AmendCheck:false
    };
    this.uptReportSample = this.uptReportSample.bind(this);
    this.getReportSummary=this.getReportSummary.bind(this);    
    this.pickSeq=this.pickSeq.bind(this);
    this.handleOpenPop=this.handleOpenPop.bind(this);
    this.handleClosePop=this.handleClosePop.bind(this);
    this.errorpop = React.createRef();
    this.handleDelClick=this.handleDelClick.bind(this);
  }

  toggleTimeCheck = () => 
  
  {
    this.state.ReportDtl.forEach((data)=>{data.showtime=!this.state.TimeCheck})
    this.setState({ TimeCheck: !this.state.TimeCheck })
  }

  
  toggleAmendCheck = () => 
  
  {
   // this.state.ReportDtl.forEach((data)=>{data.showtime=!this.state.TimeCheck})
  if (this.state.ModID[this.state.ModID.length-1]==='-')
   this.setState({rptComment:'Issued as amendment to report COA#: '+this.state.ModID+'R'+this.state.ModRptSeq})
  else 
  this.setState({rptComment:'Issued as amendment to report COA#: '+this.state.ModID+'-R'+this.state.ModRptSeq})
   this.setState({ AmendCheck: !this.state.AmendCheck })
  }


  toggletime = (data,index)=>
  {
    data.showtime=!data.showtime;
    this.setState({ReportDtl:this.state.ReportDtl});
  }
  showConfirmDel=(index)=>{
    this.setState({confirmDelOpen:true,indexToDel: index}); 
}

showConfirmDelRpt=(index,rptID)=>{
  this.setState({confirmDelRptOpen:true,indexToDelRpt: index, rptIDtoDel:rptID}); 
}

handleCancelDel=()=>{
 this.setState({confirmDelOpen:false}); 
}

handleCancelDelRpt=()=>{
  this.setState({confirmDelRptOpen:false}); 
 }

handleConfirmDel=(event)=>{
 this.setState({confirmDelOpen:false}); 
 event.preventDefault();
  this.handleDelClick(event,this.state.indexToDel);
}

handleConfirmDelRpt=(event)=>{
  this.setState({confirmDelRptOpen:false}); 
  event.preventDefault();
  this.DelReport(this.state.indexToDelRpt,this.state.rptIDtoDel);
 }

    handleSort = clickedColumn => () => {
    const { sort_col, ReportSum, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        ReportSum: _.sortBy(ReportSum, [clickedColumn]),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      ReportSum: ReportSum.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


  handleDelClick = ({e},i) =>{
     console.log(this.state.ReportDtl);
     console.log(i);
     var idtodel=this.state.ReportDtl[i].group_sample_id;
    this.delSampleData(this.state.ReportDtl[i].group_sample_id).then(result=>{
      if (result.status !== "ERROR") {
        console.log(result);
        var newData=_.filter(this.state.ReportDtl, function(item){ return item.group_sample_id!==idtodel }); 
     this.setState({confirmDelOpen:false, ReportDtl:newData}); 
       }
      else { this.errorpop.current.handleOpen();} 
      })
  
  }


  delSampleData = async key => {
    const response = await fetch("/api/delSample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ id: key })
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  AddResult = (e, { value }) => {
    var oldResult = this.state.resOptions;
    oldResult.push({ text: value, value: value });
     console.log(oldResult);
    this.setState({
      resOptions: oldResult
    });
  };


  AddCfmResult = (e, { value }) => {
    var oldResult = this.state.cfmOptions;
    oldResult.push({ text: value, value: value });
    // console.log(oldUnits);
    this.setState({
      cfmOptions: oldResult
    });
  };

  saveTestRes = (d,data) =>{
    // console.log(data);
   // console.log(d);
    // console.log(newDataElem);
   var newData = this.state.ReportDtl;
   
   for (var i in newData) {
                if (newData[i].sample_ID===data.sample_ID)
  //       newData[i].custNM===newDataElem.custNM &&
  //       newData[i].recvDT===newDataElem.recvDT)
         { newData[i].tst_result=d.value;
          if (d.value==='PP') newData[i].confirm_tst_result='Pending';
         }
         
      }
      console.log(newData);
   this.setState({ReportDtl:newData});
  // console.log(data.value)
  }

  saveCfmRes = (d,data) =>{
    // console.log(data);
   // console.log(d);
    // console.log(newDataElem);
   var newData = this.state.ReportDtl;
   
   for (var i in newData) {
                if (newData[i].sample_ID===data.sample_ID)
  //       newData[i].custNM===newDataElem.custNM &&
  //       newData[i].recvDT===newDataElem.recvDT)
         { newData[i].confirm_tst_result=d.value;
           }
         
      }
      console.log(newData);
   this.setState({ReportDtl:newData});
  // console.log(data.value)
  }


  handleOpen = () => this.setState({ modalOpen: true });

  updSuffix =(data) => this.setState({suffix:data.value});

  handleClose = () => {
    this.setState({ loadingSave:false, modalOpen: false, ModStep: "" ,emailRptID:'',emailSent:false});
  };

  
  updReportSent= async payload => {
    const response = await fetch("/api/updReportSent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    if (body.STATUS === 'FAIL') this.errorpop.current.handleOpen();
    else for (var i in this.state.ReportSum)
    if (this.state.ReportSum[i].ReportID===payload.ReportID) 
    this.state.ReportSum[i].LAST_SENT_DATE=moment().format("YYYY-MM-DD")
       // console.log(body);
    return body;
  };



  sendEmail = () => {
    this.setState({emailLoader:true});
   if (this.state.emailSent)
        this.updReportSent({ReportID:this.state.emailRptID})
      .then((res)=>{        this.getReportSummary().then(res => {
        // console.log(res);
        if (res.STATUS !== "FAIL")
          this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse()});
      });
      this.setState({ modalOpen: false, ModStep: "",emailLoader:false,emailSent:false });
            })
  else
    this.dropMail().then((res)=>{
      console.log("email_sent");
      if (res.STATUS === 'ERROR') 
      { 
        // modalOpen: false, ModStep: "",
        this.setState({ emailLoader:false });
      }
      else 
      {
      this.updReportSent({ReportID:this.state.emailRptID})
      .then((res)=>{
        this.getReportSummary().then(res => {
           console.log(res);
          if (res.STATUS !== "FAIL")
            this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse()});
        });
        this.setState({ modalOpen: false, ModStep: "",emailLoader:false });
            });
        console.log("set sent indicator");
          }
    })    
  }
  
  dropMail = async() => {
    const response = await fetch("/api/sendReport", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email:this.state.mail_email,
        subject:this.state.mail_subject,
        body:this.state.mail_content,
        attachment: this.state.mail_attachment
      })
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    if (body.STATUS === 'ERROR') 
    this.setState({emailconfirm:true,
    emailmsg:'The Email Can not be sent. Please check the address and try send again'})
    else this.setState({emailconfirm:true,
    emailmsg:'Email has been sent'})

    return body;
  };
  closeemailconfirm = () =>{
    this.setState({emailconfirm:false});
  }

  getReportSummary = async () => {
    
  var URLstring=      "/api/getReportSummary?rptID=" +
  this.state.selRptID +
  "&cust=" +
  this.state.selCust +
  "&minrptdt=" +
  this.state.selMinRptDate +
  "&maxrptdt=" +
  this.state.selMaxRptDate +
  "&minrcvdt=" +
  this.state.selMinRcvDate +
  "&maxrcvdt=" +
  this.state.selMaxRcvDate;
  if (this.state.selMinRcvDate===null) 
  URLstring= "/api/getReportSummary?rptID=" +
  this.state.selRptID +
  "&cust=" +
  this.state.selCust +
  "&minrptdt=" +
  moment().subtract(7,'d').format('YYYY-MM-DD') +
  "&maxrptdt=" +
  moment().format('YYYY-MM-DD') +
  "&minrcvdt=" +
  moment().subtract(7,'d').format('YYYY-MM-DD') +
  "&maxrcvdt=" +
  moment().format('YYYY-MM-DD')+"&minrptdt=null&maxrptdt=null";

  console.log(URLstring);
    const response = await fetch(
     URLstring,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    //  console.log(body);
    return body;
  };

  updReportSummary = async payload => {
    const response = await fetch("/api/uptReportSummary", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // if (body.STATUS === 'ERROR') this.errorpop.current.handleOpen();
    // console.log(body);
    return body;
  };

  getReportDetail = async () => {
    const response = await fetch(
      "/api/getReportDtl?rptid=" + this.state.ModID,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

  getReport = async filename => {
    const response = await fetch("/api/getPDF?filename=" + filename, {
      method: "GET",
      headers: {
        Accept: "application/blob",
        "Content-Type": "application/json"
      }
    });
    const body = await response.blob();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

  DelReport = async (index,reportID) => {
    const response = await fetch("/api/delReport", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({reportID:reportID})
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    console.log(body);
    if (body.STATUS === 'FAIL') this.errorpop.current.handleOpen();
    else 
     this.state.ReportSum.splice(index,1);
     this.setState({modalOpen:false})
    return body;
  };

  downloadReport = data => {
    this.getReport(data.ReportID + "-R" + data.CURR_REV + ".pdf").then(res => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res, data.ReportID + "-R" + data.CURR_REV + ".pdf");
    } else  {
      const fileURL = URL.createObjectURL(res);
      var anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.setAttribute("type", "hidden");
      anchor.download = data.ReportID + "-R" + data.CURR_REV + ".pdf";
      anchor.href=fileURL;
      anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
       anchor.click();
       setTimeout(function() {
        URL.revokeObjectURL(fileURL);
       }, 100);
  }
    });
  };

  saveDate = (type, data) => {
    // console.log(data);
    this.setState({ [type]: moment(data) });
    // console.log(this.state);
  };

  saveCntct = (event, data) => {
    this.setState({ ModCustDtl: data.value });
  };

  saveUnit = (index,data) => {
   this.state.ReportDtl[index].unit=data.value;
   this.setState({confirmDelOpen:false});
  }

  saveArea = (index,data) => {
    if (data.value==='' || !isNaN(parseInt(data.value))) {  
      this.state.ReportDtl[index].area_size=data.value;
      this.setState({confirmDelOpen:false});
      }
   }

   saveRaw = (index,data) => {
    this.state.ReportDtl[index].raw_result=data.value;
    this.setState({confirmDelOpen:false});
   }

   calcResult = (index,data) => {
    console.log(this.state.ReportDtl[index].raw_result)
     console.log(isNaN(this.state.ReportDtl[index].raw_result))
     console.log(isNaN(this.state.ReportDtl[index].area_size))
    if (this.state.ReportDtl[index].raw_result &&
      this.state.ReportDtl[index].area_size &&
      !isNaN(this.state.ReportDtl[index].raw_result) &&
        !isNaN(this.state.ReportDtl[index].area_size)) 
        {
          this.state.ReportDtl[index].tst_result=
          parseFloat((this.state.ReportDtl[index].raw_result)/(this.state.ReportDtl[index].area_size)).toFixed(2) ;
          console.log(this.state.ReportDtl[index].tst_result)
          this.setState({confirmDelOpen:false});
        }
        else
        {
          
          this.setState({confirmDelOpen:false});
        }
   }
   overrideResult = (index,data) => {
    this.state.ReportDtl[index].tst_result=data.value;
    this.setState({confirmDelOpen:false});
   }




   saveTest = (d,data) => {
     console.log(d);
     console.log(data);
     data.test=d.value;
     data.testNM=_.where(this.state.testOptions,{value:d.value})[0].text;
     data.test_method=_.where(this.state.testOptions,{value:d.value})[0].test_method;
     data.confirm_method=_.where(this.state.testOptions,{value:d.value})[0].confirm_method;
     this.setState({confirmDelOpen:false});
   }

   AddOneSample = () =>{
     console.log(this.state.ReportDtl)
      var max_group_id=_.last(_.pluck(this.state.ReportDtl,'group_sample_id').sort())
      console.log(max_group_id)
      if (max_group_id.split('-').length===4) {
        max_group_id=max_group_id.substring(0,max_group_id.length-2)
      }
      const new_group_id=max_group_id.split('-')[0]+'-'+max_group_id.split('-')[1]+'-'
      +(parseInt(max_group_id.split('-')[2])+1)
      console.log(new_group_id)
     this.addSampleData(max_group_id,new_group_id).then(result => {
      if (result.status !== "ERROR") {
      this.showSamples()
      }
      else { this.errorpop.current.handleOpen();} 
     }).catch(err=>{
      this.errorpop.current.handleOpen();
     })
   }

   addSampleData = async (key, newkey) => {
    const response = await fetch("/api/duplicateSample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ id: key, to_id:newkey })
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  ReviseReport = data => {
  
    this.setState({
      ModStep: "reportInfo",
      pickCustNM: data.custNM,
      ModID: data.ReportID,
      ModRcvDt: data.RECEIVE_DATE,
      ModTstDt: data.TEST_DATE,
      ModRptDt: data.REPORT_DATE,
      ModCustDtl: data.CNTCT_DTL,
      ModRptSeq: data.CURR_REV,
      rptComment: data.COMMENT,
      modalOpen: true,
      AmendCheck: false,
      suffix: data.ReportID.replace(data.ReportID.split('-',3).join('-')+'-','')
    });
  };

  showSamples = () => {
    this.setState({ loadingSave:true});
    this.getReportDetail().then(res => {
      this.setState({ ModStep: "sampleEdit",loadingSave:false});
      if (res.STATUS !== "FAIL") 
       {  
        res=_.sortBy(_.sortBy(_.sortBy(res.RESULT,'test_seq'), 'seqid'),'testid')
        var newOptions=this.state.resOptions;
        var newcfmOptions=this.state.cfmOptions;
        res.map((data, index) => {
          res[index].popupOpen=false;
          // res[index].raw_result='';
          res[index].showtime=this.state.TimeCheck;
          if (!res[index].area_size) res[index].area_size=1;
          if (_.where(newOptions,{value:data.tst_result}).length===0)
               newOptions.push({text:data.tst_result, value:data.tst_result });
               if (_.where(newcfmOptions,{value:data.confirm_tst_result}).length===0)
               newcfmOptions.push({text:data.confirm_tst_result, value:data.confirm_tst_result });
          })
          var seqList=_.uniq(_.pluck(res,'seqid'));
          var optList=[];
          for (var i in seqList) 
           if (seqList[i]%1===0) optList.push({key:seqList[i],value:seqList[i],text:seqList[i]})
         this.setState({resOptions:newOptions,cfmOptions:newcfmOptions,seqOptions:optList});
        // for (var i in res.RESULT)
      //   if (res.RESULT[i].tst_result.substr(0,2)==='PP') res.RESULT[i].tst_result= 'Presumptive Positive';
        this.setState({ ReportDtl: res }); }
    });
  };

  GenerateLink = data => {
    this.setState({
      pickCustNM:data.custNM,
      linkified:
        window.location.href +
        "report/" +
        encodeURIComponent(btoa(data.ReportID + "-R" + data.CURR_REV + ".pdf")),
      ModStep: "showlink",
      modalOpen: true
    });
  };


  emailRpt = (data) => {
    var mailContent="Hi, "+data.custNM+
    ", \n\n   Your report is ready for review in the attachment.\n\n\nThanks \nFSR Laboratory";
    this.setState({
      pickCustNM:data.custNM,
      linkified:
        window.location.href +
        "report/" +
        encodeURIComponent(btoa(data.ReportID + "-R" + data.CURR_REV + ".pdf")),
        mail_attachment: data.ReportID + "-R" + data.CURR_REV + ".pdf",
        mail_email: data.email,
        mail_content: mailContent,
        mail_subject:"Test Report For Samples Received on " + moment(data.RECEIVE_DATE).format("MM/DD/YYYY"),
        ModStep: "mailReport",
        modalOpen: true,
        emailRptID: data.ReportID
    })
     
  }

  saveRptDtl = (index, data, type) => {
    var newData = this.state.ReportDtl;
    switch (type) {
      case "sample_date":
        newData[index].sample_date = data;
        break;
      case "desc":
        newData[index].desc = data.value;
        break;
      case "unit":
        newData[index].unit = data.value;
        break;
      case "result":
        newData[index].tst_result = data.value;
        break;
      default:
    }
    // console.log(newData);
    this.setState({ ReportDtl: newData });
  };

  uptReportSample = async payload => {
    const response = await fetch("/api/uptReportSample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

 updateComment=(event,data)=>{
  if (data.value.length<500&&data.value.split("\n").length<=6)
  this.setState({rptComment:data.value});
}
  saveReport = () => {
    this.setState({loadingSave:true})
    var payload = {};
    payload.curr_rev = this.state.ModRptSeq + 1;
    payload.rev_date = moment().format("YYYY-MM-DD");
    payload.cntct_dtl = this.state.ModCustDtl;
    payload.rcvDt = moment(this.state.ModRcvDt).format("YYYY-MM-DD");
    payload.testDt = moment(this.state.ModTstDt).format("YYYY-MM-DD");
    payload.reportDt = moment(this.state.ModRptDt).format("YYYY-MM-DD");
    payload.ReportID = this.state.ModID;
    payload.newReportID=this.state.ModID.split('-',3).join('-')+'-'+this.state.suffix;
        var all_Promises = [];
        //  console.log(this.state.ReportDtl);
        this.state.ReportDtl.map((data, index) => {
          // console.log(data);
          all_Promises.push(this.uptReportSample(data));
        });
        Promise.all(all_Promises).then(() => {
          var rptData = [];
          this.state.ReportDtl.map((data, index) => {
            rptData.push({
              seqid: data.seqid,
              custcont: this.state.ModCustDtl,
              custNM: "",
              custaddr: "",
              receive_date: this.state.ModRcvDt,
              tst_start_date: this.state.ModTstDt,
              analysisNM: data.testNM,
              desc: data.desc,
              sample_ID: data.sample_ID,
              tst_result: data.tst_result,
              unit: data.unit,
              sample_date: data.sample_date,
              test_method: data.test_method,
              confirm_method: data.confirm_method,
              confirm_tst_result: data.confirm_tst_result,
              group_sample_id : data.group_sample_id,
              fax: data.fax,
              tel: data.tel,
              showtime:data.showtime
            });
          });
          var reportID = this.state.ModID.split('-',3).join('-')+'-'+this.state.suffix;
          var filename = reportID + "-R" + (this.state.ModRptSeq + 1) + ".pdf";

          var pdfPayload = {
            filename: filename,
            rpt_id: reportID + "-R" + (this.state.ModRptSeq + 1),
            Detail: rptData,
            rptDt: this.state.ModRptDt,
            TimeCheck: this.state.TimeCheck,
            Comment: this.state.rptComment
          };
          // console.log(pdfPayload);
          this.getPDFoutput(pdfPayload).then(res => {
            if (res.STATUS === "SUCCESS") {
            // this.getReport(filename).then(
            //   res=>{
                //   const fileURL = URL.createObjectURL(res);
                //  setTimeout(function() {
                //     URL.revokeObjectURL(fileURL);
                //    }, 10000);
              this.setState({
                loadingSave:false,
                ModStep: "previewReport",
                linkified:
                  window.location.href +
                  "report/" +
                  encodeURIComponent(
                    btoa(reportID + "-R" + (this.state.ModRptSeq + 1) + ".pdf")
                  )
              // ,previewURL: res
              });

            // })
          }
          });
        });
  };

  confirmReport = () => {
    var payload = {};
    payload.curr_rev = this.state.ModRptSeq + 1;
    payload.rev_date = moment().format("YYYY-MM-DD");
    payload.cntct_dtl = this.state.ModCustDtl;
    payload.rcvDt = moment(this.state.ModRcvDt).format("YYYY-MM-DD");
    payload.testDt = moment(this.state.ModTstDt).format("YYYY-MM-DD");
    payload.reportDt = moment(this.state.ModRptDt).format("YYYY-MM-DD");
    payload.ReportID = this.state.ModID;
    payload.newReportID=this.state.ModID.split('-',3).join('-')+'-'+this.state.suffix;
    payload.comment=this.state.rptComment;
    this.updReportSummary(payload).then(res => {
      if (res.STATUS !== "FAIL") {
        this.setState({
          ModStep: "downloadReport"})
        this.getReportSummary().then(res => {
          // console.log(res);
          if (res.STATUS !== "FAIL")
            this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse()});
        });
      }
    })
  }

  getPDFoutput = async payload => {
    const response = await fetch("/api/genrptpdf", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    console.log(body);
    return body;
  };

  getAllCustomers = async () => {
    const response = await fetch("/api/getCustomer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  saveOption = (type, data) => {
    //console.log(data);
    if (type === "rptID") this.setState({ selRptID: data.value });
    else if (type === "customer") this.setState({ selCust: data.value });
    else if (type === "test") this.setState({ selTest: data.value });
    else if (type === "rcvDt") 
     {if (data.length>1) this.setState({ selMinRcvDate: moment(data[0]).format('YYYY-MM-DD') ,selMaxRcvDate: moment(data[1]).format('YYYY-MM-DD')  });}
    else if (type === "rptDt")     if (data.length>1) this.setState({ selMinRptDate: moment(data[0]).format('YYYY-MM-DD') ,selMaxRptDate: moment(data[1]).format('YYYY-MM-DD')  });
  };

  applyFilter = () => {
    this.getReportSummary().then(res => {
      // console.log(res);
      if (res.STATUS !== "FAIL") this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse() });
    });
  };

  removeFilter = () => {
    this.setState(
      { selRptID: "", selCust: null, selTest: null, selMinRcvDate: '2001-01-01',
      selMaxRcvDate: '2199-01-01',
      selMinRptDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
      selMaxRptDate: moment().format('YYYY-MM-DD')}, ()=>this.getReportSummary().then(res => {
          // console.log(res);
          if (res.STATUS !== "FAIL") this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse() });
        }) 
      ) 
    // );
  };

  componentDidMount() {
    this.setState( {selMinRcvDate: '2001-01-01',
    selMaxRcvDate: '2199-01-01',
    selMinRptDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
    selMaxRptDate: moment().format('YYYY-MM-DD')});
    this.getReportSummary().then(res => {
      console.log(res);
      if (res.STATUS !== "FAIL") this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse() });
    });
    this.getAllCustomers().then(res => {
      this.setState({ custOptions: res });
    });
    this.getAllTests().then(res => {
      this.setState({ testOptions: res });
    });
  }

  handleClosePop = (data,index) =>
  {
   var newData = this.state.ReportDtl;
   newData[index].popupOpen=false;
   this.setState({ReportDtl:newData, newSeq:''});
  }

  handleOpenPop = (data,index) =>
  {
    var newData = this.state.ReportDtl;
    for (var i in newData) newData[i].popupOpen=false;
   newData[index].popupOpen=true;
   this.setState({ReportDtl:newData});
    }
  
   
   pickSeq = (event,value,sourceseq,index) => {
    //  console.log(this.state.reportSamples);
    var newData=this.state.ReportDtl;
    // console.log(sourceseq);
    newData[index].popupOpen=false;
    if (value.value>sourceseq) {
      console.log("move down")
      for (var i in newData) 
       { if (Math.floor(newData[i].seqid)>sourceseq && Math.floor(newData[i].seqid)<=value.value) 
        newData[i].seqid=newData[i].seqid-1;
       else if (Math.floor(newData[i].seqid)===sourceseq) 
       newData[i].seqid=value.value+newData[i].seqid%1; 
    }
  }
    else if (value.value<sourceseq) {console.log("move up");
    for (var i in newData) 
    {if (Math.floor(newData[i].seqid)<sourceseq && Math.floor(newData[i].seqid)>=value.value)
       newData[i].seqid=newData[i].seqid+1;
    else if (Math.floor(newData[i].seqid)===sourceseq ) 
    newData[i].seqid=value.value+newData[i].seqid%1; }
  }
    else {console.log("no move")}
    // console.log(value);
    // console.log(_.sortBy(_.sortBy(newData, 'seqid'),'testid'));
    
   this.setState({ReportDtl:_.sortBy(_.sortBy(newData, 'seqid'),'testid')});
    return true;
   }

   toggleSent = () =>{
     this.setState({emailSent:!this.state.emailSent})
   }

  render() {
    const { sort_col, sort_dir } = this.state;

    var nmIcon = null;
    if (sort_col !== "custNM") nmIcon = <Icon name='sort' />;

    var rcvDTIcon = null;
    if (sort_col !== "RECEIVE_DATE") rcvDTIcon = <Icon name='sort' />;


    var rptDTIcon = null;
    if (sort_col !== "REPORT_DATE") rptDTIcon = <Icon name='sort' />;

    var InfoUpdateModal = null;
    if (this.state.ModStep === "reportInfo")
      InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{this.state.pickCustNM} - Update Report Info</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>The following are the information part of report</p>
              <Form>
                <Form.Group widths="equal">
                <Form.Field
                   control={Input}
                   label="Change the Report Suffix"
                   value={this.state.suffix}
                   onChange={(e, data) => this.updSuffix(data)}
                 />
                  <Form.Field
                    control={Flatpickr}
                    label="Receive Date"
                    options={{
                      defaultDate: moment(this.state.ModRcvDt).format(
                        "YYYY-MM-DD"
                      )
                    }}
                    value={moment(this.state.ModRcvDt).format("YYYY-MM-DD")}
                    onChange={(e, data) => this.saveDate("ModRcvDt", data)}
                  />
                  {/* <Form.Field
                    control={Flatpickr}
                    label="Test Date"
                    options={{
                      defaultDate: moment(this.state.ModTstDt).format(
                        "YYYY-MM-DD"
                      )
                    }}
                    value={moment(this.state.ModTstDt).format("YYYY-MM-DD")}
                    onChange={(e, data) => this.saveDate("ModTstDt", data)}
                  /> */}
                  <Form.Field
                    control={Flatpickr}
                    label="Report Date"
                    options={{
                      defaultDate: moment(this.state.ModRptDt).format(
                        "YYYY-MM-DD"
                      )
                    }}
                    value={moment(this.state.ModRptDt).format("YYYY-MM-DD")}
                    onChange={(e, data) => this.saveDate("ModRptDt", data)}
                  />
                </Form.Group>
                <Form.Field
                  control={TextArea}
                  label="Contact Detail"
                  autoHeight
                  value={this.state.ModCustDtl}
                  onChange={(e, data) => this.saveCntct(e, data)}
                />
              <Form.Field
                    label="Report Comments (Maximum Size : 500 Characters, 6 Lines):"
                    control={TextArea} 
                    width={16}
                    autoHeight
                    value={this.state.rptComment}
                    onChange={this.updateComment}
                    />
              <Form.Field>
              <Checkbox toggle label='Show Sample Time on Report'  onChange={this.toggleTimeCheck} checked={this.state.TimeCheck} />
    </Form.Field>
    <Form.Field>
              <Checkbox toggle label='Amendment Statement'  onChange={this.toggleAmendCheck} checked={this.state.AmendCheck} />
    </Form.Field>
              </Form>

            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button icon secondary onClick={this.handleClose}>
              Cancel <Icon name="delete" />
            </Button>
            <Button icon primary onClick={this.showSamples} loading={this.state.loadingSave}>
              Next <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        </Modal>
      );
    else if (this.state.ModStep === "sampleEdit")
     { 
      var seqid=0;
      var newid=false;

         var sampleBody=this.state.ReportDtl.map((data, index) => {
        // console.log(data);
        var maxrow=_.where(this.state.ReportDtl,{seqid:data.seqid}).length;
        var popup= ""
        if (data.seqid%1===0)   popup=<Popup
        trigger={<Button icon circular size='mini' compact>
        <Icon rotated='clockwise' name='exchange'/>
             </Button>}
        content={
        <Dropdown search selection options={this.state.seqOptions}
        onChange={(event,value)=>this.pickSeq(event,value,data.seqid,index)} />
         }
        on='click'
        position='top left'
        hideOnScroll
        open={data.popupOpen}
        onClose={()=>this.handleClosePop(data,index)}
        onOpen={()=>this.handleOpenPop(data,index)}
      />

      var delButton= ""
      if (data.seqid%1===0)   delButton=
      <Button icon circular compact size='mini' 
          onClick={()=>this.showConfirmDel(index)}
          > <Icon name='trash' /> 
          </Button>

 var pickResultCell=""
      console.log(data.raw_result);
     if (data.raw_result && data.raw_result!=='' && !isNaN(data.raw_result)) 
     pickResultCell=<Input size="mini" value={data.tst_result} onChange={(event,d)=>this.overrideResult(index,d)}/>
     else 
      pickResultCell=(<Dropdown
        placeholder="Select Result"
        selection
        search
        allowAdditions
        size='small'
        options={this.state.resOptions}
        value={data.tst_result}
        onAddItem={this.AddResult}
        onChange={(e,d) => this.saveTestRes(d,data)}
      />);

        var pickConfirmLabl="";
  
        var pickConfirmCell="";
        if (data.tst_result==='PP') 
        {
        pickConfirmCell=(<Dropdown
          placeholder="Select Confirmation Result"
          selection
          search
          allowAdditions
          size='small'
          options={this.state.cfmOptions}
          value={data.confirm_tst_result}
          onAddItem={this.AddCfmResult}
          onChange={(e,d) => this.saveCfmRes(d,data)}
        />);
        pickConfirmLabl=(<Label className='cfmLabel' pointing='below'>Please choose confirmation result</Label>)
       }
       if (data.seqid!==seqid) 
       {seqid=data.seqid; newid=true}
       else newid=false;
      //  console.log(data)
       if (newid)  
         return (
          <Table.Row key={"d" + index}>
  <Table.Cell rowSpan={maxrow}>{delButton}{popup}{data.seqid}</Table.Cell>
  <Table.Cell rowSpan={maxrow}>{data.group_sample_id}</Table.Cell>
              <Table.Cell rowSpan={maxrow}>
              {" "}
              <Input size="small">
                {" "}
                <Flatpickr
                  label="Sample Date"
                  options={{
                    enableTime: true,
                    defaultDate: moment(data.sample_date).format(
                      "YYYY-MM-DD HH:mm"
                    )
                  }}
                  value={moment(data.sample_date).format(
                    "YYYY-MM-DD HH:mm"
                  )}
                  onChange={(e, data) =>
                    this.saveRptDtl(index, data, "sample_date")
                  }
                />
              </Input><br/>
              <Checkbox checked={data.showtime} onChange={(e,d)=>this.toggletime(data,index)}/> Show Time
            </Table.Cell>

  <Table.Cell rowSpan={maxrow}>
  <Form>
                <TextArea
                  rows={1}
                  autoHeight
                  value={data.desc}
                  onChange={(e, data) =>
                    this.saveRptDtl(index, data, "desc")
                  }
                />
              </Form>
    </Table.Cell>
  <Table.Cell><Dropdown
          placeholder="Select Test"
          selection
          size='tiny'
          options={this.state.testOptions}
          value={data.test}
          onChange={(e,d) => this.saveTest(d,data)}
        />
        </Table.Cell>
  <Table.Cell><Input size="mini" value={data.unit} onChange={(event,d)=>this.saveUnit(index,d)}/></Table.Cell>
  <Table.Cell><Input size="mini" value={data.area_size}  onChange={(event,d)=>this.saveArea(index,d)}/></Table.Cell>
  <Table.Cell><Input size="mini" value={data.raw_result} onChange={(event,d)=>this.saveRaw(index,d)} 
          onBlur={(event,d)=>this.calcResult(index,d)}/></Table.Cell>
  <Table.Cell>
  {pickResultCell}{pickConfirmLabl}{pickConfirmCell}
  </Table.Cell>
  </Table.Row>
        );
     else    return (
      <Table.Row key={"d" + index}>
       <Table.Cell><Dropdown
          placeholder="Select Test"
          selection
          size='tiny'
          options={this.state.testOptions}
          value={data.test}
          onChange={(e,d) => this.saveTest(d,data)}
        />
        </Table.Cell>
      <Table.Cell><Input size="mini" value={data.unit} onChange={(event,d)=>this.saveUnit(index,d)}/></Table.Cell>
      <Table.Cell><Input size="mini" value={data.area_size} onChange={(event,d)=>this.saveArea(index,d)}/></Table.Cell>
      <Table.Cell><Input size="mini" value={data.raw_result} onChange={(event,d)=>this.saveRaw(index,d)} 
          onBlur={(event,d)=>this.calcResult(index,d)}/></Table.Cell>
      <Table.Cell>{pickResultCell}{pickConfirmLabl}{pickConfirmCell}</Table.Cell>
    </Table.Row>
  )
      })
      InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{this.state.pickCustNM} - Update Report Detail Info</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>The following are the detail sample information of report</p>
            </Modal.Description>

            <Table celled size="small">
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell width={2}>No.</Table.HeaderCell>
            <Table.HeaderCell width={2}>Sample ID</Table.HeaderCell>
            <Table.HeaderCell width={2}>Sample Date</Table.HeaderCell>
            <Table.HeaderCell width={5}>Description</Table.HeaderCell>
            <Table.HeaderCell width={2}>Test</Table.HeaderCell>
            <Table.HeaderCell width={1}>Unit</Table.HeaderCell>
            <Table.HeaderCell width={1}>Test Area</Table.HeaderCell>
            <Table.HeaderCell width={1}>Raw Result (For Calculation)</Table.HeaderCell>
            <Table.HeaderCell width={1}>Test Result</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sampleBody}
              </Table.Body>
            </Table>
            <div className="spacer" />
          </Modal.Content>
          <Modal.Actions>
          <Button icon color='red' onClick={this.AddOneSample}>
              Add <Icon name="plus" />
            </Button>
            <Button icon secondary onClick={this.handleClose}>
              Cancel <Icon name="delete" />
            </Button>
            <Button icon color='green' onClick={()=>{ this.setState({ModStep:'reportInfo'}) }}>
              <Icon name="chevron left" /> Back
            </Button>
            <Button icon primary onClick={this.saveReport} loading={this.state.loadingSave}>
              Next <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        </Modal>
      );

      }

    else if (this.state.ModStep === 'previewReport')
    {
      // console.log(this.state.previewURL);
    InfoUpdateModal=(
      <Modal size='fullscreen' open={this.state.modalOpen}
     onClose={this.handleClose}
     closeOnDimmerClick={false}>
     <Modal.Header>{this.state.pickCustNM} - Preview Report</Modal.Header>
     <Modal.Content scrolling>
        <Modal.Description>
         <Header></Header>
         <p>Please preview report</p>
       </Modal.Description>
       <iframe title='report preview' className='pdframe' id='pdfframe' type="application/pdf"
        src={this.state.linkified}> 
      <p style={{ 'font-size' : '110%'}}><em><strong>ERROR: </strong>  
An iframe should be displayed here but your browser version does not support iframes. </em>Please update your browser to its most recent version and try again.</p>
    </iframe>

     </Modal.Content>
     <Modal.Actions>
     <Button secondary onClick={this.handleClose} >
         Cancel <Icon name='delete' />
       </Button>
       <Button icon color='green' onClick={()=>{ this.setState({ModStep:'sampleEdit'}) }}>
              <Icon name="chevron left" /> Back
            </Button>
       <Button primary onClick={this.confirmReport}>
         Next <Icon name='chevron right' />
       </Button>
     </Modal.Actions>
   </Modal>
    )
   
    }  
    else if (this.state.ModStep === "downloadReport")
      InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{this.state.pickCustNM} - Download Report</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>The report has been successfully generated.</p>
              <p>
                Please use the download button below to download the report.
              </p>
              <p>
                The report can also be safely shared through the following link.
                Use "Copy" Button to save to clipboard
              </p>
            </Modal.Description>
            <a href={this.state.linkified} target="_blank">{this.state.linkified}</a>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.handleClose}>
              Close <Icon name="delete" />
            </Button>
            <CopyToClipboard
              text={this.state.linkified}
              onCopy={() => this.setState({ copied: true })}
            >
              <Button onClick={this.handleClose} color="green">
                Copy <Icon name="copy" />
              </Button>
            </CopyToClipboard>
            <Button
              primary
              onClick={() =>
                this.downloadReport({
                  CURR_REV: this.state.ModRptSeq + 1,
                  ReportID: this.state.ModID
                })
              }
            >
              Download <Icon name="download" />
            </Button>
          </Modal.Actions>
        </Modal>
      );
    else if (this.state.ModStep === "showlink")
      InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{this.state.pickCustNM} - Link of Report</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>
                The report can be safely shared through the following link. Use
                "Copy" Button to save to clipboard
              </p>
            </Modal.Description>

            <a href={this.state.linkified} target="_blank">{this.state.linkified}</a>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.handleClose}>
              Close <Icon name="delete" />
            </Button>
            <CopyToClipboard
              text={this.state.linkified}
              onCopy={() => this.setState({ copied: true })}
            >
              <Button primary onClick={this.handleClose}>
                Copy <Icon name="copy" />
              </Button>
            </CopyToClipboard>
          </Modal.Actions>
        </Modal>
      );
      else if (this.state.ModStep === "mailReport")
      {
     InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{this.state.pickCustNM} - Email Report</Modal.Header>
          <Modal.Content scrolling>
          <Segment basic>
       <Dimmer inverted active={this.state.emailLoader}>
        <Loader inverted>Loading</Loader>
      </Dimmer>
          <Form>
          <Form.Field>
          <Checkbox toggle label='Sent offline'  onChange={this.toggleSent} checked={this.state.emailSent} />
          </Form.Field>
          <Form.Field
                   control={Input}
                   label="Send to:"
                   value={this.state.mail_email}
                   disabled={this.state.emailSent}
                   onChange={(e, data) => this.setState({mail_email:data.value})}
                 />
                     <Form.Field
                   control={Input}
                   label="Subject:"
                   value={this.state.mail_subject}
                   disabled={this.state.emailSent}
                   onChange={(e, data) => this.setState({mail_subject:data.value})}
                 />       
                <Form.Field
                  control={TextArea}
                  label="Content:"
                  autoHeight
                  value={this.state.mail_content}
                  disabled={this.state.emailSent}
                  onChange={(e, data) => this.setState({mail_content:data.value})}
                />
          </Form>
          </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.handleClose}>
              Close <Icon name="delete" />
            </Button>
            <Button primary onClick={this.sendEmail}>
                Send <Icon name="mail" />
              </Button>
          </Modal.Actions>
        </Modal>
      );
      }
    

    var databody = this.state.ReportSum.map((data, index) => {
      var emailIcon=<Icon name="mail" />;
    console.log(data);
    if (data.RPT_SENT==='Y')
    emailIcon=<Icon.Group><Icon color='green' name="mail" />
    <Icon color='green' size='large' corner='top right' name='check' />
    </Icon.Group>;

      return (
        <Table.Row key={"r" + index}>
          <Table.Cell>{data.ReportID.endsWith('-')?data.ReportID.substr(0,data.ReportID.length-1):data.ReportID}</Table.Cell>
          <Table.Cell>{data.custNM}</Table.Cell>
          <Table.Cell>{moment(data.RECEIVE_DATE).format("MMM DD,YYYY")}</Table.Cell>
          <Table.Cell>
            {moment(data.REPORT_DATE).format("MMM DD,YYYY")}
          </Table.Cell>
          <Table.Cell>{data.CURR_REV}</Table.Cell>
          {/* <Table.Cell>{data.LAST_SENT_DATE?moment(data.LAST_SENT_DATE).format("MMM DD,YYYY"):''}</Table.Cell> */}
          <Table.Cell>
            <Button icon data-tooltip="Download Report"  onClick={() => this.downloadReport(data)}>
              <Icon name="download" />
            </Button>
            <Button icon data-tooltip="Edit Report"  onClick={() => this.ReviseReport(data)}>
              <Icon name="edit" />
            </Button>
            <Button icon data-tooltip="Create Link"  onClick={() => this.GenerateLink(data)}>
              <Icon name="linkify" />
            </Button>
            <Button icon data-tooltip="Delete Report"  onClick={() => this.showConfirmDelRpt(index,data.ReportID) }>
              <Icon name="trash" />
            </Button>
            <Button icon data-tooltip="Send Report"  onClick={() => this.emailRpt(data) }>
              {emailIcon}
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <Form>
          <Form.Group>
            {/* <Form.Field
              control={Input}
              label="Report ID"
              placeholder="Search by Report ID"
              fluid
              value={this.state.selRptID?this.state.selRptID:''}
              onChange={(e, data) => this.saveOption("rptID", data)}
              width={4}
            /> */}
            <Form.Field
              control={Dropdown}
              label="Customer"
              placeholder="Select Customer"
              fluid
              search
              options={this.state.custOptions}
              selection
              value={this.state.selCust}
              onChange={(e, data) => this.saveOption("customer", data)}
              width={8}
            />
            {/* <Form.Field
              control={Dropdown}
              label="Test"
              placeholder="Select Test"
              fluid
              search
              selection
              value={this.state.selTest}
              options={this.state.testOptions}
              onChange={(e, data) => this.saveOption("test", data)}
              width={4}
            /> */}
            {/* <Form.Field
              control={Flatpickr}
              label="Receive Date"
              value={[this.state.selMinRcvDate, this.state.selMaxRcvDate]}
              options={{
               mode:"range",
               defaultDate: [this.state.selMinRcvDate, this.state.selMaxRcvDate]
               }}
              onChange={(selectedDates) => this.saveOption("rcvDt", selectedDates)}
              width={4}
            /> */}
            <Form.Field
              control={Flatpickr}
              label="Report Date"
              value={[this.state.selMinRptDate, this.state.selMaxRptDate]}
              options={{
                altInput: true,
                altFormat: "M j, Y",
                dateFormat: "Y-m-d",
                mode:"range",
                defaultDate: [this.state.selMinRptDate, this.state.selMaxRptDate]
                }}
              onChange={(selectedDates, dateStr, instance) => this.saveOption("rptDt", selectedDates)}
              width={4}
            />
            <Button
              icon
              className="search-button"
              primary
              onClick={this.applyFilter}
            >
              <Icon name="search" />
            </Button>
            <Button
              icon
              className="search-button"
              secondary
              onClick={this.removeFilter}
            >
              <Icon name="delete" />
            </Button>
            
          </Form.Group>{" "}
        </Form>
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Report ID</Table.HeaderCell>
              <Table.HeaderCell width={4} sorted={sort_col === "custNM" ? sort_dir : null}
                onClick={this.handleSort("custNM")} > Customer  {nmIcon}</Table.HeaderCell>
                <Table.HeaderCell width={2} sorted={sort_col === "RECEIVE_DATE" ? sort_dir : null}
                onClick={this.handleSort("RECEIVE_DATE")} >Receive Date  {rcvDTIcon}</Table.HeaderCell>
                <Table.HeaderCell width={1} sorted={sort_col === "REPORT_DATE" ? sort_dir : null}
                onClick={this.handleSort("REPORT_DATE")} >Report Date  {rptDTIcon}</Table.HeaderCell>
              <Table.HeaderCell  width={1}>Revision</Table.HeaderCell>
              {/* <Table.HeaderCell>Sent</Table.HeaderCell> */}
              <Table.HeaderCell width={5}>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{databody}</Table.Body>
        </Table>
        {InfoUpdateModal}
        <Errorpop ref={this.errorpop} />
        <Confirm open={this.state.emailconfirm} content={this.state.emailmsg} 
        onCancel={this.closeemailconfirm} onConfirm={this.closeemailconfirm} />
        <Confirm open={this.state.confirmDelOpen} content='This action will delete the sample.' 
              onCancel={this.handleCancelDel} onConfirm={(event)=>this.handleConfirmDel(event)} />   
       <Confirm open={this.state.confirmDelRptOpen} content='This action will delete the report and samples within it.' 
              onCancel={this.handleCancelDelRpt} onConfirm={(event)=>this.handleConfirmDelRpt(event)} />  
      </div>
    );
  }
}
