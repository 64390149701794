import React, { Component } from "react";
import {
  Modal, Header, Button,Icon, Form, Select, Input, Message, Divider
} from "semantic-ui-react";


import "./RecvInvt.css";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";
import { exists } from "fs";

const dupErrorHeader='Duplicates are found in the inventory list.';
const dupErroBody='Please remove the duplicates before submission.';
const earlyErrorHeader='A later transaction has already been entered';
const earlyErroBody='Please fix the incorrect date before submission.';
const nopersonErrorHeader='Receiver Name is not entered';
const nopersonErroBody='Please enter the receiver name before submission.';
const quantErrorHeader='Quantity value is invalid';
const quantErroBody='Please correct the quantity entry before submission.';

export default class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTrans: [{inv_key:1, unit_price:0.0, quantity: 1}],
      defaultPrice:0,
      supplyData : [],
      lotno: 0,
      receiveperson:'',
      error: false,  
      Action : 'R',
      errorHeader: '',
      errorBody:'',
      receiveDate: moment().format("YYYY-MM-DD"),
      transDate: moment().format("YYYY-MM-DD")
    };
    this.errorpop = React.createRef();
  }

 

  componentDidMount() {
    axios.get("/api/getDistinctMaterial").then(res => {
      console.log(res.data);
      this.setState({ supplyData: res.data });
      if (_.where(this.state.supplyData, {key:1}).length>0)  
          this.setState({defaultPrice: _.where(this.state.supplyData, {key:1})[0].unit_price,
            currentTrans: [{inv_key:1, unit_price:_.where(this.state.supplyData, {key:1})[0].unit_price, quantity: 1}]});
         else this.setState({defaultPrice: 0,
         currentTrans: [{inv_key:1, unit_price:0, quantity: 1}]});
    // this.setState({ maxkey: maxkeynow });
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  }

 
  addTrans = () => {

    var Trans=this.state.currentTrans;
    Trans.push({inv_key:1, unit_price:this.state.defaultPrice, quantity: 1});
    this.setState({currentTrans:Trans})

  }

  removeSupply = (index) => {
    var Trans=this.state.currentTrans;
    Trans.splice(index,1);
    this.setState({currentTrans:Trans});
  }
  
  updateVal = (data, d) => {
    var Trans=this.state.currentTrans;
    data.quantity=d.value;
    this.setState({currentTrans:Trans});
  }

  updateSupply = (data, d) => {
    var Trans=this.state.currentTrans;
    data.inv_key=d.value;
    data.unit_price=_.where(this.state.supplyData, {key:d.value})[0].unit_price;
    this.setState({currentTrans:Trans});
  }

  saveData = () => {
    var dup=false;
    var early=false;
    var quanterror=false;
    var latestDateList=[];
  //   axios.get("/api/getTransDate").then((result) => {
  //       latestDateList=result.data; 
  //  console.log(latestDateList);
  //  var currTime=moment();
  //  var receiveTime=moment(this.state.receiveDate)
  //  console.log(receiveTime);
  //  receiveTime.set({'hour' : currTime.hour(),'minute':currTime.minute(),'second':currTime.second()});
  //  console.log(receiveTime);
  this.state.currentTrans.map((data,index)=>{
    
    if (_.where(this.state.currentTrans,{inv_key:data.inv_key}).length>1) dup=true;
    // else if (_.where(latestDateList,{inv_key:data.inv_key}).length>0 && 
    // moment(_.where(latestDateList,{inv_key:data.inv_key})[0].max_date)>receiveTime) 
    // early=true;
    else if (!/^[\+\-]?\d+$/.test(data.quantity)) quanterror=true;
    })
    if (dup) this.setState({error:true,errorHeader:dupErrorHeader,errorBody:dupErroBody}) 
     else if (early) this.setState({error:true,errorHeader:earlyErrorHeader,errorBody:earlyErroBody}) 
     else if (quanterror) this.setState({error:true,errorHeader:quantErrorHeader,errorBody:quantErroBody}) 
     else if (this.state.receiveperson==='') 
     this.setState({error:true,errorHeader:nopersonErrorHeader,errorBody:nopersonErroBody}) 
      else
    { 

    var saveBody={
      tranType : this.state.Action,
      lot: this.state.lotno,
      receiver: this.state.receiveperson,
      receivedate: this.state.receiveDate,
      transdate: moment().format('YYYY-MM-DD hh:mm:ss'),
      trans: this.state.currentTrans
    }
    console.log(saveBody);

    this.setState({error:false});
    axios.post("/api/supplyTrans",saveBody).then(
      this.setState({
        currentTrans: [{inv_key:1, quantity: 1, unit_price:this.state.defaultPrice}],
        lotno: 0,
        receiveperson:'',
        error: false,  
        Action : 'R',
        receiveDate: moment().format("YYYY-MM-DD")
      })
    ).catch((error)=>{
      this.errorpop.current.handleOpen();
  })
}
// })
// .catch((error)=>{
//   this.errorpop.current.handleOpen();
//   return;
// })
  }

render() {
  var pickType=
  <Form.Field><label>Pick Transation Type</label><Button.Group>
  <Button positive onClick={()=>this.setState({Action:'R'})}>Receive</Button>
  <Button.Or />
  <Button onClick={()=>this.setState({Action:'A'})} >Adjust</Button>
</Button.Group> 
</Form.Field>
if (this.state.Action==='A') 
pickType=
<Form.Field><label>Pick Transation Type</label><Button.Group>
<Button onClick={()=>this.setState({Action:'R'})} >Receive</Button>
<Button.Or />
<Button positive onClick={()=>this.setState({Action:'A'})}>Adjust</Button>
</Button.Group> 
</Form.Field>

  var topForm=<Form.Group>
         <Form.Field control={Input} label='Enter Lot Number' value={this.state.lotno} disabled={this.state.Action==='A'}
          onChange={(e,d)=>this.setState({lotno:d.value})}/>
         <Form.Field control={Input} label='Enter the reciver name' value={this.state.receiveperson}
            onChange={(e,d)=>this.setState({receiveperson:d.value})}/>
              <Form.Field
              control={Flatpickr}
              label="Receive Date"
              options={{ defaultDate: moment().format("YYYY-MM-DD") }}
              width={3}
              value={this.state.receiveDate}
              onChange={(e, data) => this.setState({ receiveDate: data })}
            />
            <Form.Field
              control={Flatpickr}
              label="Log Date"
              options={{ defaultDate: moment().format("YYYY-MM-DD") }}
              width={3}
              value={this.state.transDate}
              disabled={true}
              />
       </Form.Group>
    var formList=[];
        this.state.currentTrans.map((data,index)=>{
        if (index===0)  formList.push(     <Form.Group widths='equal'>
         <Form.Field inline control={Select} label='Pick the Supply' options={this.state.supplyData} value={data.inv_key}
          onChange={(e,d)=>this.updateSupply(data,d)}/>
         <Form.Field inline disabled control={Input} label='Unit Price' value={data.unit_price}/>
         <Form.Field inline control={Input} label='Input the quantity' value={data.quantity}
            onChange={(e,d)=>this.updateVal(data,d)}/>
         <Button className='formButton' icon disabled primary onClick={()=>this.removeSupply(index)}>
         <Icon name="delete" />
           </Button>
       </Form.Group>)
       else 
       formList.push( <Form.Group widths='equal'>
       <Form.Field inline  control={Select} label='Pick the Supply' options={this.state.supplyData} value={data.inv_key}
        onChange={(e,d)=>this.updateSupply(data,d)}/>
       <Form.Field inline disabled control={Input} label='Unit Price' value={data.unit_price}/>
       <Form.Field inline  control={Input} label='Input the quantity' value={data.quantity}
          onChange={(e,d)=>this.updateVal(data,d)}/>
       <Button className='formButton' icon primary onClick={()=>this.removeSupply(index)}>
       <Icon name="delete" />
         </Button>
     </Form.Group>)
      })

      var message=   <Divider hidden />;
      if (this.state.error) 
        message=  <Message
        negative
        header={this.state.errorHeader}
        content={this.state.errorBody}
      />


    return (
      <div>
      <Form className='receiveForm'>
        {pickType}
       {topForm}
       {formList}
       {message}
      <Button icon primary onClick={()=>this.addTrans()}>
      Add <Icon name="add" />
   </Button>
  <Button icon color='red' onClick={()=>this.setState({
      currentTrans: [{inv_key:1, unit_price:this.state.defaultPrice, quantity: 1}],
      lotno: 0,
      receiveperson:'',
      duperror: false,  
      Action : 'R'
    })}>
      Reset <Icon name="undo" />
    </Button>
    <Button icon color="green" onClick={()=>this.saveData()}>
      Save <Icon name="save" />
    </Button>
    </Form>
        <Errorpop ref={this.errorpop} />
        </div>
    );
  }
}
