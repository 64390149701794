import React, { Component } from "react";
import {
  Form,
  Input,
  Dropdown,
  Button,
  TextArea,
  Icon,
  Confirm,
  Table,
  Loader,
  Dimmer,
  Segment,
  Modal,
  Header
} from "semantic-ui-react";

import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import "./ReceivePage.css";
import _ from "underscore";
import moment from "moment";
import Cookies from "universal-cookie";
import Errorpop from "../error/Errorpop";
import axios from "axios";



const cookies = new Cookies();
var id = 0;
var dflt_test = "LIS";
var justadded = false;
var prevTime = null;
export default class ReceivePage extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      custOptions: [],
      testOptions: [],
      unitOptions: [],
      tempOptions: [],
      SampleDetail: null,
      initData: this.props.initData,
      SampleData: [],
      custID: null,
      items: 0,
      receiveDate: moment().format("YYYY-MM-DD"),
      addButtonEnabled: true,
      printButtonEnabled: true,
      saveButtonEnabled: true,
      cancelButtonEnabled: true,
      userOptions: [],
      userID: '',
      userKey: cookies.get("userkey"),
      confirmResetOpen: false,
      confirmDeleteOpen : false,
      grpOptDisabled: false,
      sampleDate: moment().format("YYYY-MM-DD"),
      sampleTime: '00:00',
      analysisID:[],
      analysisNM:[],
      UnitID:[],
      UnitNM:[],
      TempID:[],
      TempNM:[],
      prev_ref:0,
      currentSeq:1,
      loadingButton: false,
      loadingSave: false,
      loaderActive: false,
      modalOpen:false,
      inccnt:0,
      showOptionButton:false,
      disableSaveOpt:true,
      testEditDisabled: false
    };
    id = 0;
    justadded = false;
    this.handleAddClick = this.handleAddClick.bind(this);
    this.handleCancelClick = this.handleCancelClick.bind(this);
    this.pickCustomer = this.pickCustomer.bind(this);
    this.pickAnalysis = this.pickAnalysis.bind(this);
    this.pickUnit = this.pickUnit.bind(this);
    this.pickTemp = this.pickTemp.bind(this);
    this.saveDesc = this.saveDesc.bind(this);
    this.saveDate = this.saveDate.bind(this);
    this.delSample = this.delSample.bind(this);
    this.renderArrays = this.renderArrays.bind(this);
    this.refreshTable = this.refreshTable.bind(this);
    this.scrollToBottom = this.scrollToBottom.bind(this);
    this.handlePrintClick = this.handlePrintClick.bind(this);
    this.AddUnit = this.AddUnit.bind(this);
    this.logout = this.logout.bind(this);
    this.addForm = this.addForm.bind(this);
    this.addSample = this.addSample.bind(this);
    this.delSampleData = this.delSampleData.bind(this);
    this.initialize=this.initialize.bind(this);
    this.saveSampleData=this.saveSampleData.bind(this);
    this.handleSaveClick=this.handleSaveClick.bind(this);
    this.addTest=this.addTest.bind(this);
    this.removeTest=this.removeTest.bind(this);
    this.errorpop = React.createRef();

  }

  logout = () => {
    cookies.remove("id");
    cookies.remove("userkey");
    cookies.remove("auth-token");
    window.location.reload();
  };

  writeLog = async() => {
    const response = await fetch("/api/printlog", {
      method: "POST",
      headers: {
        Accept: "application/Blob",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({payload:this.state.SampleData})
    });
    const body = await response.blob();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  pickCustomer = (e, data) => {
    // console.log(JSON.stringify(data))  ;
    console.log(data.value);
    if (data.value==="33") {
      this.getAllTests().then(res => {
        console.log(res);
        var option=_.filter(res,function(item){return (item.key!=0 && item.key!=4) })
     var analysis=[];
      //  console.log(option);
        analysis.push(_.where(option, { value: "t31" })[0].text);
        this.setState({ testOptions: option , analysisNM: analysis , analysisID: ["t31"] });})}
    else
    if (data.value==="130" || data.value==="131" || data.value==="133" ) {
      this.getAllTests().then(res => {
        console.log(res);
        var option=_.filter(res,function(item){return (item.key!=0 && item.key!=4) })
     var analysis=[];
      //  console.log(option);
        analysis.push(_.where(option, { value: "t31" })[0].text);
        this.setState({ testOptions: option , analysisNM: analysis , analysisID: ["t31"] });})
    }
    else
    {
    
       this.getAllTests().then(res => {
        var analysis=[];
        analysis.push(_.where(res, { value: "t0" })[0].text);
        this.setState({ testOptions: res , analysisNM: analysis , analysisID: ["t0"] });
      })
    }
    this.setState({
      custID: data.value,
      addButtonEnabled: false,
      printButtonEnabled: false,
      cancelButtonEnabled: false
    });
  };

  getPDFoutput = async payload => {
    const response = await fetch("/api/genreceiptpdf", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handlePrintClick() {
    var filename =
      this.state.custID.toString() +
      "_" +
      moment().format("YYMMDDHHmm") +
      ".pdf";
    // console.log(this.state.custOptions);
    // console.log(this.state.custID);

    // console.log(_.where(this.state.custOptions, { value: this.state.custID }))
    var pdfPayload = {
      filename: filename,
      custID: this.state.custID,
      custNM: _.where(this.state.custOptions, { value: this.state.custID })[0]
        .text,
      recvDT: this.state.receiveDate,
      Detail: this.state.SampleData
    };
    // console.log(pdfPayload);
    this.getPDFoutput(pdfPayload).then(res => {
      /*const file = new Blob(
    [res.data], 
    {type: 'application/pdf'});*/
      //Build a URL from the file
      const fileURL = URL.createObjectURL(res);
      // console.log(res);
      // console.log(fileURL);
      //Open the URL on new Window
      window.open(fileURL);
    });
  }

  addTest(e) {
      e.preventDefault();
      if (this.state.custID.toString()==="33") this.state.analysisID.push("t31"); 
      else if (this.state.custID.toString()==="130" || this.state.custID.toString()==="131" || this.state.custID.toString()==="133")
      this.state.analysisID.push("t31"); 
      else  this.state.analysisID.push("t0");

    this.state.UnitID.push("u1");
    this.state.TempID.push("p1");
    console.log(this.state.testOptions);
    if (this.state.custID==="33")
    this.state.analysisNM.push(_.where(this.state.testOptions, { value: "t31" })[0].text);
    else
    if (this.state.custID==="130" || this.state.custID==="131" || this.state.custID==="133")
    this.state.analysisNM.push(_.where(this.state.testOptions, { value: "t31" })[0].text);
    else
    this.state.analysisNM.push(_.where(this.state.testOptions, { value: "t0" })[0].text);

    this.state.UnitNM.push(_.where(this.state.unitOptions, { value: "u1" })[0].text);
    this.state.TempNM.push(_.where(this.state.tempOptions, { value: "p1" })[0].text) ;
this.setState({confirmResetOpen: false}); 
  }

  removeTest(e,index) {
    e.preventDefault();
    
    this.state.analysisID.splice(index,1);
    this.state.UnitID.splice(index,1);
    this.state.TempID.splice(index,1);
    this.state.analysisNM.splice(index,1);
    this.state.UnitNM.splice(index,1);
    this.state.TempNM.splice(index,1);
    this.setState({confirmResetOpen: false});
  }

  pickAnalysis = (data, index) => {
    var testOptions = this.state.testOptions;
    var analysisID=this.state.analysisID;
    var analysisNM=this.state.analysisNM;
     analysisID[index]=data.value;
  analysisNM[index]=_.where(testOptions, {value: data.value})[0].text;
  this.setState({analysisID : analysisID,
           analysisNM : analysisNM});
  console.log(analysisID);
  console.log(analysisNM);
    // dflt_test=_.where(this.state.testOptions, {
    //   value: data.value
    // })[0].code;
  };

  pickUnit = (data,index ) => {

    var UnitID=this.state.UnitID;
    UnitID[index]=data.value;
    var UnitNM=this.state.UnitNM;
    if (_.where(this.state.unitOptions, { value: data.value }).length > 0) {
        UnitNM[index]=_.where(this.state.unitOptions, {
          value: data.value
        })[0].text;
      } else {
       UnitNM[index]=data.value
      }
    this.setState({UnitID:UnitID,UnitNM:UnitNM});
    console.log(UnitID);
    console.log(UnitNM);
  };

  pickTemp = (data, index) => {
    
    var TempID=this.state.TempID;
    TempID[index]=data.value;
    var TempNM=this.state.TempNM;
    if (_.where(this.state.tempOptions, { value: data.value }).length > 0) {
      TempNM[index]=_.where(this.state.tempOptions, {
        value: data.value
      })[0].text;
    } else {
      TempNM[index]=data.value
    }
  this.setState({TempID:TempID,TempNM:TempNM});
  // console.log(TempID);
  // console.log(TempNM);
  };

  saveSeq = (event,data,item,i) => {
    var newData = this.state.SampleData;
    if (data.value==='' || !isNaN(parseInt(data.value))) {  
    newData[i].seqno = data.value;
    justadded = false;
    this.setState({ SampleData: newData,currentSeq:parseInt(data.value)+1 });
    }
  }

  saveDesc = (event, data, item, i) => {
    // console.log(event);
    // console.log(data);
    var newData = this.state.SampleData;
    newData[i].Desc = data.value;
    justadded = false;
    this.setState({ SampleData: newData });
  };

  saveArea = (event, data, item, i) => {
    // console.log(event);
    // console.log(data);
    var newData = this.state.SampleData;
    if (data.value && !isNaN(data.value))
    { newData[i].area_size = data.value;
    justadded = false;
    this.setState({ SampleData: newData }); }
  };

  saveRefnum = (e, data, item, i) => {
    var newData = this.state.SampleData;
    if (data.value==='' || !isNaN(parseInt(data.value))) {  
    newData[i].ref_num = data.value;
    justadded = false;
    newData[i].err_ref_num=false;
    this.setState({ SampleData: newData, prev_ref : data.value }, () => this.renderArrays());
    }
    else {
      newData[i].err_ref_num=true;
      justadded = false;
      this.setState({ SampleData: newData});
    }
  }

  commitChange = (event, item, i) => {
   //console.log(event);
    // console.log(item);
    this.renderArrays("U", i, item.labelID)
  };

  commitDateChange = (data, item, i) => {
    //console.log(event);
     console.log(data);
     var newData = this.state.SampleData;
     newData[i].SampleDate = data+' '+moment(newData[i].SampleDate).format('HH:mm');
     justadded = false;
     prevTime=moment(newData[i].SampleDate ).format('YYYY-MM-DD HH:mm');
     this.setState({ SampleData: newData },
     this.renderArrays("U", i, item.labelID));
   };
 

  saveDate = (data, item, i) => {
    console.log(data);
    var newData = this.state.SampleData;
    newData[i].SampleDate = data+' '+moment(newData[i].SampleDate).format('HH:mm');
    justadded = false;
    prevTime=moment(newData[i].SampleDate ).format('YYYY-MM-DD HH:mm');
    this.setState({ SampleData: newData });
  };


  // commitTimeChange = (data, item, i) => {
  //   //console.log(event);
  //    // console.log(item);
  //    console.log(data); 
  //    var newData = this.state.SampleData;
  //    newData[i].SampleDate = moment(newData[i].SampleDate).format('YYYY-MM-DD')+' '+data;
  //    justadded = false;
  //  //  console.log(newData[i].SampleDate);
  //    prevTime=moment(newData[i].SampleDate ,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
  //    console.log(prevTime);
  //    this.setState({ SampleData: newData },
  //    this.renderArrays("U", i, item.labelID));
  //  };

  saveTime = (data, item, i) => {
    console.log(data);
    var newData = this.state.SampleData;
    newData[i].SampleDate = moment(newData[i].SampleDate).format('YYYY-MM-DD')+' '+data;
    justadded = false;
  //  console.log(newData[i].SampleDate);
    prevTime=moment(newData[i].SampleDate ,'YYYY-MM-DD HH:mm').format('YYYY-MM-DD HH:mm');
    console.log(prevTime);
    this.setState({ SampleData: newData },
    this.renderArrays("U", i, item.labelID));
  };


saveSampleDate = (data) => {
  console.log(data);
  this.setState({ sampleDate: data });
}

  saveRecvDate = (data) => {
    //console.log(data);
    this.setState({ receiveDate: data });
  };

  saveStartSeq = (data) =>{
    console.log(parseInt(data.value));
    if (data.value==='' || !isNaN(data.value)) {  
    this.setState({ currentSeq:data.value });
    }
  }

  AddUnit = (e, { value }) => {
    var oldUnits = this.state.unitOptions;
    oldUnits.push({ text: value, value:value });
    // console.log(oldUnits);
    this.setState({
      unitOptions: oldUnits
    });
  };

  AddTemp = (e, { value }) => {
    var oldTemp = this.state.tempOptions;
    oldTemp.push({ text: value, value:value });
    this.setState({
      tempOptions: oldTemp
    });
  };
  // Need to rewrite render arrays to handle update and delete scenario.

  renderArrays(opt, key , id) {
    if (opt === "U") {
    
    var newData = this.state.SampleData;
    
    var payload = {
      id: newData[key].labelID,
      custid: this.state.custID,
      userid: this.state.userKey,
      testid: newData[key].analysisID,
      unitNM: newData[key].UnitNM,
      tempNM: newData[key].TempNM,
      recvDT: this.state.receiveDate,
      sampleDT: newData[key].SampleDate,
      desc: newData[key].Desc.replace("\\","\\\\").replace("'","\\'"),
      //desc: newData[key].Desc,
      unitID: newData[key].UnitID,
      tempID: newData[key].TempID,
      area_size:newData[key].area_size
    };

//        this.setState({ SampleData: newData }, () => this.refreshTable());
this.updSample(payload)
  .then(result => {
    if (result.status !== "ERROR") this.refreshTable();
    else 
     { console.log("error" + JSON.stringify(result));
     this.errorpop.current.handleOpen();
     this.delSample(key);
    }
  })
  .catch(function(error) {
    console.log(error);
  });

} else this.refreshTable();
}

  refreshTable = () => {
    // console.log("sample data:");
    // console.log(this.state.SampleData);
    
  }
  

// The following code block handle the delete of a single entry from the 
// form

  showConfirmDelete=(i)=>{
       this.setState({confirmDeleteOpen:true, confirmDeleteNumber:i}); 
  }

  handleCancelDelete=()=>{
    this.setState({confirmDeleteOpen:false}); 
  }

  handleConfirmDelete=()=>{
    this.setState({confirmDeleteOpen:false}); 
    this.delSample(this.state.confirmDeleteNumber);
  }

  delSample = i => {
    var newData = this.state.SampleData;
    this.delSampleData(this.state.SampleData[i].labelID).then(result => {
      if (result.status !== "ERROR") {
        if (newData[i].labelID.endsWith('-D'))
        newData.map((data,index)=>{
          if (data.labelID+'-D'=== newData[i].labelID)
            data.dupInd='N'
        })
      
        for (var j=newData.length;j--;j===0)
         {
         if (newData[j].labelID===this.state.SampleData[i].labelID || 
          newData[j].labelID===this.state.SampleData[i].labelID+'-D')
        { newData.splice(j, 1);
          id = id - 1; 
          break;
      }
    }
      
        justadded = false;
        // console.log(newData);
        this.setState({ SampleData: newData }, () => this.refreshTable());
      }
      else 
      this.errorpop.current.handleOpen();
    });
  };

  updSample = async(payload) => {

    const response = await fetch("/api/updSample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ id:payload.id, desc:payload.desc, sampleDT:payload.sampleDT,area_size:payload.area_size})
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  delSampleData = async key => {
    const response = await fetch("/api/delSample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ id: key })
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

// The following code block handle the save of the entries

saveSampleData = async payload => {
  console.log(payload);
  const response = await fetch("/api/saveSample", {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify({ condition: payload })
  });
  const body = await response.json();
  console.log(body);
  if (response.status !== 200) throw Error(body.message);
  return body;
};

handleSaveClick = () => {
  this.setState({saveButtonEnabled:true,  loadingSave: true})
  

  var singleTestItems=_.pluck(_.filter(this.state.SampleData,(item)=>{return item.analysisID.length===1}),'labelID');
  var multiTestItems=_.pluck(_.filter(this.state.SampleData,(item)=>{return item.analysisID.length>1}),'labelID');
  
  var valueToIns=[];
  _.filter(this.state.SampleData,(item)=>{return item.analysisID.length>1}).map(
    (data,index) => {
      console.log(data);
      for (var i in data.analysisID)
   valueToIns.push("("+data.seqno+",'"
   +data.labelID+"','"+data.labelID+'-'+data.analysisID[i]+"','"+this.state.custID+"','"+this.state.userKey+"','"+data.analysisID[i]+"','"+
   data.UnitNM[i].replace("\\","\\\\").replace("'","\\'")+"','"+data.TempNM[i].replace("\\","\\\\").replace("'","\\'")+"','"+this.state.receiveDate+"','"+data.SampleDate+"','"
   +data.Desc.replace("\\","\\\\").replace("'","\\'")+"','RECEIVED','"
   +data.UnitID[i]+"','"+data.TempID[i]+`',${data.area_size},${i})`)
    })
    var insertData=(valueToIns.join(','));
    var updateData=_.map(singleTestItems,function(str){return "'"+str+"'"}).join(',');
    var deleteData=_.map(multiTestItems,function(str){return "'"+str+"'"}).join(',');
 console.log(insertData);
  this.saveSampleData({ins:insertData,upd:updateData,del:deleteData,analysis:this.state.analysisID,
    unitid:this.state.UnitID,
    tempid:this.state.TempID,
    analysisNM:this.state.analysisNM,
    unitNM:this.state.UnitNM,
    tempNM:this.state.TempNM
  })
  .then (res=>{
    if (res.status !== "ERROR") {
      prevTime = null;
      this.setState(
        {
          SampleDetail: null,
          SampleData: [],
          custID: null,
          items: 0,
          receiveDate: moment().format('YYYY-MM-DD'),
          addButtonEnabled: true,
          printButtonEnabled: true,
          cancelButtonEnabled: true,
          saveButtonEnabled: true,
          testEditDisabled: false,
          grpOptDisabled: false,
          showOptionButton:false,
          currentSeq: 1,
          loadingSave: false
        },
        () =>
        { this.renderArrays("C", 0)
        this.getAllTests().then(res => {
          this.setState({ testOptions: res });
          this.setState({analysisID: ["t0"],
          UnitID: ["u1"],
          TempID: ["p1"],
          analysisNM: [_.where(this.state.testOptions, { value: "t0" })[0].text],
          UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
          TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});
        })
        }

      );
    }
    else {
      this.errorpop.current.handleOpen();
    }
  })
  .catch (err=>{console.log(err)})
}
  
  // The following code lbock handel the delete of all entries
  handleCancelClick() {
    var del_promises=[]
    if (this.state.SampleData.length>0)
     this.state.SampleData.map((item,i)=>{del_promises.push(this.delSampleData(item.labelID))})
    Promise.all(del_promises).then(()=>{
      prevTime = null;
    this.setState(
      {
        SampleDetail: null,
        SampleData: [],
        custID: null,
        items: 0,
        receiveDate: moment().format('YYYY-MM-DD'),
        sampleDate: moment().format('YYYY-MM-DD'),
        addButtonEnabled: true,
        printButtonEnabled: true,
        cancelButtonEnabled: true,
        saveButtonEnabled: true,
grpOptDisabled:false,
currentSeq: 1
      },
      () => {
        this.renderArrays("C", 0)
        this.getAllTests().then(res => {
          this.setState({ testOptions: res });
          this.setState({analysisID: ["t0"],
          UnitID: ["u1"],
          TempID: ["p1"],
          analysisNM: [_.where(this.state.testOptions, { value: "t0" })[0].text],
          UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
          TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});
        })

      }
    );
    })
  }
  
  showConfirmCancel=()=>{
    this.setState({confirmResetOpen:true}); 
}

handleCancelCancel=()=>{
 this.setState({confirmResetOpen:false}); 
}

handleConfirmCancel=()=>{
 this.setState({showOptionButton:false, confirmResetOpen:false,testEditDisabled: false}); 
 this.handleCancelClick();
}

// The following code block handel the copy of an entry
 handleCopyClick = (item) => {
   console.log(item);
   var newData = {
    seqno: item.seqno+0.1,
    labelID:
      item.labelID+'-D',
    analysisID: item.analysisID,
    UnitID: item.UnitID,
    TempID: item.TempID,
    analysisNM: item.analysisNM,
    UnitNM: item.UnitNM,
    TempNM: item.TempNM,
    SampleDate: item.SampleDate,
    Desc: item.Desc,
    area_size:item.area_size
  };
  this.addSample({
    seqno: newData.seqno,
    id: newData.labelID,
    custid: this.state.custID,
    userid: this.state.userKey,
    testid: newData.analysisID,
    unitNM: newData.UnitNM,
    tempNM: newData.TempNM,
    unitID: newData.UnitID,
    tempID: newData.TempID,
    recvDT: this.state.receiveDate,
    sampleDT: newData.SampleDate,
    desc: newData.Desc?newData.Desc:'',
    status : 'DRAFT',
    area_size:newData.area_size
  })
    .then(result => {
      if (result.status !== "ERROR") this.addForm(newData);
      else {  this.errorpop.current.handleOpen();
        console.log("error" + JSON.stringify(result));
      }
    })
    .catch(function(error) {
      console.log(error);
    });
 }

// The following code block handel the addition of a new entry

  handleAddClick() {
    this.setState({addButtonEnabled:true,  loadingButton: true})
    var currTS = moment();
    if (!prevTime) 
      prevTime=this.state.sampleDate;
      // this.getMaxSeq(this.state.custID,this.state.receiveDate).then (
      //   (res) => {
    var newData = {
      seqno: this.state.currentSeq,
      labelID:
        "FSR" +
        "-" +
        currTS.format("YYMMDD") +
        this.state.userKey.toString().toUpperCase() +
        "-" +
        this.state.custID.toString() +
        currTS.format("HHmmss"),
      analysisID: this.state.analysisID,
      UnitID: _.map(this.state.UnitID,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      TempID: _.map(this.state.TempID,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      analysisNM: this.state.analysisNM,
      UnitNM: _.map(this.state.UnitNM,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      TempNM: _.map(this.state.TempNM,function(item){return item.replace("\\","\\\\").replace("'","\\'")}),
      SampleDate: prevTime,
      Desc: "",
      area_size:1
    };
    this.setState({prev_ref:parseInt(this.state.prev_ref)+1, currentSeq:parseInt(this.state.currentSeq)+1})
    console.log(newData);
    this.addSample({
      seqno: newData.seqno,
      id: newData.labelID,
      custid: this.state.custID,
      userid: this.state.userKey,
      testid: newData.analysisID,
      unitNM: newData.UnitNM,
      tempNM: newData.TempNM, 
      unitID: newData.UnitID,
      tempID: newData.TempID,
      recvDT: this.state.receiveDate,
      sampleDT: newData.SampleDate,
      area_size:1,
      desc: newData.Desc?newData.Desc:'',
      status : 'DRAFT'
    })
      .then(result => {
        this.setState({showOptionButton:true,testEditDisabled: true,addButtonEnabled:false,  loadingButton: false})
        if (result.status !== "ERROR") this.addForm(newData);
        else { this.errorpop.current.handleOpen(); console.log("error" + JSON.stringify(result)); }
      })
      .catch(function(error) {
        console.log(error);
      });
  // }
// )
// .catch(function(error){console.log(error)})
  }

  addSample = async payload => {
    console.log(payload);
    const response = await fetch("/api/addSample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };



  addForm(payload) {
    id = id + 1;
    this.setState({
      items: id,
      grpOptDisabled: true
    });
    var newData = this.state.SampleData;
    if (payload.labelID.endsWith('-D'))
    newData.map((data,index)=>{
      if (data.labelID+'-D'=== payload.labelID)
        data.dupInd='Y'
    })
    newData.push(payload);
    newData=_.sortBy(newData,'labelID');

    this.setState({ SampleData: newData, saveButtonEnabled:false }, () => {
      this.renderArrays("A", 0);
    });
  }


  // The following block of code retrieve all metadata from database to render the page.

  getAllCustomers = async () => {
    const response = await fetch("/api/getCustomer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllUnits = async () => {
    const response = await fetch("/api/getUnit", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    //   console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllTemps = async () => {
    const response = await fetch("/api/getTemp", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };
  

  // initialize an empty start point or load from draft

  initialize() {

    this.setState({analysisID: ["t0"],
    UnitID: ["u1"],
    TempID: ["p1"],
    analysisNM: [_.where(this.state.testOptions, { value: "t0" })[0].text],
    UnitNM: [_.where(this.state.unitOptions, { value: "u1" })[0].text],
    TempNM: [_.where(this.state.tempOptions, { value: "p1" })[0].text]});
   if (this.state.initData.length>0)
   {
    // console.log("Loading from draft ... ");
    console.log(this.state.initData);
    this.setState({custID:this.state.initData[0].cust_id});
    this.setState({receiveDate:moment(this.state.initData[0].receive_date).format('YYYY-MM-DD')});
    this.setState({sampleDate: moment(this.state.initData[0].sample_date).format("YYYY-MM-DD HH:mm")});
    var newData=[];
    this.state.initData[0].unit_id.split(",").map((data,index)=>{
      console.log(_.where(this.state.unitOptions,{value:data}).length);
      if (_.where(this.state.unitOptions,{value:data}).length===0)
      {var oldUnits = this.state.unitOptions;
      oldUnits.push({ text: data, value:data });
      // console.log(oldUnits);
      this.setState({
        unitOptions: oldUnits
      });
    }
    })

    console.log(this.state.unitOptions);
    this.state.initData[0].temp_id.split(",").map((data,index)=>{
      if (_.where(this.state.tempOptions,{value:data}).length===0)
      {    var oldTemp = this.state.tempOptions;
        oldTemp.push({ text: data, value:data });
        this.setState({
          tempOptions: oldTemp
        });
    }
    })
    for (var i in this.state.initData)
    { var testOptions=(this.state.testOptions);
      var dupInd='N';
      if (_.where(this.state.initData,{sample_ID:this.state.initData[i].sample_ID+'-D'}).length>0) dupInd='Y';
       newData.push ({
         seqno: this.state.initData[i].seqid,
        labelID: this.state.initData[i].sample_ID,
      analysisID: this.state.initData[i].test.split(","),
      UnitID: this.state.initData[i].unit_id.replace("\\","\\\\").replace("'","\\'").split(","),
      TempID: this.state.initData[i].temp_id.replace("\\","\\\\").replace("'","\\'").split(","),
      analysisNM: _.map(this.state.initData[i].test.split(","), function(tid){ 
        return _.where(testOptions, {
       value: tid
      })[0].text}),
      UnitNM: this.state.initData[i].unit.replace("\\","\\\\").replace("'","\\'").split(","),
      TempNM: this.state.initData[i].temp.replace("\\","\\\\").replace("'","\\'").split(","),
      SampleDate: moment(this.state.initData[i].sample_date).format("YYYY-MM-DD HH:mm"),
      area_size:this.state.initData[i].area_size,
      Desc: this.state.initData[i].desc,
      dupInd: dupInd
       }      );
    }
    // console.log(newData);

    this.setState({analysisID: newData[0].analysisID,
    UnitID: this.state.initData[0].unit_id.split(","),
    TempID: this.state.initData[0].temp_id.split(","),
    analysisNM: newData[0].analysisNM,
    UnitNM: this.state.initData[0].unit.split(","),
    TempNM: this.state.initData[0].temp.split(",")});

    this.setState({SampleData:newData,grpOptDisabled:true, showOptionButton:true, testEditDisabled:true,
      addButtonEnabled:false,saveButtonEnabled:false, printButtonEnabled:false,cancelButtonEnabled:false,loaderActive:false});
   }

  else  
  this.setState({loaderActive:false})
  }


  getUserName=async()=>{
    const response=await 
    fetch('/api/getname', {
      method: 'GET',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const body=await response.json();
    console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
    }


    getMaxSeq=async(custid,recvDT)=>{
      const response=await 
      fetch('/api/getmaxseq?cust='+custid+'&rcv='+recvDT, {
        method: 'GET',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      const body=await response.json();
      // console.log(body);
      if (response.status !== 200) throw Error(body.message);
      if (body.status !== "ERROR") return body[0].CNT;
      else throw Error("error getting maxseq" + JSON.stringify(body));    
      }

      changeSeq=(data)=>{
          if (data.value==='') 
          this.setState({inccnt:''});
          else if (!isNaN(parseInt(data.value))) 
          this.setState({inccnt:parseInt(data.value)})
      }

      addSeq=()=>{
        var newData=this.state.SampleData;
        newData.map((value,index)=>{
          value.seqno=value.seqno+this.state.inccnt;
        })
        this.setState({SampleData:newData,modalOpen:false})
      }

  componentWillMount() {
    this.setState({loaderActive:true});
    this.getUserName().then(res=>{this.setState({userID:res.name})})
    .catch(error=>{return error});

    this.getAllCustomers().then(res => {
      this.setState({ custOptions: res });
      this.getAllTests().then(res => {
      this.setState({ testOptions: res });
      // console.log(res);
       this.getAllUnits().then(res => {
      this.setState({ unitOptions: res });
        this.getAllTemps().then(res => {
      this.setState({ tempOptions: [
        { key: "p1", value: "p1", text: "<=40F" },
        { key: "p2", value: "p2", text: ">40F" },
        { key: "p3", value: "p3", text: "On Ice" }
      ]},()=>this.initialize());
      
    });
  });
});
  });
    // console.log("before mount:" + JSON.stringify(this.state.SampleData));
   // this.initialize()
  }

  scrollToBottom() {
    //  console.log(this.myRef.current);
    this.myRef.current.scrollIntoView({ block: "end", behavior: "smooth" });
  }

  componentDidUpdate() {
    //  console.log(justadded);
    if (justadded) {
      this.scrollToBottom();
    }
  }

  saveOption = () =>{
    console.log(this.state.UnitID);
    console.log(this.state.analysisID);
    console.log(this.state.TempID);
    console.log(this.state.SampleData);
    var newData=this.state.SampleData;
    var updPromises=[];
    newData.map((value,index)=>{
    newData[index].analysisID=this.state.analysisID;
    newData[index].analysisNM=this.state.analysisNM;
    newData[index].UnitID=this.state.UnitID;
    newData[index].UnitNM=this.state.UnitNM;
    newData[index].TempNM=this.state.TempNM;
    newData[index].TempID=this.state.TempID;
    newData[index].SampleDate=moment(this.state.sampleDate).format('YYYY-MM-DD')+' '+moment(newData[index].SampleDate).format('HH:mm')
    var payload={
      id: newData[index].labelID,
      custid: this.state.custID,
      userid: this.state.userKey,
      testid: newData[index].analysisID,
      unitNM: newData[index].UnitNM,
      tempNM: newData[index].TempNM,
      recvDT: this.state.receiveDate,
      sampleDT: newData[index].SampleDate,
      desc: (newData[index].Desc?newData[index].Desc:'').replace("\\","\\\\").replace("'","\\'"),
      //desc: newData[key].Desc,
      unitID: newData[index].UnitID,
      tempID: newData[index].TempID,
      area_size: newData[index].area_size,
      seqno: parseInt(newData[index].seqno)
    }
   updPromises.push(axios.post("/api/updSampleOpts",payload));
    console.log(payload);
  })
    console.log(newData);
    
    Promise.all(updPromises)
  .then(result => {
    var status="SUCCESS";
    result.map((data)=>{
      if (data.data.status==='ERROR') status="ERROR"
    })
    if (status !== "ERROR")  
      this.setState({grpOptDisabled:true,addButtonEnabled:false, testEditDisabled:true,disableChangeOpt:false,
      disableSaveOpt:true,saveButtonEnabled:false,SampleData:newData})
    else 
     { console.log("error" + JSON.stringify(result));
     this.errorpop.current.handleOpen();
    //  this.delSample(key);
    }
  })
  .catch(function(error) {
    console.log(error);
  });

  }

  render() {

    let changeOptButton=this.state.showOptionButton?(<Button
      primary
      hidden
      disabled={this.state.disableChangeOpt}
      onClick={()=>this.setState({grpOptDisabled:false,addButtonEnabled:true, testEditDisabled:false, 
        disableChangeOpt:true,disableSaveOpt:false,saveButtonEnabled:true})}
    >
     Change Option
    </Button>):"";
  let saveOptButton=this.state.showOptionButton?(<Button
    primary
    hidden
    disabled={this.state.disableSaveOpt}
    onClick={()=>this.saveOption()}
  >
   Save Option
  </Button>):"";

    var testGroup=this.state.analysisID.map((data,index)=>{
      var addButton='';
      if (index===this.state.analysisID.length-1) 
       addButton=
          <Form.Button className='formButton' icon circular width={1} color='blue'
          onClick={this.addTest} disabled={this.state.testEditDisabled}> <Icon name='add' /> </Form.Button>
       else  addButton=
       <Form.Button className='formButton' icon circular width={1} color='red' disabled={this.state.testEditDisabled}
       onClick={(e)=>this.removeTest(e,index)}> <Icon name='minus' /> </Form.Button>

      return (
        <Form.Group key={"fm_grp_"+index} >
        <Form.Field
            control={Dropdown}
            label="Default Test"
            placeholder="Select Test"
            fluid
            selection
            search
            width={5}
            options={this.state.testOptions}
            value={this.state.analysisID[index]}
            onChange={(e, data) => this.pickAnalysis(data,index)}
           disabled={this.state.grpOptDisabled} 
          />
          <Form.Field
            control={Dropdown}
            label="Unit"
            placeholder="Select Unit"
            width={5}
            fluid
            selection
            search
            allowAdditions
            options={this.state.unitOptions}
            value={this.state.UnitID[index]}
            onAddItem={this.AddUnit}
            onChange={(e, data) => this.pickUnit(data,index)}
            disabled={this.state.grpOptDisabled} 
          />
          <Form.Field
            control={Dropdown}
            width={5}
            label="Temp"
            placeholder="Select Temp"
            fluid
            selection
            search
            allowAdditions
            options={this.state.tempOptions}
            value={this.state.TempID[index]}
            onAddItem={this.AddTemp}
            onChange={(e, data) => this.pickTemp(data,index)}
            disabled={this.state.grpOptDisabled} 
          />
          {addButton}
          </Form.Group>
      )
    })

    
      
    var databody=this.state.SampleData.map((item, i) => {
      var copyIcon='';
   if (item.dupInd!=='Y' && !item.labelID.endsWith('-D'))
         copyIcon=<Button
         color="green"
         className="copyButton"
         disabled={this.state.addButtonEnabled}
         onClick={
         e => this.handleCopyClick(item)
         }
         icon
       >
         <Icon name="copy" />
       </Button>;

     return    <Table.Row key={"c" + i}>
     <Table.Cell>{this.state.SampleData[i].labelID}</Table.Cell>
     <Table.Cell>
     <Input size='small' value={this.state.SampleData[i].seqno}
     onChange={(e,data)=>this.saveSeq(e,data,item,i)} 
     onClose={e => this.commitChange(e, item, i)}/>
     </Table.Cell>
     <Table.Cell>
     <Input size='small'> <Flatpickr
                     label="Sample Date"
                     disabled={this.state.addButtonEnabled}
                     options={{ defaultDate: moment(this.state.sampleDate).format('YYYY-MM-DD')}}
                     value={moment(this.state.SampleData[i].SampleDate).format('YYYY-MM-DD')}
                     onChange={(e, data) => this.saveDate(data, item, i)}
                     onClose={(e, data)  => this.commitDateChange(data, item, i)}/>
                     </Input>
     </Table.Cell>
     <Table.Cell>
     <Input size='small'> <Flatpickr
                     label="Sample Time"
                     disabled={this.state.addButtonEnabled}
                     options={{ defaultDate: moment(this.state.sampleDate).format('HH:mm'),
                     enableTime: true,
                     noCalendar: true}}
                     value={moment(this.state.SampleData[i].SampleDate).format('HH:mm')}
                     onChange={(e, data) => this.saveTime(data, item, i)}
                    //  onClose={(e, data) => this.commitTimeChange(data, item, i)}
                    />
                     </Input>
     </Table.Cell>
     <Table.Cell>
     <Input size='small' value={this.state.SampleData[i].area_size}
     disabled={this.state.addButtonEnabled}
                         onChange={(e, data) => this.saveArea(e, data, item, i)}
                         onBlur={e => this.commitChange(e, item, i)}/>
     </Table.Cell>
     <Table.Cell><TextArea rows={1} autoHeight
     disabled={this.state.addButtonEnabled}
                    placeholder="Description for the sample"
                    value={this.state.SampleData[i].Desc}
                    onChange={(e, data) => this.saveDesc(e, data, item, i)}
                    onBlur={e => this.commitChange(e, item, i)} /></Table.Cell>
     <Table.Cell>    <div className="actionArea">  <Button
               color="red"
               className="deleteButton"
               disabled={this.state.addButtonEnabled}
               onClick={
               e => this.showConfirmDelete(i)
               }
               icon
             >
               <Icon name="delete" />
             </Button>
           {copyIcon}
             </div>
             </Table.Cell>
     </Table.Row>
   });
   var detailTable=
   <Table celled size='small'>
   <Table.Header>
     <Table.Row>
     <Table.HeaderCell width={2}>Label ID</Table.HeaderCell>
     <Table.HeaderCell width={1}>No.</Table.HeaderCell>
       <Table.HeaderCell width={2}>Sample Date</Table.HeaderCell>
       <Table.HeaderCell width={1}>Sample Time</Table.HeaderCell>
       <Table.HeaderCell width={1}>Sample Area</Table.HeaderCell>
       <Table.HeaderCell width={7}>Desc </Table.HeaderCell>
       <Table.HeaderCell width={2}>Action</Table.HeaderCell>
     </Table.Row>
   </Table.Header>
   <Table.Body>
 {databody}
</Table.Body>
</Table>;

var seqUpdateModal = (
  <Modal
    size="small"
    open={this.state.modalOpen}
    onClose={()=>{this.setState({modalOpen:false})}}
    closeOnDimmerClick={false}
  >
    <Modal.Header>Increase all sequence numbers</Modal.Header>
    <Modal.Content scrolling>
      <Modal.Description>
        <Header />
  Increase all sequence numbers by <br/>
        <Input size='small' value={this.state.inccnt} onChange={(event,data)=>this.changeSeq(data)}/>
      </Modal.Description>

    </Modal.Content>
    <Modal.Actions>
      <Button secondary onClick={()=>{this.setState({modalOpen:false})}}>
        Close <Icon name="delete" />
      </Button>
        <Button primary onClick={()=>this.addSeq()}>
          Confirm <Icon name="check" />
        </Button>
    </Modal.Actions>
  </Modal>)

    return (
      <Segment className='mainseg'>
       <Dimmer inverted active={this.state.loaderActive}>
        <Loader inverted>Loading</Loader>
      </Dimmer>
        <Form className="receiveForm">
          <Form.Group widths="equal">
            <Form.Field
              control={Dropdown}
              label="Customer"
              placeholder="Select Customer"
              width={8}
              search
              selection
              options={this.state.custOptions}
              onChange={this.pickCustomer}
              value={this.state.custID}
              disabled={this.state.grpOptDisabled}
            />
            {/* <Form.Field
              className="userName"
              control={Input}
              label="Technician Name"
              width={3}
              transparent
              value={this.state.userID}
              action={<Button icon onClick={this.logout}> <Icon name='power off' />   </Button>}
            /> */}
            <Form.Field
              control={Flatpickr}
              label="Receive Date"
              options={{ defaultDate: moment().format("YYYY-MM-DD") }}
              width={3}
              value={this.state.receiveDate}
              onChange={(e, data) => this.saveRecvDate(data)}
              disabled={this.state.grpOptDisabled} 
            />
              <Form.Field
              control={Flatpickr}
              width={3}
              label="Sample Date"
              options={{ defaultDate: this.state.sampleDate }}
              // data-enable-time
              value={this.state.sampleDate}
              onChange={(e, data) => this.saveSampleDate(data)}
              disabled={this.state.grpOptDisabled} 
            />
              <Form.Field
              control={Input}
              width={2}
              label="Starting Number"
              value={this.state.currentSeq}
              onChange={(e, data) => this.saveStartSeq(data)}
              disabled={this.state.grpOptDisabled} 
            />
          </Form.Group>
         {testGroup}
            {detailTable}
          <Confirm open={this.state.confirmDeleteOpen} content='This entry will be deleted permanently' 
              onCancel={this.handleCancelDelete} onConfirm={this.handleConfirmDelete} />
          <Button
            primary
            disabled={this.state.addButtonEnabled}
            onClick={this.handleAddClick}
            loading={this.state.loadingButton}
          >
            Add Sample
          </Button>
          <Button
            color="blue"
            disabled={this.state.saveButtonEnabled}
            loading={this.state.loadingSave}
            onClick={()=>{this.setState({modalOpen:true})}}
          >
            Increase Seq Num
          </Button>
          {changeOptButton}
          {saveOptButton}
          {/* <Button
            color="green"
            disabled={this.state.printButtonEnabled}
            onClick={this.handlePrintClick}
          >
            Print
          </Button> */}
           <Button
            color="green"
            disabled={this.state.saveButtonEnabled}
            loading={this.state.loadingSave}
            onClick={this.handleSaveClick}
          >
            Save
          </Button>
          <Button
            secondary
            disabled={this.state.cancelButtonEnabled}
            onClick={this.showConfirmCancel}
          >
            Cancel
          </Button>
        </Form>

        <Confirm open={this.state.confirmResetOpen} content='This action will delete all entries that have not been saved yet.' 
              onCancel={this.handleCancelCancel} onConfirm={this.handleConfirmCancel} />        
        <Errorpop ref={this.errorpop} />
        {seqUpdateModal}
        <div style={{ float: "left", clear: "both" }} ref={this.myRef} />
        </Segment>
    );
  }
}
