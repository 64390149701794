import React, { Component } from "react";
import {

  Table,
  Button,
  Icon,
  Form,
  Confirm

} from "semantic-ui-react";

import "./QCTestLog.css";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";


const analyte=[{key:1, value:1, text:'Total Plate Count'},
{key:2, value:2, text:'Total Coliform'},
{key:3, value:3, text:'E.coli'},
{key:4, value:4, text:'Staphylococcus'},
{key:5, value:5, text:'Yeast'},
{key:6, value:6, text:'Enterobacteriaceae'},
{key:7, value:7, text:'Mold'},
{key:8, value:8, text:'Lactic Acid Bacteria'},
{key:9, value:9, text:'Bacillus cereus'}]

export default class QCTestLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplyData: [],
      selSupply:'',
      selMinRcvDate: '2001-01-01',
      selMaxRcvDate: '2199-01-01',
      QCLogs:[],
      sort_col: null,
      sort_dir: null,
      confirmDelete:false,
      currentDelete:{lot_no:''}
      };
    this.errorpop = React.createRef();
  }

  toggleTimeCheck = () => this.setState({ TimeCheck: !this.state.TimeCheck })

   closeConfirmDelete=()=> {
     this.setState({confirmDelete:false})
   }

   handleDelete = () => {
     axios.post('/api/delQCTestLog',this.state.currentDelete).then(()=>{
      axios.post("/api/getQCTestLog", {mindate:this.state.selMinRptDate,maxdate:this.state.selMaxRptDate}).then(res => {
        // console.log(res);
       this.setState({ confirmDelete:false,QCLogs: _.sortBy(res.data,'test_data').reverse() });
      }).catch((error)=>{
        this.errorpop.current.handleOpen();
      }); 
     })
     .catch((error)=>{
      this.errorpop.current.handleOpen();
    });
   }

    handleSort = clickedColumn => () => {
    const { sort_col, QCLogs, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        QCLogs: _.sortBy(QCLogs, [clickedColumn]),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      QCLogs: QCLogs.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


 isValidFloat = (str) => {
  return /^\-?[0-9]+(e[0-9]+)?(\.)?([0-9]+)?$/.test(str);
}

  
  applyFilter = () => {
    axios.post("/api/getQCTestLog", {mindate:this.state.selMinRptDate,maxdate:this.state.selMaxRptDate}).then(res => {
      // console.log(res);
      var QCLogs=res.data;
      QCLogs.map((data,index)=>{
        data.supplyNM=_.where(analyte,{key:data.analyte_key})[0].text
      })
     this.setState({ QCLogs: _.sortBy(QCLogs,'test_date').reverse() });
    }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  };

  removeFilter = () => {
    var mindate=moment().subtract(7,'d').format('YYYY-MM-DD');
    var maxdate=moment().format('YYYY-MM-DD');
    this.setState( {
    selMinRptDate: mindate,
    selMaxRptDate: maxdate,
    selSupply:''});
    axios.post("/api/getQCTestLog", {mindate:mindate,maxdate:maxdate}).then(res => {
      var QCLogs=res.data;
      QCLogs.map((data,index)=>{
        data.supplyNM=_.where(analyte,{key:data.analyte_key})[0].text
      })
      this.setState({ QCLogs: _.sortBy(QCLogs,'test_date').reverse() });
     }).catch((error)=>{
       this.errorpop.current.handleOpen();
     });
    // );
  };



  componentDidMount() {
    var mindate=moment().subtract(7,'d').format('YYYY-MM-DD');
    var maxdate=moment().format('YYYY-MM-DD');
    this.setState( {
    selMinRptDate: mindate,
    selMaxRptDate: maxdate});
     axios.post("/api/getQCTestLog", {mindate:mindate,maxdate:maxdate}).then(res => {
    var QCLogs=res.data;
    QCLogs.map((data,index)=>{
      data.supplyNM=_.where(analyte,{key:data.analyte_key})[0].text
    })
     this.setState({ QCLogs: _.sortBy(QCLogs,'test_date').reverse() });
    }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  }

  
render() {
    const { sort_col, sort_dir } = this.state;

    // var nmIcon = null;
    // if (sort_col !== "supplyNM") nmIcon = <Icon name='sort' />;

    // var rcvDTIcon = null;
    // if (sort_col !== "trans_time") rcvDTIcon = <Icon name='sort' />;


     var lotIcon = null;
     if (sort_col !== "lot_no") lotIcon = <Icon name='sort' />;



    // var personIcon = null;
    // if (sort_col !== "receiver") personIcon = <Icon name='sort' />;

      var prev_lot_no='';
      var prev_date=moment();
    var databody = 
        this.state.QCLogs.map((data, index) => {
          var headerrow=[];
     if (prev_lot_no!==data.lot_no || prev_date!==data.test_date) {
      var rowspan=_.where(this.state.QCLogs,{lot_no:data.lot_no,test_date:data.test_date}).length;
      headerrow.push(<Table.Cell rowSpan={rowspan} textAlign='center'>
        <Button color='red' circular icon='close' onClick={()=>this.setState({confirmDelete:true, currentDelete:data})}/></Table.Cell>);
       headerrow.push(<Table.Cell rowSpan={rowspan}>{moment(data.test_date).format("MMM DD,YYYY")}</Table.Cell>);
       headerrow.push(<Table.Cell  rowSpan={rowspan}>{data.lot_no}</Table.Cell>);
       headerrow.push(<Table.Cell  rowSpan={rowspan}>{data.tester}</Table.Cell>);
       prev_lot_no=data.lot_no;
       prev_date=data.test_date
     }
      return (
        <Table.Row key={"r" + index}>
          {headerrow}
          <Table.Cell>{data.supplyNM}</Table.Cell>
          <Table.Cell>
            {data.lower_limit}
          </Table.Cell>
          <Table.Cell>
            {data.upper_limit}
          </Table.Cell>
          <Table.Cell>
            {data.test_result}
          </Table.Cell>
          <Table.Cell>
            <Icon size='small'
         color={(data.test_result<0)?'black':(data.test_result>=data.lower_limit && data.test_result<=data.upper_limit)?'green':'red'}
         name={(data.test_result<0)?'dont':(data.test_result>=data.lower_limit && data.test_result<=data.upper_limit)?'check':'close'}/>
          </Table.Cell>
          <Table.Cell>
            {(data.test_result<0)?'N/A Test not Needed':data.comment}
          </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <Form>
          <Form.Group>
            {/* <Form.Field
              control={Input}
              label="Report ID"
              placeholder="Search by Report ID"
              fluid
              value={this.state.selRptID?this.state.selRptID:''}
              onChange={(e, data) => this.saveOption("rptID", data)}
              width={4}
            /> 
            <Form.Field
              control={Dropdown}
              label="Supplies"
              placeholder="Select Supply"
              fluid
              search
              options={this.state.supplyData}
              selection
              value={this.state.selSupply}
              onChange={(e, data) => this.setState({selSupply:data.value})}
              width={8}
            />*/}
            <Form.Field
              control={Flatpickr}
              label="Receive Date"
              value={[this.state.selMinRptDate, this.state.selMaxRptDate]}
              options={{
                altInput: true,
                altFormat: "M j, Y",
                dateFormat: "Y-m-d",
                mode:"range",
                defaultDate: [this.state.selMinRptDate, this.state.selMaxRptDate]
                }}
              onChange={(selectedDates, dateStr, instance) => {
                if (selectedDates.length>1) this.setState({ selMinRptDate: moment(selectedDates[0]).format('YYYY-MM-DD') ,selMaxRptDate: moment(selectedDates[1]).format('YYYY-MM-DD')  })
              }}
              width={8}
            />
            <Button
              icon
              className="search-button"
              primary
              onClick={this.applyFilter}
            >
              <Icon name="search" />
            </Button>
            <Button
              icon
              className="search-button"
              secondary
              onClick={this.removeFilter}
            >
              <Icon name="delete" />
            </Button>
            
          </Form.Group>{" "}
        </Form>
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>Test Date</Table.HeaderCell>
                <Table.HeaderCell sorted={sort_col === "lot_no" ? sort_dir : null}
                onClick={this.handleSort("lot_no")}>Lot Number {lotIcon} </Table.HeaderCell>
                 <Table.HeaderCell>Tested By </Table.HeaderCell>
                <Table.HeaderCell >Analyte </Table.HeaderCell>
                <Table.HeaderCell >Lower Limit</Table.HeaderCell>
                <Table.HeaderCell >Higher Limit</Table.HeaderCell>
                <Table.HeaderCell >Test Result</Table.HeaderCell>
                <Table.HeaderCell >Passed?</Table.HeaderCell>
                <Table.HeaderCell >Comment</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{databody}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
        <Confirm open={this.state.confirmDelete} 
        content={'This will delete all Test entries from '+moment(this.state.currentDelete.test_date).format("MMM DD,YYYY")+' for lot '+this.state.currentDelete.lot_no}
        onCancel={this.closeConfirmDelete} onConfirm={this.handleDelete} />
        </div>
    );
  }
}
