import React, { Component } from "react";
import {
  Header,
  Table,
  Button,
  Modal,
  Icon,
  Dropdown,
  Input,
  Form,
  TextArea,
  Label,
  Popup,
  Checkbox,
  Confirm,
  Segment,
  Dimmer,
  Loader,
  Message
} from "semantic-ui-react";

import "./InvtLog.css";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";

export default class InvtLog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplyData: [],
      selSupply:'',
      selMinRcvDate: '2001-01-01',
      selMaxRcvDate: '2199-01-01',
      InvtLogs:[],
      sort_col: null,
      sort_dir: null,
      };
    this.errorpop = React.createRef();
  }

  toggleTimeCheck = () => this.setState({ TimeCheck: !this.state.TimeCheck })


    handleSort = clickedColumn => () => {
    const { sort_col, InvtLogs, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        InvtLogs: _.sortBy(InvtLogs, [clickedColumn]),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      InvtLogs: InvtLogs.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


 isValidFloat = (str) => {
  return /^\-?[0-9]+(e[0-9]+)?(\.)?([0-9]+)?$/.test(str);
}

  
  applyFilter = () => {
    axios.post("/api/getInvLog", {mindate:this.state.selMinRptDate,maxdate:this.state.selMaxRptDate,supplyNM:this.state.selSupply}).then(res => {
      // console.log(res);
     this.setState({ InvtLogs: _.sortBy(res.data,'recv_date').reverse() });
    }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  };

  removeFilter = () => {
    var mindate=moment().subtract(7,'d').format('YYYY-MM-DD');
    var maxdate=moment().format('YYYY-MM-DD');
    this.setState( {
    selMinRptDate: mindate,
    selMaxRptDate: maxdate,
    selSupply:''});
    axios.post("/api/getInvLog", {mindate:mindate,maxdate:maxdate}).then(res => {
      this.setState({ InvtLogs: _.sortBy(res.data,'recv_date').reverse() });
     }).catch((error)=>{
       this.errorpop.current.handleOpen();
     });
    // );
  };



  componentDidMount() {
    var mindate=moment().subtract(7,'d').format('YYYY-MM-DD');
    var maxdate=moment().format('YYYY-MM-DD');
    this.setState( {
    selMinRptDate: mindate,
    selMaxRptDate: maxdate});
    axios.get("/api/getDistinctMaterial").then(res => {
      this.setState({ supplyData: res.data });
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
     axios.post("/api/getInvLog", {mindate:mindate,maxdate:maxdate}).then(res => {
     this.setState({ InvtLogs: _.sortBy(res.data,'recv_date').reverse() });
    }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  }

  
render() {
    const { sort_col, sort_dir } = this.state;

    var nmIcon = null;
    if (sort_col !== "supplyNM") nmIcon = <Icon name='sort' />;

    var rcvDTIcon = null;
    if (sort_col !== "recv_date") rcvDTIcon = <Icon name='sort' />;


    var lotIcon = null;
    if (sort_col !== "lot_no") lotIcon = <Icon name='sort' />;


    var amtIcon = null;
    if (sort_col !== "trans_amt") amtIcon = <Icon name='sort' />;


  
    var personIcon = null;
    if (sort_col !== "operator") personIcon = <Icon name='sort' />;

      
    var databody = 
        this.state.InvtLogs.map((data, index) => {
      // console.log(data);
      var lot_no=data.lot_no;
      if (data.operation==='U') lot_no='Used' 
      else if (data.operation==='A') lot_no='Adjust';
      return (
        <Table.Row key={"r" + index}>
          <Table.Cell>{moment(data.recv_date).format("MMM DD,YYYY")}</Table.Cell>
          <Table.Cell>{data.supplyNM}</Table.Cell>
          <Table.Cell>{lot_no}</Table.Cell>
          <Table.Cell>
            {data.trans_amt}
          </Table.Cell>
          <Table.Cell>
            {data.unit_price}
          </Table.Cell>
          <Table.Cell>{data.operator}</Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <Form>
          <Form.Group>
            {/* <Form.Field
              control={Input}
              label="Report ID"
              placeholder="Search by Report ID"
              fluid
              value={this.state.selRptID?this.state.selRptID:''}
              onChange={(e, data) => this.saveOption("rptID", data)}
              width={4}
            /> */}
            <Form.Field
              control={Dropdown}
              label="Supplies"
              placeholder="Select Supply"
              fluid
              search
              options={this.state.supplyData}
              selection
              value={this.state.selSupply}
              onChange={(e, data) => this.setState({selSupply:data.value})}
              width={8}
            />
            <Form.Field
              control={Flatpickr}
              label="Receive Date"
              value={[this.state.selMinRptDate, this.state.selMaxRptDate]}
              options={{
                altInput: true,
                altFormat: "M j, Y",
                dateFormat: "Y-m-d",
                mode:"range",
                defaultDate: [this.state.selMinRptDate, this.state.selMaxRptDate]
                }}
              onChange={(selectedDates, dateStr, instance) => {
                if (selectedDates.length>1) this.setState({ selMinRptDate: moment(selectedDates[0]).format('YYYY-MM-DD') ,selMaxRptDate: moment(selectedDates[1]).format('YYYY-MM-DD')  })
              }}
              width={4}
            />
            <Button
              icon
              className="search-button"
              primary
              onClick={this.applyFilter}
            >
              <Icon name="search" />
            </Button>
            <Button
              icon
              className="search-button"
              secondary
              onClick={this.removeFilter}
            >
              <Icon name="delete" />
            </Button>
            
          </Form.Group>{" "}
        </Form>
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell sorted={sort_col === "recv_date" ? sort_dir : null}
                onClick={this.handleSort("recv_date")} >Receive Date  {rcvDTIcon}</Table.HeaderCell>
              <Table.HeaderCell sorted={sort_col === "supplyNM" ? sort_dir : null}
                onClick={this.handleSort("supplyNM")} > SupplyNm  {nmIcon}</Table.HeaderCell>
              <Table.HeaderCell sorted={sort_col === "lot_no" ? sort_dir : null}
                onClick={this.handleSort("lot_no")}>Lot Number {lotIcon} </Table.HeaderCell>
                <Table.HeaderCell sorted={sort_col === "trans_amt" ? sort_dir : null}
                onClick={this.handleSort("trans_amt")} >Quatity (unit) {amtIcon}</Table.HeaderCell>
                                <Table.HeaderCell >Unit Price</Table.HeaderCell>
                <Table.HeaderCell sorted={sort_col === "operator" ? sort_dir : null}
                onClick={this.handleSort("operator")} >Received By  {personIcon}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{databody}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
        </div>
    );
  }
}
