import React, { Component } from 'react';
import { Grid, Button, Form, Header, Segment, Message } from 'semantic-ui-react';
import './loginform.css';
import Mainpage from '../mainpage/Mainpage';
import Cookies from 'universal-cookie';
const  invalid_password_ERROR=<Message 
error
header='Invalid password'
content='The username/password combination is invalid, please try again!'
visible
 />;
var crypto = require('crypto');
const cookies = new Cookies();


// var SaltLength = 9;

function createHash(password) {
  var salt = '39DFvnd03';
  var hash = md5(password + salt);
  return salt + hash;
}


// function generateSalt(len) {
//   var set = '0123456789abcdefghijklmnopqurstuvwxyzABCDEFGHIJKLMNOPQURSTUVWXYZ',
//       setLen = set.length,
//       salt = '';
//   for (var i = 0; i < len; i++) {
//     var p = Math.floor(Math.random() * setLen);
//     salt += set[p];
//   }
//   return salt;
// }

function md5(string) {
  return crypto.createHash('md5').update(string).digest('hex');
}


class loginform extends Component {

    constructor(props) {
        super(props);
   
        // This binding is necessary to make `this` work in the callback
        this.handleClick = this.handleClick.bind(this);
        this.state = {loggedin: false,
           username:'',
           password:'',
           message:''
            };
      }

 checkPassword=async()=>{
 const response=await 
 fetch('/api/checkpswd', {
   method: 'POST',
   headers:{
     'Accept': 'application/json',
     'Content-Type': 'application/json'
   },
   body: JSON.stringify({
     username:this.state.username,
     password:this.state.password
   })
 });
 const body=await response.json();
//  console.log(body);
 if (response.status !== 200) throw Error(body.message);
 return body;
 }

  handleClick() 
   {
    var self = this;
    // console.log(this.state.username+':'+this.state.password);
    this.checkPassword().then((res)=>{
    if (res.passed===true) 
    {this.setState({loggedin: true});
    cookies.set('auth-token', res.token, { path: '/' });
    // cookies.set('id', res.id, { path: '/' });
    cookies.set('userkey', res.userkey, { path: '/' });
    // cookies.set('nm', res.name, { path: '/' });
    // console.log('loggedin '+this.state.loggedin);
      var sysScreen=<Mainpage appContext={self.props.appContext}/>;
       self.props.appContext.setState({loginPage:null,sysScreen:sysScreen})
    }
    else 
    {
    this.setState({message:invalid_password_ERROR});
    }
    })
    }

  render() {
    return (
        <div className='login-form'>
        <Grid className="logingrid" textAlign='center' verticalAlign='middle' centered columns={3}>
        <Grid.Column>
        <Header as='h1' color='black' textAlign='center'>
          Food Safety Research Laboratory, Inc.
        </Header>
        <Header as='h2' color='blue' textAlign='center'>
          Log-in to your account
        </Header>
        <Form size='large'>
        <Segment stacked>
        <Form.Input fluid icon='user' iconPosition='left' placeholder='User Name' onChange = {(event,{ name, value }) => this.setState({username:value})}/>
        <Form.Input
              fluid
              icon='lock'
              iconPosition='left'
              placeholder='Password'
              type='password'
              onChange = {(event,{ name, value }) => this.setState({password:createHash(value)})
              }
            />
        <Button type='submit' fluid size='large' color='blue'
          onClick={this.handleClick}>Login</Button>
          {this.state.message}
        </Segment>
      </Form>
      </Grid.Column>
      </Grid>
       </div>
    );
  }
}

export default loginform;
