import React, { Component } from "react";
import { Header, Table, Dropdown, Form, Button, Message, Checkbox,Icon,  Input} from "semantic-ui-react";
import _ from "underscore";
import "./AdminPrice.css";
import Errorpop from "../error/Errorpop";
import moment from "moment";
import * as Constants from "../common/constants.js";

export default class AdminPrice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custData: [],
      testData: [],
      priceData: [],
      custID:null,
      sort_col: null,
      sort_dir: null,
      saveButtonDisabled: true
    };
  }

  handleSort = clickedColumn => () => {
    const { sort_col, priceData, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        priceData: _.sortBy(priceData, function (i) { return i[clickedColumn].toLowerCase(); }),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      priceData: priceData.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


  getCusts = async () => {
    const response = await fetch("/api/getCustomer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getPrices = async (custID) => {
    const response = await fetch("/api/getPrice?custID="+custID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  pickCustomer = (e, data) => {
    //console.log(JSON.stringify(data))  ;
    var custKey=data.value;
    this.setState({
      custID: data.value,
      saveButtonDisabled : false
    });
    this.getPrices(data.value).then(res => {
      console.log(res);
      var fullPriceData=[];
      this.state.testData.map((data,index)=>{
        console.log(custKey);
        // console.log(_.where(this.state.priceData,{cust_id:this.state.custID,test_id:data.key.toString()}));
          fullPriceData.push({
        checked: _.where(res,{cust_id:custKey,test_id:data.key.toString()}).length>0?true:false,
          cust_id: custKey,
          test_id: data.key.toString(),
          unit_price: _.where(res,{cust_id:custKey,test_id:data.key.toString()}).length>0
          ?_.where(res,{cust_id: custKey,test_id:data.key.toString()})[0].unit_price:0,
          confirm_price: _.where(res,{cust_id:custKey,test_id:data.key.toString()}).length>0
          ?_.where(res,{cust_id: custKey,test_id:data.key.toString()})[0].confirm_price:0,
          cust_nm: _.where(this.state.custData,{key:parseInt(custKey)})[0].text,
          tst_nm: _.where(this.state.testData,{key:data.key})[0].text
        })
      })


      this.setState({ priceData: fullPriceData });
    });
  };

  componentDidMount() {
    this.getCusts().then(res => {
      console.log(res);
      res=_.sortBy(res, function (i) { return i["text"].toLowerCase(); });
      this.setState({ custData: res });
    });
    this.getTests().then(res => {
        console.log(res);
        res=_.sortBy(res, function (i) { return i["text"].toLowerCase(); });
        this.setState({ testData: res.concat(Constants.MiscItems) });
      });
  }
 
   updatePrice = (d,data,index) =>{

     var  newData=this.state.priceData;
     newData[index].unit_price=d.value;
     newData[index].updated=true;
     this.setState({priceData:newData});
   }


    
   updateConfirmPrice = (d,data,index) =>{

    var  newData=this.state.priceData;
    newData[index].confirm_price=d.value;
    newData[index].updated=true;
    this.setState({priceData:newData});
  }

    checked = (data,index) => {
      var  newData=this.state.priceData;
     newData[index].checked=!newData[index].checked;
     console.log(newData[index])
      this.setState({priceData:newData});
 
    }


    delPrices = async (payload) => {
      const response = await fetch("/api/delPrice", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });
      const body = await response.json();
      if (response.status !== 200) throw Error(body.message);
      return body;
    };

    insertPrices = async (payload) => {
      const response = await fetch("/api/insertPrice", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify(payload)
      });
      const body = await response.json();
      if (response.status !== 200) throw Error(body.message);
      return body;
    };


    saveData = () => {
      var delids=[];
      var insdata=[];
      var  newData=this.state.priceData;
      
      for (var i in this.state.priceData)
       if (this.state.priceData[i].checked && ( this.state.priceData[i].updated || this.state.priceData[i].unit_price===0))
       { 
         insdata.push("("+this.state.priceData[i].cust_id+","+this.state.priceData[i].test_id+","+this.state.priceData[i].unit_price+","+this.state.priceData[i].confirm_price+",'"+moment().format('YYYY-MM-DD hh:mm:ss')+"')")
         delids.push("(cust_id="+this.state.priceData[i].cust_id+" AND test_id="+this.state.priceData[i].test_id+")")
         newData[i].updated=false;
       }
       var delpayload=delids.join("OR");
       var inspayload=insdata.join(",");
       console.log(delpayload);
       console.log(inspayload);
      this.delPrices({payload:delpayload,time:moment().format('YYYY-MM-DD hh:mm:ss')}).then(()=>{
            this.insertPrices({payload:inspayload}).then(()=>{
  
               this.setState({priceData:newData});
            })
      })

    }

    render() {
    const { sort_col, priceData, sort_dir } = this.state;
 console.log(priceData);
// console.log(status);

// if (this.state.custID) 


// console.log(fullPriceData);

var custTableRows=priceData.map((data,index) => {
  var priceCell= data.unit_price;
  var cfmpriceCell=data.confirm_price;
  if (data.checked) 
   {priceCell=
  <Input value={data.unit_price} onChange={(e, d) => this.updatePrice(d,data,index)}/>
  cfmpriceCell=
  <Input value={data.confirm_price} onChange={(e, d) => this.updateConfirmPrice(d,data,index)}/>
   }
  return (
    <Table.Row key={"c" + index}
      >
      <Table.Cell> <Checkbox  checked={data.checked}
                onChange={(e, d) => this.checked(data, index)}
                /></Table.Cell>
      <Table.Cell>{data.tst_nm}</Table.Cell>
      <Table.Cell>{priceCell}</Table.Cell>
      <Table.Cell>{cfmpriceCell}</Table.Cell>
    </Table.Row>
  );
})

var nmIcon = null;
if (sort_col !== "tst_nm") nmIcon = <Icon name='sort' />;

    return (
      <div>
        <Header as="h1">Invoice Management</Header>
        <Form className="receiveForm">
          <Form.Group widths="equal">
            <Form.Field
              control={Dropdown}
              label="Select Customer"
              placeholder="Select Customer"
              width={4}
              search
              selection
              options={this.state.custData}
              onChange={this.pickCustomer}
              value={this.state.custID}
            />
            </Form.Group>
            </Form>

      <Table fixed celled padded sortable size='small'>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell width={1}/>
              <Table.HeaderCell singleLine
                sorted={sort_col === "tst_nm" ? sort_dir : null}
                onClick={this.handleSort("tst_nm")} width={3}
              > Test Name {nmIcon} </Table.HeaderCell>
                <Table.HeaderCell singleLine width={1}
              > Price </Table.HeaderCell>
                              <Table.HeaderCell singleLine width={1}
              > Confirmation Price </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{custTableRows}</Table.Body>
        </Table>  
        <Errorpop ref={this.errorpop} />
        <Button icon className='save-button' primary
              onClick={this.saveData} 
              disabled={this.state.saveButtonDisabled}>
              Save
            </Button>
      </div>

    );
  }
}
