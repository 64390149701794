import React, { Component } from "react";
import { Table, Input, Button, Checkbox, Header, Form, Dropdown, Icon, Confirm } from "semantic-ui-react";
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
// import ReviseSample from "../ReviseSample/ReviseSample"
import "./UpdateSample.css";
import Errorpop from "../error/Errorpop";
import _ from "underscore";
import moment from "moment";
import axios from "axios";

export default class UpdateSampe extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleData: [],
      checkedall: false,
      sort_col: null,
      sort_dir: null,
      reportInactive: true,
      savecnt: 0,
      custOptions: [],
      testOptions: [],
      selCust: null,
      selTest: null,
      selRcvDate: null,
      confirmDel: false,
      expansionList: [],
      checkAllList:[],
      openEditModal: false
    };
    this.applyFilter = this.applyFilter.bind(this);
    this.removeFilter = this.removeFilter.bind(this);
    this.expand = this.expand.bind(this);
    this.checked = this.checked.bind(this);
    this.errorpop = React.createRef();
  }

  openConfirmDel = () => {
    this.setState({ confirmDel: true })
  }

  handleCancelDel = () => {
    this.setState({ confirmDel: false })
  }

  handleConfirmDelete = () => {

    var delIDList = _.pluck(_.where(this.state.sampleData, { checked: true }), 'labelID');

    this.delMultiSamples(_.map(delIDList, function (str) { return "'" + str + "'" }).join(',')).then(res => {
      var newData = _.reject(this.state.sampleData, function(item){return item.checked});
      this.setState({ sampleData: newData });
    }
    )
      .catch(err => { console.log("ERROR in save tests " + err); })

    console.log(_.map(delIDList, function (str) { return "'" + str + "'" }).join(','));
    this.setState({ confirmDel: false })
  }

  updateDesc = (d,data) => {
    var sampleData=this.state.sampleData;
    for (var i in sampleData)
    if (sampleData[i].labelID===data.labelID) sampleData[i].desc=d.value;
    this.setState({sampleData:sampleData});
  }

  
  updateSeq = (d,data) => {
    var sampleData=this.state.sampleData;
    for (var i in sampleData)
    if (sampleData[i].labelID===data.labelID) sampleData[i].seqid=d.value;
    this.setState({sampleData:sampleData});
  }

  updateUnit = (d,data) => {
    var sampleData=this.state.sampleData;
    for (var i in sampleData)
    if (sampleData[i].labelID===data.labelID) sampleData[i].unit=d.value;
    this.setState({sampleData:sampleData});
  }

  updateTemp = (d,data) => {
    var sampleData=this.state.sampleData;
    for (var i in sampleData)
    if (sampleData[i].labelID===data.labelID) sampleData[i].temp=d.value;
    this.setState({sampleData:sampleData});
  }

  updateRcvDate = (d,data) =>{
    // console.log(d);
    // console.log(data);
    var sampleData=this.state.sampleData;
    for (var i in sampleData)
    if (sampleData[i].labelID===data.labelID) sampleData[i].SampleDate=moment(d,'YYYY-MM-DD HH:mm');
    this.setState({sampleData:sampleData});
  }
  enableEdit = (data) =>{
    var sampleData=this.state.sampleData;
    for (var i in sampleData)
    if (sampleData[i].labelID===data.labelID) sampleData[i].editing=true;
    this.setState({sampleData:sampleData});
  }

  saveEdit = (data) => {
    var sampleData=this.state.sampleData;
    for (var i in sampleData)
    if (sampleData[i].labelID===data.labelID) 
    { sampleData[i].editing=false;
      this.saveUpdSample(sampleData[i]).then(()=>{
        this.setState({sampleData:sampleData});
      }).catch((error)=>{console.log("Issue with DB Access")})
    }
    
  }

  copySample=(data)=>{
    var sampleData=this.state.sampleData;
    var newData=JSON.parse(JSON.stringify(data));
    // console.log(sampleData);
    // console.log(data);
    this.copyNewSample(newData).then((res)=>{
      console.log(res);
      newData.labelID=newData.labelID+'-D';
      newData.seqid=newData.seqid+0.1;
      console.log(newData);
    sampleData.push(newData);
    this.setState({sampleData:sampleData});
    }).catch((error)=>{console.log("Issue with DB Access")})
    //  for (var i in sampleData) {
    //   if (sampleData[i].labelID===data.labelID) {
    //     sampleData.push(newData);
    //   } 
    //  }
  }

  copyNewSample = async payload => {
    // console.log(payload);
    const response = await fetch("/api/copySample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    console.log(body);
    if (body.status==="ERROR") throw Error("Issue in DB access")
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  saveUpdSample = async payload => {
    // console.log(payload);
    const response = await fetch("/api/saveUpdSample", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    //console.log(body);
    if (body.status==="ERROR") throw Error("Issue in DB access")
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  delMultiSamples = async (id) => {
    var payload = { 'id': id };
    const response = await fetch("/api/delMultiSamples", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    else if (body.status === 'ERROR') throw Error(body.message);
    return body;
  };

  handleSort = clickedColumn => () => {
    const { sort_col, sampleData, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        sampleData: _.sortBy(sampleData, [clickedColumn]),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      sampleData: sampleData.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


  expand = (event, data) => {
    var newList = this.state.expansionList;
    if (_.size(_.where(newList,
      {
        custNM: data.custNM,
        recvDT: data.recvDT,
        analysisNM: data.analysisNM
      })) === 0)
      newList.push({
        custNM: data.custNM,
        recvDT: data.recvDT,
        analysisNM: data.analysisNM
      });
    else
      newList = _.reject(newList, function (item) {
        return item.custNM === data.custNM && item.recvDT === data.recvDT && item.analysisNM === data.analysisNM;
      });
      // console.log(newList);
    this.setState({ expansionList: newList });
  };

  checked = (data, index) => {
 var checkMulti=false;
    var checkList=[];
    var checkAllList=this.state.checkAllList;
    if (data.line_type!=='summary') 
     checkList.push(data.labelID); 
    else 
    {checkList=_.pluck(_.where(this.state.sampleData, {
      custNM: data.custNM,
      recvDT: data.recvDT,
      analysisNM: data.analysisNM
    }),'labelID');
    if (checkAllList.length===0 || _.size(_.where(checkAllList, {
      custNM: data.custNM,
      recvDT: data.recvDT,
      analysisNM: data.analysisNM
    }))===0) {checkAllList.push({
      custNM: data.custNM,
      recvDT: data.recvDT,
      analysisNM: data.analysisNM
    });
    checkMulti=true;
  }
    else {
      checkAllList = _.reject(checkAllList, function (item) {
        return item.custNM === data.custNM && item.recvDT === data.recvDT && item.analysisNM === data.analysisNM;
      });
    }
    this.setState({'checkAllList':checkAllList});
    
  }
//  console.log(checkList);
    var newData = this.state.sampleData;
    for (var i in newData) 
    {
      
      if (_.indexOf(checkList,newData[i].labelID)>=0)
      {
      
    if (newData[i].checked && checkMulti===false) {
      newData[i].checked = false;
      newData[i].start_date = "";
      newData[i].end_date = "";
      this.setState({
        reportInactive: this.state.savecnt === 1 ? true : false,
        savecnt: this.state.savecnt - 1
      });
      if (this.state.checkedall)
        this.setState({ checkedall: !this.state.checkedall });
      if (_.size(_.where(this.state.checkAllList, {
        custNM: newData[i].custNM,
        recvDT: newData[i].recvDT,
        analysisNM: newData[i].analysisNM
      }))>0) {
        this.setState({checkAllList:_.reject(this.state.checkAllList, function (item) {
          return item.custNM === newData[i].custNM && item.recvDT === newData[i].recvDT && item.analysisNM === newData[i].analysisNM;
        })});
      }
    } else {
      newData[i].checked = true;
      this.setState({ savecnt: this.state.savecnt + 1, reportInactive: false });
    }
  }
  }
  this.setState({ sampleData: newData });
  };

  checkall = () => {
    var newData = this.state.sampleData;
    var index = 0;
    if (!this.state.checkedall)
      for (index in newData) {
        if (!newData[index].checked) {
          newData[index].checked = true;
          newData[index].start_date = moment().format("YYYY-MM-DD HH:mm");
          newData[index].end_date = moment()
            .add(newData[index].dflt_dur, "hours")
            .format("YYYY-MM-DD HH:mm");
          this.setState({
            savecnt: this.state.savecnt + 1,
            reportInactive: false
          });
        }
      }
    else
      for (index in newData) {
        if (newData[index].checked) {
          newData[index].checked = false;
          newData[index].start_date = "";
          newData[index].end_date = "";
          this.setState({
            savecnt: this.state.savecnt - 1,
            reportInactive: true
          });
        }
      }
    this.setState({ sampleData: newData, checkedall: !this.state.checkedall });
  };

  getAllDrafts = async () => {
    const response = await fetch("/api/getAllDrafts?cust=" + this.state.selCust + "&test=" +
      this.state.selTest + "&date=" + this.state.selRcvDate, {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      });
    const body = await response.json();
    //  console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  commitTest = async payload => {
    // console.log(payload);
    const response = await fetch("/api/commitTest", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  saveData = () => {
    var newData = this.state.sampleData;
    var saveAll = _.where(newData, { checked: true }).map((data, index) => {
      return this.commitTest(data);
    });
    Promise.all(saveAll)
      .then(result => {

        newData = _.reject(newData, function (data) {
          return _.where(result, { labelID: data.labelID, status: "SUCCESS" }).length > 0;
        });
        this.setState({
          sampleData: newData,
          savecnt: _.where(newData, { checked: true }).length,
          reportInactive: _.where(newData, { checked: true }).length === 0
        });

      })
      .catch(error => {
        console.log("ERROR in save tests " + error);
      });
  };

  getAllCustomers = async () => {
    const response = await fetch("/api/getCustomer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  applyFilter() {
    this.getAllDrafts().then(res => {
      this.setState({ sampleData: _.sortBy(_.sortBy(_.sortBy(res, 'testid'),'recvDT'),'custid') });
    });
  }

  removeFilter = () => {
    this.setState({ selCust: null, selTest: null, selRcvDate: null }, () => {
      this.getAllDrafts().then(res => {
        this.setState({ sampleData: _.sortBy(_.sortBy(_.sortBy(res, 'testid'),'recvDT'),'custid') });
      })
    });
  }

  openEditModal = (event,data) => {
    this.setState({openEditModal:true});
  }

  componentWillMount() {
    this.getAllDrafts().then(res => {
      // console.log(res);
      this.setState({ sampleData: _.sortBy(_.sortBy(_.sortBy(res, 'testid'),'recvDT'),'custid') });
    });
    this.getAllCustomers().then(res => {
      this.setState({ custOptions: res });
    });
    this.getAllTests().then(res => {
      this.setState({ testOptions: res });
    });
  }

  saveOption = (type, data) => {
    if (type === 'customer')
      this.setState({ selCust: data.value });
    else if (type === 'test')
      this.setState({ selTest: data.value });
    else if (type === 'rcvDt')
      this.setState({ selRcvDate: data });
  }

  startEdit = (data,index) => {
    
    console.log(data);
    console.log(index);
    var newData=[];
    var labelIDs=[];
    var i=index+1;
    while (i<data.length && data[i].line_type!=='summary' ) 
    {
      newData.push(data[i]);
      labelIDs.push("'"+data[i].labelID+"'");
      i++;
    }
    console.log(labelIDs);
    axios.post("/api/queryUpdates",{"id":"("+labelIDs.join(",")+")"}).then(result => {
      if (result.status!=="ERROR")
      {
        this.props.onDraftSelected(result.data);
      }
      else 
      { console.log("error" + JSON.stringify(result));
      this.errorpop.current.handleOpen();
     }
      })
    
  }


  render() {
    const { sort_col, sampleData, sort_dir } = this.state;

    // Create groups of records
    var newData = []
    var currCus = null;
    var currDt = moment('0001-01-01');
    var currTst = null;
    var lastSumLine= -1;
    var lastBeginLine=0;
    for (var i in sampleData) {
      sampleData[i].origID=i;
      if (currCus !== sampleData[i].custNM || currDt !== sampleData[i].recvDT || currTst !== sampleData[i].analysisNM) {

        currCus = sampleData[i].custNM;
        currDt = sampleData[i].recvDT;
        currTst = sampleData[i].analysisNM;
        // console.log(this.state.checkAllList);
        if (this.state.checkAllList.length===0 || _.size(_.where(this.state.checkAllList, {
          custNM: currCus,
          recvDT: currDt,
          analysisNM: currTst
        }))===0)
        newData.push({ line_type: 'summary', custNM: currCus, recvDT: currDt, analysisNM: currTst, checked: false, hidden: false });
        else 
        newData.push({ line_type: 'summary', custNM: currCus, recvDT: currDt, analysisNM: currTst, checked: true, hidden: false });
        if (lastSumLine>-1) newData[lastSumLine].seqid=(i-lastBeginLine)+' Samples';
        lastBeginLine=i;
        lastSumLine=_.size(newData)-1;
        newData.push(sampleData[i])
      }
      else newData.push(sampleData[i])

    }

    // console.log(newData);

  if (lastSumLine>-1) newData[lastSumLine].seqid=(i-lastBeginLine+1)+' Samples';

    var DataCell = newData.map((data, index) => {
      if (data.line_type==='summary') data.hidden=false;
      else if (_.size(_.where(this.state.expansionList, {
        custNM: data.custNM,
        recvDT: data.recvDT,
        analysisNM: data.analysisNM
      })) > 0) data.hidden=false; else data.hidden=true;
        // Define the triangle before the name
      var nameIcon = null;
      var editButton=null;
      var dupButton=null;
  
      if (data.line_type === 'summary') {

        if (_.size(_.where(this.state.expansionList, {
          custNM: data.custNM,
          recvDT: data.recvDT,
          analysisNM: data.analysisNM
        })) === 0) nameIcon = <Icon name='triangle right' size='large'

          onClick={(e) => this.expand(e, data)} />

        else nameIcon = <Icon name='triangle down' size='large'

          onClick={(e) => this.expand(e, data)} />

          editButton=<Icon className='editicon' name='edit' size='large' onClick={(e) => this.startEdit(newData,index)}/>
      }
else {
//  if (data.editing)
//  editButton=<Icon className='editicon' name='save' size='large' onClick={(e) => this.saveEdit(data)}/>
//  else 
//   editButton=<Icon className='editicon' name='edit' size='large' onClick={(e) => this.enableEdit(data)}/>
//   if (!data.labelID.endsWith('-D') && _.where(newData,{labelID:data.labelID+'-D'}).length===0) 
//   dupButton=<Icon className='editicon' name='copy' size='large' onClick={(e) => this.copySample(data)}/>
}
     var descInput='';
    //  var unitInput='';
    //  var tempInput='';
     var dateInput='';
     var seqInput='';
      if (data.editing) {
         descInput=<Input className='descInput' value={data.desc} onChange={(e, d) => this.updateDesc(d,data)}/>
         seqInput=<Input className='seqInput' value={data.seqid} onChange={(e, d) => this.updateSeq(d,data)}/>
        // unitInput=<Input className='newInput' value={data.unit} fluid onChange={(e, d) => this.updateUnit(d,data)}/>
      //  tempInput=<Input className='newInput' value={data.temp} fluid onChange={(e, d) => this.updateTemp(d,data)}/>
       dateInput=<Input size='small' fluid> <Flatpickr
       options={{
         defaultDate: moment(data.SampleDate).format("YYYY-MM-DD HH:mm"),
         enableTime: true
       }}
       value={moment(data.SampleDate).format("YYYY-MM-DD HH:mm")}
       onChange={(e, d) =>
         this.updateRcvDate(d, data)
       }
     /></Input>
      }
      else 
      {descInput=data.desc;
        // unitInput=data.unit;
        dateInput=data.line_type === 'summary' ? '' : moment(data.SampleDate).format("YYYY-MM-DD HH:mm");
        // tempInput=data.temp;
        seqInput=data.seqid;
      }
      
      if (data.hidden === false)
        return (
          <Table.Row key={"s" + index}>
            <Table.Cell>
            {editButton}
              {/* <Checkbox className='checkbox'
                checked={data.checked}
                onChange={(e, d) => this.checked(data, index)}
              />
                            {editButton}
                            {dupButton} */}
            </Table.Cell>
            <Table.Cell>
              {nameIcon}
              {data.custNM}

            </Table.Cell>
            <Table.Cell>{moment(data.recvDT).format("YYYY-MM-DD")}</Table.Cell>
            <Table.Cell>{data.analysisNM}</Table.Cell>
            <Table.Cell>{seqInput}
  </Table.Cell>
  <Table.Cell>{descInput}</Table.Cell>
            <Table.Cell>
              {dateInput}
            </Table.Cell>
            {/* <Table.Cell>{unitInput}</Table.Cell>
            <Table.Cell>{tempInput}</Table.Cell> */}
          </Table.Row>
        );
      else return null;
    });

    var labelIcon = null;
    if (sort_col !== "labelID") labelIcon = <Icon name='sort' />;

    var nmIcon = null;
    if (sort_col !== "custNM") nmIcon = <Icon name='sort' />;

    var sampleDTIcon = null;
    if (sort_col !== "SampleDate") sampleDTIcon = <Icon name='sort' />;

    var rcvDTIcon = null;
    if (sort_col !== "recvDT") rcvDTIcon = <Icon name='sort' />;

    var testIcon = null;
    if (sort_col !== "analysisNM") testIcon = <Icon name='sort' />;

    return (
      <div>
        <Header>
          You can update and delete samples below:
        </Header>
        <Form>
          <Form.Group>
            <Form.Field
              control={Dropdown}
              label="Customer"
              placeholder="Select Customer"
              fluid
              search
              selection
              value={this.state.selCust}
              options={this.state.custOptions}
              onChange={(e, data) => this.saveOption('customer', data)}
              width={8}
            />
            <Form.Field
              control={Dropdown}
              label="Test"
              placeholder="Select Test"
              fluid
              search
              selection
              value={this.state.selTest}
              options={this.state.testOptions}
              onChange={(e, data) => this.saveOption('test', data)}
              width={4}
            />
            <Form.Field
              control={Flatpickr}
              label="Receive Date"
              value={this.state.selRcvDate}
              onChange={(e, data) => this.saveOption('rcvDt', data)}
              width={4}
            />
            <Button icon className='search-button' primary
              onClick={this.applyFilter}>
              <Icon name='search' />
            </Button>
            <Button icon className='search-button' secondary
              onClick={this.removeFilter}>
              <Icon name='delete' />
            </Button>
          </Form.Group> </Form>
        <Table celled sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={1}>
                {/* {" "}
                <Checkbox
                  checked={this.state.checkedall}
                  onChange={(e, data) => this.checkall()}
                />{" "} */}
              </Table.HeaderCell>

              <Table.HeaderCell 
                width={3}
                sorted={sort_col === "custNM" ? sort_dir : null}
                onClick={this.handleSort("custNM")}
              >
                Customer
                {nmIcon}
              </Table.HeaderCell>

              <Table.HeaderCell
                sorted={sort_col === "recvDT" ? sort_dir : null}
                onClick={this.handleSort("recvDT")}
                width={1}
              >
                Receive Date
               {rcvDTIcon}
              </Table.HeaderCell>
              <Table.HeaderCell
                sorted={sort_col === "analysisNM" ? sort_dir : null}
                onClick={this.handleSort("analysisNM")}
                width={2}
              >
                Test Type
                {testIcon}
              </Table.HeaderCell>
              <Table.HeaderCell
              width={2}
              // sorted={sort_col === "labelID" ? sort_dir : null}
              // onClick={this.handleSort("labelID")}
              >
                No.
                {/* {labelIcon} */}
              </Table.HeaderCell>
              <Table.HeaderCell width={5}>Description</Table.HeaderCell>
              <Table.HeaderCell width={2}
              // sorted={sort_col === "SampleDate" ? sort_dir : null}
              // onClick={this.handleSort("SampleDate")}
              >
                Sample Date/Time
                {/* {sampleDTIcon} */}
              </Table.HeaderCell>

              {/* <Table.HeaderCell width={1}>Unit</Table.HeaderCell>
              <Table.HeaderCell width={1}>Temp</Table.HeaderCell> */}
            </Table.Row>
          </Table.Header>
          <Table.Body>{DataCell}</Table.Body>
        </Table>

        {/* <Button
          color="green"
          disabled={this.state.reportInactive}
          onClick={this.saveData}
          className="saveBut"
        >
          Save
        </Button> */}

        <Button
          color="red"
          disabled={this.state.reportInactive}
          onClick={this.openConfirmDel}
          className="saveBut"
        >
          Delete
        </Button>
        <Errorpop ref={this.errorpop} />
        <Confirm open={this.state.confirmDel} content='This action will delete all entries selected.'
          onCancel={this.handleCancelDel} onConfirm={this.handleConfirmDelete} />
      </div>
    );
  }
}
