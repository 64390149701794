import React, { Component } from "react";
import { Header, Checkbox, Confirm } from "semantic-ui-react";


export default class AdminExternalSite extends Component {
  constructor(props) {
    super(props);
    this.state = {
    site_available:true,
    confirmChange:false,
    confirmContent:""
    };
}


getSiteState = async () => {
    const response = await fetch("/api/getsitestate", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
    const body = await response.json();
    //    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  setSiteState = async (newstate) => {
    const response = await fetch("/api/setsitestate", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
          state:newstate
      })
    });
    const body = await response.json();
    //    if (response.status !== 200) throw Error(body.message);
    return body;
  };



  handelCancelChange=()=>{
      this.setState({confirmChange:false})
  }

  handleConfirmChange=()=>{
      var newSite=!this.state.site_available;
      console.log(newSite);
      this.setSiteState(newSite?'ON':'OFF').then(res=>{
      this.setState({site_available:newSite,confirmChange:false})
      })
  }

componentWillMount()
{
  this.getSiteState().then( res=>{
      if (res[0].SITE_STATUS==='ON')
      this.setState({site_available:true})
      else
      this.setState({site_available:false})
  })    
} 


  render() {
    return (
      <div>
        <Header as="h1">External Site Status</Header>
        <Header as="h2">Ths external website is currently {this.state.site_available?"ON":"OFF"} </Header>
        <Header as="h2"> Please use the following toggle to turn it {this.state.site_available?"off":"on"} </Header>
        <Checkbox toggle checked={this.state.site_available} onChange={()=>
            {this.setState({confirmChange:true,
             confirmContent:this.state.site_available?"This action will disable access to the external website. Please confirm the action.":
             "This action will enable access to the external website. Please confirm the action."})}} /> 
        <Confirm open={this.state.confirmChange} content={this.state.confirmContent}
              onCancel={this.handelCancelChange} onConfirm={this.handleConfirmChange} />    
    </div>
    );
  }
}
