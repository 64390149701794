import React, { Component } from "react";
import {
  Header,
  Table,
  Button,
  Modal,
  Icon,
  Dropdown,
  Input,
  Form
} from "semantic-ui-react";

import "./InvtRpt.css";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";

export default class InvtRpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      supplyData: [],
      selSupply:'',
      selRptDate: '2199-01-01',
      InvtRpt:[],
      sort_col: null,
      sort_dir: null,
      };
    this.errorpop = React.createRef();
  }

  toggleTimeCheck = () => this.setState({ TimeCheck: !this.state.TimeCheck })


    handleSort = clickedColumn => () => {
    const { sort_col, InvtRpt, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        InvtRpt: _.sortBy(InvtRpt, [clickedColumn]),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      InvtRpt: InvtRpt.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


 isValidFloat = (str) => {
  return /^\-?[0-9]+(e[0-9]+)?(\.)?([0-9]+)?$/.test(str);
}

  
  applyFilter = () => {
    axios.post("/api/getInvBal", {supplyNM:this.state.selSupply,RptDate:this.state.selRptDate}).then(res => {
      // console.log(res);
     this.setState({ InvtRpt: _.sortBy(res.data,'supplyNM').reverse() });
    }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  };

  removeFilter = () => {
    var maxdate=moment().format('YYYY-MM-DD');
    this.setState( {
      selSupply:'',
    selRptDate: maxdate});
    axios.post("/api/getInvBal", {RptDate:maxdate}).then(res => {
      this.setState({ InvtRpt: _.sortBy(res.data,'supplyNM').reverse() });
     }).catch((error)=>{
       this.errorpop.current.handleOpen();
     });
    // );
  };



  componentDidMount() {
    var maxdate=moment().format('YYYY-MM-DD');
    this.setState( {
    selRptDate: maxdate});
    axios.get("/api/getDistinctMaterial").then(res => {
      this.setState({ supplyData: res.data });
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
     axios.post("/api/getInvBal", {RptDate:maxdate}).then(res => {
     this.setState({ InvtRpt: _.sortBy(res.data,'supplyNM').reverse() });
    }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  }

  
render() {
    const { sort_col, sort_dir } = this.state;

    var nmIcon = null;
    if (sort_col !== "supplyNM") nmIcon = <Icon name='sort' />;


    var endamtIcon = null;
    if (sort_col !== "end_inv_amt") endamtIcon = <Icon name='sort' />;


    var databody = 
        this.state.InvtRpt.map((data, index) => {
      // console.log(data);
      return (
        <Table.Row key={"r" + index}>
          <Table.Cell>{moment(this.state.selRptDate).format("MMM DD,YYYY")}</Table.Cell>
          <Table.Cell>{data.supplyNM}</Table.Cell>
            <Table.Cell>
            {data.end_inv_amt}
          </Table.Cell>
                </Table.Row>
      );
    });

    return (
      <div>
      <Form>
          <Form.Group>
            <Form.Field
              control={Dropdown}
              label="Supplies"
              placeholder="Select Supply"
              fluid
              search
              options={this.state.supplyData}
              selection
              value={this.state.selSupply}
              onChange={(e, data) => this.setState({selSupply:data.value})}
              width={8}
            />
            <Form.Field
              control={Flatpickr}
              label="Report Date"
              value={this.state.selRptDate}
              options={{
                altInput: true,
                altFormat: "M j, Y",
                dateFormat: "Y-m-d",
                defaultDate: this.state.selRptDate
                }}
            onChange={(e, data) => this.setState({selRptDate:data})}
              width={4}
            />
            <Button
              icon
              className="search-button"
              primary
              onClick={this.applyFilter}
            >
              <Icon name="search" />
            </Button>
            <Button
              icon
              className="search-button"
              secondary
              onClick={this.removeFilter}
            >
              <Icon name="delete" />
            </Button>
            
          </Form.Group>{" "}
        </Form>
    
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell >Report Date </Table.HeaderCell>
            <Table.HeaderCell sorted={sort_col === "supplyNM" ? sort_dir : null}
                onClick={this.handleSort("supplyNM")} > Supply  {nmIcon}</Table.HeaderCell>
                <Table.HeaderCell sorted={sort_col === "end_inv_amt" ? sort_dir : null}
                onClick={this.handleSort("end_inv_amt")} >End Amount  {endamtIcon}</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{databody}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
        </div>
    );
  }
}
