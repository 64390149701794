import React, { Component } from "react";
import { Header, Table, Button } from "semantic-ui-react";

import "./LoadDraft.css";
import _ from "underscore";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();

export default class LoadDraft extends Component {
  constructor(props) {
    super(props);
    this.state = { drafts: [] };
    this.getAllDrafts = this.getAllDrafts.bind(this);
  }

  handleEditDraft(data){
  this.getReceiptByDraft(data).then(
res => {
    if (res.status !== "ERROR")
  this.props.onDraftSelected(res);
}
  )
  }

 handleDeleteDraft = (data) => {
  this.getDeleteDrafts(data).then(res=>{
      var newData=_.without(this.state.drafts, _.findWhere(this.state.drafts, {
        cust_id : data.cust_id,
        user_id : data.user_id,
        recv_dt : data.recv_dt,
        test_id : data.test_id
      }));
      this.setState({drafts:newData})  
  })
  .catch(err=>{})
 }

 getReceiptByDraft = async (data) => {
    const response = await fetch("/api/queryDrafts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

 getDeleteDrafts = async (data) => {
    const response = await fetch("/api/delDrafts", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

 
   getAllDrafts = async () => {
    const response = await fetch("/api/getDrafts", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);

    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  getAllTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  componentWillMount() {
    this.getAllTests().then(res => { 
    this.setState({ testOptions: res });
    var testOptions=res;
    this.getAllDrafts().then(res => {
        if (res.status !== "ERROR") 
         { 
          console.log(res);
          for (var i in res) 
          { res[i].TEST=(_.map(res[i].test_id.split(","), function(id){ return _.where(testOptions, {
             value: id
           })[0].text})).join(", ");
          }
          this.setState({ drafts: res });
    }
      //    console.log(res);
    });
  })
  }

  render() {
    var databody = this.state.drafts.map((data, index) => {
        return (
          <Table.Row key={"c" + index}>
            <Table.Cell>{data.CUST_NM}</Table.Cell>
            <Table.Cell>{data.TEST}</Table.Cell>
            <Table.Cell>{data.USER_NM}</Table.Cell>
            <Table.Cell>{data.recv_dt}</Table.Cell>
            <Table.Cell>{data.cnt}</Table.Cell>
            <Table.Cell>
          {/*    <Button onClick={() => this.handleDelCust(data.key)} className='rmvButt'>
                {" "}
                Remove Customer{" "}
        </Button> */}
              <Button onClick={() => this.handleEditDraft(data)}>
                {" "}
                Continue with Draft{" "}
              </Button>
              <Button onClick={() => this.handleDeleteDraft(data)}>
                {" "}
                Delete Draft{" "}
              </Button>
            </Table.Cell>
          </Table.Row>
        );
      });

    return (
        <div>
          <Header as="h2"> Please find all receipts that are in draft status below </Header>
        <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Customer</Table.HeaderCell>
            <Table.HeaderCell>Test</Table.HeaderCell>
            <Table.HeaderCell>Receiving Technician</Table.HeaderCell>
            <Table.HeaderCell>Receive Date</Table.HeaderCell>
            <Table.HeaderCell>Sample Count</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
      {databody}
     </Table.Body>
      </Table>

        </div>
    );
  }
}
