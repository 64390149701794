import React, { Component } from "react";
import { Header, Table, Button, Modal, Input, Message, Form, Dropdown} from "semantic-ui-react";
import _ from "underscore";
import "./AdminExtUser.css";
import Errorpop from "../error/Errorpop";
import moment from "moment";

export default class AdminExtUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      chgPswdOpen: false,
      chgPswdUser: null,
      addUserOpen: null,
      newName: "",
      newPswd: "",
      maxkey:null,
      hidemsg: true,
      errmsg: '',
      newFirstName:'',
      newLastName:'',
      custOptions: [],
      selCust:'',
      sort_col: null,
      sort_dir: null,
    };
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleDelUser = this.handleDelUser.bind(this);
    this.handleChgPswd = this.handleChgPswd.bind(this);
    this.submitNewUser = this.submitNewUser.bind(this);
    this.nameChange = this.nameChange.bind(this);
    this.pswdChange = this.pswdChange.bind(this);
    this.fnChange = this.fnChange.bind(this);
    this.lnChange = this.lnChange.bind(this);
    this.errorpop = React.createRef();
    this.submitAdd=this.submitAdd.bind(this);
    this.submitDel=this.submitDel.bind(this);
    this.submitUpd=this.submitUpd.bind(this);
    this.submitChgPswd=this.submitChgPswd.bind(this);
    this.randomString=this.randomString.bind(this);
  }

  handleSort = clickedColumn => () => {
    const { sort_col, userData, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        userData: _.sortBy(userData, function (i) { return i[clickedColumn].toLowerCase(); }),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      userData: userData.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };

  randomString(length, chars) {
    var result = '';
    for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
    return result;
}


  openChgPswd = () => {
    this.setState({ chgPswdOpen: true });
  };

  closeChgPswd = () => {
    this.setState({ chgPswdOpen: false });
  };

  openAddUser = () => {
    this.setState({ addUserOpen: true });
  };

  closeAddUser = () => {
    this.setState({ addUserOpen: false });
    this.setState({ hidemsg: true });
  };

  handleAddUser = () => {
    this.openAddUser();
  };


  saveOption = (type, data) => {
    //console.log(data);
  if (type === "customer") this.setState({ selCust: data.value });
  };



  nameChange(event) {
    this.setState({ newName: event.target.value });
  }

 fnChange(event) {
    this.setState({ newFirstName: event.target.value });
  }

  lnChange(event) {
    this.setState({ newLastName: event.target.value });
  }

  pswdChange(event) {
    this.setState({ newPswd: event.target.value });
  }

  handleDelUser = username => {

    this.delExtUser(username).then((result)=>this.submitDel(result,username))

  };

  delExtUser = async (name) => {
    const response = await fetch("/api/delExtUser?text="+name, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  submitDel= (result,username) => {
    if (result.status==='ERROR') 
    {
      console.log('error');
              this.errorpop.current.handleOpen();
      }
      else {
    var userList = this.state.userData;
    userList = _.reject(userList, function(data) {
      return data.email === username;
    });
    this.setState({ userData: userList });
  }
  }

  handleChgPswd = username => {
    this.openChgPswd();
    this.setState({ chgPswdUser: username });
  };


  submitChgPswd(){
    
    this.updUsers(this.state.chgPswdUser, this.state.newPswd)
    .then((result)=>this.submitUpd(result))
  }

  submitUpd= (result) => {
    if (result.status==='ERROR') 
    {
      console.log('error');
              this.errorpop.current.handleOpen();
      }
      else {
        this.closeChgPswd();
      }
  }

  
  updUsers = async (text,pswd) => {
    const response = await fetch("/api/updUsers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
          'text': text,
        'password': pswd
      })
    });
    const body = await response.json();
//    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  submitNewUser() {
    if (this.state.selCust==='')
    { this.setState({hidemsg:false});
     this.setState({errmsg:'You must select a customer'}) }
  else
  if (this.state.newName==='')
  { this.setState({hidemsg:false});
   this.setState({errmsg:'Email for user login can not be empty'}) }
  else {
    //console.log(this.state.newName + "/" + this.state.newPswd);
   this.checkExtUser(this.state.newName).then((result)=>
   { if (result[0].cnt===0) {
     var password=this.randomString(8,'0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ')
     console.log(this.state.newName);
     console.log(password);
     console.log(this.state.selCust);
      this.addExtUser(this.state.maxkey+1, this.state.selCust, this.state.newName, password,this.state.newFirstName,this.state.newLastName)
     .then((result)=>
      { this.submitAdd(result)
      }
      )
  }
  else 
   { this.setState({hidemsg:false});
   this.setState({errmsg:'User Id Already exists'}) } })
  }
  }

  checkExtUser=async(text)=>{
    const response = await fetch("/api/checkExtUsers?text="+text, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
//    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  addExtUser = async (key,custid,email,pswd,fn,ln) => {
    const response = await fetch("/api/addExtUser", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'key':key,
        'custid': custid,
        'email': email,
        'password': pswd,
        'fn': fn,
        'ln': ln
      })
    });
    const body = await response.json();
//    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  submitAdd = (result) => {
    if (result.status==='ERROR') 
    {
      console.log('error');
              this.errorpop.current.handleOpen();
      }
      else {
    var olddata = this.state.userData;
    this.getExtUserNames().then(res => {
      if (res.status==='ERROR'){
console.log('error');
        this.errorpop.current.handleOpen();
      }
      else {

      this.setState({ userData: res });
      var maxkeynow=parseInt(_.max(res,function(ele){return ele.key}).key,10);
      if (maxkeynow) this.setState({maxkey:maxkeynow})
      else this.setState({maxkey:0});
    this.setState({ newName: "", newPswd: "" });
    this.closeAddUser();
      }
    })
      }
  }


  getExtUserNames = async () => {
    const response = await fetch("/api/getExtUsers", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllCustomers = async () => {
    const response = await fetch("/api/getCustomer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  componentDidMount() {

    this.getExtUserNames().then(res => {
      if (res.status==='ERROR'){
console.log('error');
        this.errorpop.current.handleOpen();
      }
      else {

      this.setState({ userData: res });
      var maxkeynow=parseInt(_.max(res,function(ele){return ele.key}).key,10);
      if (maxkeynow) this.setState({maxkey:maxkeynow})
      else this.setState({maxkey:0});
      console.log(this.state.maxkey);
      this.getAllCustomers().then(res => {
        this.setState({ custOptions: res });
      });
      }
    });

  }

  render() {
    const { sort_col, sort_dir } = this.state;
    var userTableRows = this.state.userData.map((data, index) => {
      return (
        <Table.Row key={"user" + index}>
          <Table.Cell>{data.email}</Table.Cell>
          <Table.Cell>{data.cust_nm}</Table.Cell>
          <Table.Cell>{data.last_login?moment(data.last_login).format('MM/DD/YYYY'):''}</Table.Cell>
          <Table.Cell>
            <Button hidden={data.email==='admin'} onClick={() => this.handleDelUser(data.email)}>
              {" "}
              Remove User{" "}
            </Button>
            <Button onClick={() => this.handleChgPswd(data.email)}>
              {" "}
              Reset Password{" "}
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });

    var ChangePswdModal = (
      <Modal
        size="small"
        className="modal"
        open={this.state.chgPswdOpen}
        onClose={this.closeChgPswd}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {"Change Passwod for User " + this.state.chgPswdUser}
        </Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Header as="h3"> Please type the new password below: </Header>{" "}
          </div>
          <div>
            {" "}
            <Input
              className="passField"
              value={this.state.newPswd}
              placeholder="Password"
              onChange={this.pswdChange}
            />
          </div>
          <div>
            {" "}
            <Button primary onClick={this.submitChgPswd}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeChgPswd}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    var AddUserModal = (
      <Modal
        size="full"
        className="modal"
        open={this.state.addUserOpen}
        onClose={this.closeAddUser}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Invite New User</Modal.Header>
        <Modal.Content>
          <Form>
                <Form.Group widths="equal">
                <Form.Field
              control={Dropdown}
              label="Customer"
              placeholder="Select Customer"
              fluid
              search
              options={this.state.custOptions}
              selection
              value={this.state.selCust}
              onChange={(e, data) => this.saveOption("customer", data)}
              width={8}
            />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field 
              control={Input}
              label="User Email"
              fluid
              className="nameField"
              placeholder="User Email for Login"
              value={this.state.newName}
              onChange={this.nameChange}
            />
            </Form.Group>
            <Form.Group widths="equal">
            <Form.Field control={Input}
              label="First Name"
              className="nameField"
              placeholder="First Name"
              value={this.state.newFirstName}
              onChange={this.fnChange}
            />
               <Form.Field control={Input}
              label="Last Name"
              className="nameField"
              placeholder="Last Name"
              value={this.state.newLastName}
              onChange={this.lnChange}
            />
            </Form.Group>
          </Form>
          <Message
      error
      header='Action Forbidden'
      content={this.state.errmsg}
      hidden={this.state.hidemsg}
    />
          <div>
            {" "}
            <Button primary onClick={this.submitNewUser}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeAddUser}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    return (
      <div>
        <Header as="h1">External User Management</Header>
        <Button onClick={this.handleAddUser}> Invite New User </Button>
        {AddUserModal}
        {ChangePswdModal}
        <Table celled padded sortable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine
              sorted={sort_col === "email" ? sort_dir : null}
              onClick={this.handleSort("email")}
              > User Name </Table.HeaderCell>
              <Table.HeaderCell singleLine
              sorted={sort_col === "cust_nm" ? sort_dir : null}
              onClick={this.handleSort("cust_nm")}
              > Customer Name </Table.HeaderCell>  
              <Table.HeaderCell>Last Login</Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{userTableRows}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
      </div>
    );
  }
}
