import React, { Component } from "react";
import {
  Header,
  Table,
  Button,
  Modal,
  Icon,
  Dropdown,
  Input,
  Form,
  TextArea,
  Label,
  Popup,
  Checkbox,
  Confirm,
  Segment,
  Dimmer,
  Loader,
  Message
} from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import "./CreateInvoice.css";
import _ from "underscore";
import moment from "moment";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";
import axios from "axios";
import { runInThisContext } from "vm";
import * as Constants from "../common/constants.js";

export default class CreateInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ReportSum: [],
      modalOpen: false,
      ReportDtl: [],
      selCust: null,
      selTest: null,
      selMinRcvDate: '2001-01-01',
      selMaxRcvDate: '2199-01-01',
      selMinRptDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
      selMaxRptDate: moment().format('YYYY-MM-DD'),
      emailLoader:false,
      testOptions: [],
      custOptions: [],
      selRptID: null,
      emailSent:false,
       sort_col: null,
      sort_dir: null,
      previewURL: new Blob(),
      TimeCheck : true,
      InvoiceDate : moment(),
      DueDate : moment().add(10,'d'),
      priceDtl:[],
      SpongeFee: 0,
      ShippingFee: 0,
      OtherFee: 0,
      loadingSave: false,
      chargeModalOpen: false,
      otherChargeNm:'',
      otherChargeFee:0.0,
      invoicingError: false,
      InvoiceId: 0,
      sentType: "",
      showInvoiced: true
    };
    this.getReportSummary=this.getReportSummary.bind(this);    
    this.handleOpenPop=this.handleOpenPop.bind(this);
    this.handleClosePop=this.handleClosePop.bind(this);
    this.errorpop = React.createRef();
  }

  toggleTimeCheck = () => this.setState({ TimeCheck: !this.state.TimeCheck })


    handleSort = clickedColumn => () => {
    const { sort_col, ReportSum, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        ReportSum: _.sortBy(ReportSum, [clickedColumn]),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      ReportSum: ReportSum.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


 isValidFloat = (str) => {
  return /^\-?[0-9]+(e[0-9]+)?(\.)?([0-9]+)?$/.test(str);
}

  handleOpen = () => this.setState({ modalOpen: true });

  updSuffix =(data) => this.setState({suffix:data.value});

  handleClose = () => {
    this.setState({ modalOpen: false, ModStep: "" ,SpongeFee:0,ShippingFee:0,loadingSave:false,ReportDtl: []});
  };

  
  updInvoiceSent= async payload => {
    const response = await fetch("/api/updInvoiceSent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    if (body.STATUS === 'FAIL') this.errorpop.current.handleOpen();
    else for (var i in this.state.ReportSum)
    if (this.state.ReportSum[i].ReportID===payload.ReportID) 
    this.state.ReportSum[i].LAST_SENT_DATE=moment().format("YYYY-MM-DD")
       // console.log(body);
    return body;
  };


  updReportSent= async payload => {
    const response = await fetch("/api/updReportSent", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    if (body.STATUS === 'FAIL') this.errorpop.current.handleOpen();
    else for (var i in this.state.ReportSum)
    if (this.state.ReportSum[i].ReportID===payload.ReportID) 
    this.state.ReportSum[i].LAST_SENT_DATE=moment().format("YYYY-MM-DD")
       // console.log(body);
    return body;
  };



  sendEmail = () => {
    this.setState({emailLoader:true});
   if (this.state.emailSent && this.state.sentType==='Inv')
        this.updInvoiceSent({ReportID:this.state.emailRptID})
      .then((res)=>{        this.getReportSummary().then(res => {
        // console.log(res);
        if (res.STATUS !== "FAIL")
          this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse()});
      });
      this.setState({ modalOpen: false, ModStep: "",emailLoader:false,emailSent:false });
            })
  else
  if (this.state.emailSent && this.state.sentType==='Rpt')
  this.updReportSent({ReportID:this.state.emailRptID})
.then((res)=>{        this.getReportSummary().then(res => {
  // console.log(res);
  if (res.STATUS !== "FAIL")
    this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse()});
});
this.setState({ modalOpen: false, ModStep: "",emailLoader:false,emailSent:false });
      })
    else 
    this.dropMail().then((res)=>{
      console.log("email_sent");
      if (res.STATUS === 'ERROR') 
      { 
        // modalOpen: false, ModStep: "",
        this.setState({ emailLoader:false });
      }
      else 
      if (this.state.sentType==='Inv')
      this.updInvoiceSent({ReportID:this.state.emailRptID})
      .then((res)=>{        this.getReportSummary().then(res => {
        // console.log(res);
        if (res.STATUS !== "FAIL")
          this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse()});
      });
      this.setState({ modalOpen: false, ModStep: "",emailLoader:false });
            })
      else 
      this.updReportSent({ReportID:this.state.emailRptID})
      .then((res)=>{        this.getReportSummary().then(res => {
        // console.log(res);
        if (res.STATUS !== "FAIL")
          this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse()});
      });
      this.setState({ modalOpen: false, ModStep: "",emailLoader:false });
            })
    })    
  }
  
  dropMail = async() => {
    const response = await fetch("/api/sendReport", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email:this.state.mail_email,
        subject:this.state.mail_subject,
        body:this.state.mail_content,
        attachment: this.state.mail_attachment
      })
    });
    const body = await response.json();
    console.log(body)
    if (response.status !== 200) throw Error(body.message);
    if (body.STATUS === 'ERROR') 
    this.setState({emailconfirm:true,
    emailmsg:'The Email Can not be sent. Please check the address and try send again'})
    else this.setState({emailconfirm:true,
    emailmsg:'Email has been sent'})

    return body;
  };
  closeemailconfirm = () =>{
    this.setState({emailconfirm:false});
  }

  getReportSummary = async () => {
    
  var URLstring=      "/api/getReportSummary?rptID=" +
  this.state.selRptID +
  "&showInvoiced=" +
  this.state.showInvoiced +
  "&cust=" +
  this.state.selCust +
  "&minrptdt=" +
  this.state.selMinRptDate +
  "&maxrptdt=" +
  this.state.selMaxRptDate +
  "&minrcvdt=" +
  this.state.selMinRcvDate +
  "&maxrcvdt=" +
  this.state.selMaxRcvDate;
  if (this.state.selMinRcvDate===null) 
  URLstring= "/api/getReportSummary?rptID=" +
  this.state.selRptID +
  "&cust=" +
  this.state.selCust +
  "&minrptdt=" +
  moment().subtract(7,'d').format('YYYY-MM-DD') +
  "&maxrptdt=" +
  moment().format('YYYY-MM-DD') +
  "&minrcvdt=" +
  moment().subtract(7,'d').format('YYYY-MM-DD') +
  "&maxrcvdt=" +
  moment().format('YYYY-MM-DD')+"&minrptdt=null&maxrptdt=null";

  console.log(URLstring);
    const response = await fetch(
     URLstring,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    //  console.log(body);
    return body;
  };

  updReportInv = async payload => {
    const response = await fetch("/api/uptReportInvoice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // if (body.STATUS === 'ERROR') this.errorpop.current.handleOpen();
    // console.log(body);
    return body;
  };

  getReportDetail = async () => {
    const response = await fetch(
      "/api/getReportDtl?rptid=" + this.state.ModID,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

  getReport = async filename => {
    const response = await fetch("/api/getPDF?filename=" + filename, {
      method: "GET",
      headers: {
        Accept: "application/blob",
        "Content-Type": "application/json"
      }
    });
    const body = await response.blob();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };


  viewReport = data => {
    this.getReport(data.ReportID + "-R" + data.CURR_REV + ".pdf").then(res => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res, data.ReportID + "-R" + data.CURR_REV + ".pdf");
    } else  {
      const fileURL = URL.createObjectURL(res);
      var anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.setAttribute("type", "hidden");
    //   anchor.download = data.ReportID + "-R" + data.CURR_REV + ".pdf";
      anchor.href=fileURL;
      anchor.target='_blank';
    //   anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
       anchor.click();
       setTimeout(function() {
        URL.revokeObjectURL(fileURL);
       }, 100);
  }
    });
  };

  saveData = (type, data, datatype) => {
    console.log(data);
   if (type==='InvoiceDate' || type==='DueDate')  this.setState({ [type]: moment(data) });
   else   { 
    if (datatype==='decimal')  
    { if (data.value==='') 
    this.setState({[type]:''});
    else if (this.isValidFloat(data.value)) 
     { console.log(data.value); this.setState({[type]:data.value}) }

   }
    else this.setState({ [type]: data.value });
  }
    // console.log(this.state);
  };

  savePrice = (index,data) => {
    // console.log(this.state.ReportDtl);
    // console.log(data.value);
    var newDtl=this.state.ReportDtl;
    newDtl[index].price=data.value;
    newDtl[index].UnfilledPrice=false;
     this.setState({ReportDtl:newDtl});
  }

  saveNM = (index,data) => {
    // console.log(this.state.ReportDtl);
    // console.log(data.value);
    var newDtl=this.state.ReportDtl;
    newDtl[index].testNM=data.value;
    this.setState({ReportDtl:newDtl});
  }


  savecfmPrice = (index,data) => {
    // console.log(this.state.ReportDtl);
    // console.log(data.value);
    var newDtl=this.state.ReportDtl;
    newDtl[index].cfmprice=data.value;
    newDtl[index].UnfilledCfmPrice=false;
     this.setState({ReportDtl:newDtl});
  }

  sendInvoice = data => {
    this.getPrice(data.cust_id).then((res)=>{ 
      var priceres=res;
         this.getInvID().then (res =>{
      if (res.STATUS==='SUCCESS') 
      {
      this.setState({InvoiceID:parseInt(res.RESULT[0].max_inv_id)+1});
      this.setState({
        invoicingError:false,
        ModStep: "invoiceInfo",
        ModID: data.ReportID,
        ModRcvDt: data.RECEIVE_DATE,
        ModTstDt: data.TEST_DATE,
        ModRptDt: data.REPORT_DATE,
        BillToDtl: data.CNTCT_DTL,
        ShipToDtl: data.CNTCT_DTL,
        ModRptSeq: data.CURR_REV,
        modalOpen: true,
        suffix: data.ReportID.replace(data.ReportID.split('-',3).join('-')+'-',''),
        priceDtl:priceres
      });
      }   else 
      this.errorpop.current.handleOpen();
      });
      // console.log(res);
    
  })
  };

  showSamples = () => {
    this.setState({ ModStep: "samplePrice" });
    this.getReportDetail().then(res => {
      if (res.STATUS !== "FAIL") 
       {  
        res=_.sortBy(_.sortBy(res.RESULT, 'seqid'),'testid')
        this.setState({ ReportDtl: res }); }
    });
  };

  GenerateLink = data => {
    this.setState({
      linkified:
        window.location.href +
        "report/" +
        encodeURIComponent(btoa(data.ReportID + "-R" + data.CURR_REV + ".pdf")),
      ModStep: "showlink",
      modalOpen: true
    });
  };


  emailInv = (data) => {
    console.log(data)
    var mailContent="Hi, "+data.custNM+
    ", \n\n Your invoice is ready for review in the attachment." +
    "\n\n\nThanks \nFSR Laboratory";
    var reportID=data.ReportID;
    if (data.ReportID.endsWith('-')) var reportID=data.ReportID.substring(0,data.ReportID.length-1);
    console.log(reportID);
    this.setState({
      linkified:
        window.location.href +
        "report/" +
        encodeURIComponent(btoa(data.ReportID + "-R" + data.CURR_REV + ".pdf")),
        mail_attachment: data.INVOICE_ID + ".pdf",
        mail_email: data.email,
        mail_content: mailContent,
        mail_subject:"Invoice For Report ID "+ reportID,
        ModStep: "mailReport",
        modalOpen: true,
        emailRptID: data.ReportID,
        sentType:"Inv"
    })
     
  }

  emailRpt = (data) => {
    console.log(data)
    var mailContent="Hi, "+data.custNM+
    ", \n\n   Your report is ready for review in the attachment.\n\n\nThanks \nFSR Laboratory";
 var reportID=data.ReportID;
    if (data.ReportID.endsWith('-')) var reportID=data.ReportID.substring(0,data.ReportID.length-1);
    console.log(reportID);
    this.setState({
      linkified:
        window.location.href +
        "report/" +
        encodeURIComponent(btoa(data.ReportID + "-R" + data.CURR_REV + ".pdf")),
        mail_attachment: data.ReportID + "-R" + data.CURR_REV + ".pdf",
        mail_email: data.email,
        mail_content: mailContent,
        mail_subject:"Test Report For Samples Received on " + moment(data.RECEIVE_DATE).format("MM/DD/YYYY"),
        ModStep: "mailReport",
        modalOpen: true,
        emailRptID: data.ReportID,
        sentType:"Rpt"
    })
     
  }

  uptSamplePrice = async payload => {
    const response = await fetch("/api/uptSamplePrice", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

  uptCharges = async payload => {
    console.log(payload);
    const response = await fetch("/api/uptCharges", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

  delCharges = async payload => {
    console.log(payload);
    const response = await fetch("/api/delCharges", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };

  getInvID = async payload => {
    const response = await fetch("/api/getInvID", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    // console.log(body);
    return body;
  };


  saveInvoice = () => {
    this.setState({invoicingError:false})
    var payload = {}; 
    var allPricesFilled=true;
    this.state.ReportDtl.forEach((data,index)=>{
      if (!data.price && data.price!==0 )
      {allPricesFilled=false;data.UnfilledPrice=true;}
      else data.UnfilledPrice=false;
      if (!data.cfmprice && data.cfmprice!==0 && data.tst_result==='PP')
      {allPricesFilled=false;data.UnfilledCfmPrice=true;}
      else data.UnfilledCfmPrice=false;
    })
    console.log(allPricesFilled);
    console.log(this.state.ModID);
    if (!allPricesFilled) { this.setState({ReportDtl:this.state.ReportDtl, invoicingError:true});return 1; }
    this.setState({loadingSave:true});
    payload.ReportID = this.state.ModID;
        var all_Promises = [];
        all_Promises.push(this.delCharges({rptId:this.state.ModID}));
        //  console.log(this.state.ReportDtl);
        this.state.ReportDtl.map((data, index) => {
          //  console.log(data);

          if (data.seqid!=='') all_Promises.push(this.uptSamplePrice(data));
          else  { var charge=data; charge.rptId=this.state.ModID;
              all_Promises.push(this.uptCharges(charge));}
        });
        Promise.all(all_Promises).then(() => {
          var rptData = [];
          this.state.ReportDtl.map((data, index) => {
            rptData.push({
              seqid: data.seqid,
              InvoiceDate: this.state.InvoiceDate,
              DueDate : this.state.DueDate,
              BillToDtl: this.state.BillToDtl,
              ShipToDtl: this.state.ShipToDtl,
              receive_date: data.receive_date,
              seqid: data.seqid,
              testNM: data.testNM,
              tst_result: data.tst_result,
              price: data.price,
              cfmprice: data.cfmprice,
              custNM: data.custNM
            });
          });
          // console.log(rptData);

          // if (this.state.ModID.split('-',4)[3]!=='')
          // var invoiceID = "INV-"+this.state.ModID.split('-',4).join('-');
          // else 
          // var invoiceID = "INV-"+this.state.ModID.split('-',3).join('-');


          var invoiceID=this.state.InvoiceID;
          var filename = invoiceID+".pdf";

          var pdfPayload = {
           filename: filename,
            invoiceID: invoiceID,
            ShippingFee: this.state.ShippingFee,
            SpongeFee: this.state.SpongeFee,
            OtherFee: this.state.OtherFee,
           Detail: rptData
          };
          this.getPDFInvoice(pdfPayload).then(res => {
            if (res.STATUS === "SUCCESS") {
              this.setState({
                invoiceID:invoiceID,
                loadingSave:false,
                ModStep: "previewInvoice",
                linkified:
                  window.location.href +
                  "report/" +
                  encodeURIComponent(
                    btoa(filename)
                  )
              });

        }
        })
        });
  };

  confirmInvoice = () => {
    var payload = {};
    payload.ReportID = this.state.ModID;
    payload.invoiceID = this.state.invoiceID;
    this.updReportInv(payload).then(res => {
      if (res.STATUS !== "FAIL") {
        for (var i in this.state.ReportSum)
    if (this.state.ReportSum[i].ReportID===payload.ReportID) 
    {this.state.ReportSum[i].INVOICE_ID=payload.invoiceID;
    this.state.ReportSum[i].INVOICE_DT=moment().format("YYYY-MM-DD");}
        this.setState({
          ModStep: "downloadInvoice"})
      }
    })
  }

  getPDFInvoice = async payload => {
    const response = await fetch("/api/geninvpdf", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    console.log(body);
    return body;
  };

  getAllCustomers = async () => {
    const response = await fetch("/api/getCustomer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    //console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getAllTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getPrice = async (custid) => {
    const response = await fetch("/api/getPrice?custID="+custid, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    // console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  saveOption = (type, data) => {
    //console.log(data);
    if (type === "rptID") this.setState({ selRptID: data.value });
    else if (type === "customer") this.setState({ selCust: data.value });
    else if (type === "test") this.setState({ selTest: data.value });
    else if (type === "rcvDt") 
     {if (data.length>1) this.setState({ selMinRcvDate: moment(data[0]).format('YYYY-MM-DD') ,selMaxRcvDate: moment(data[1]).format('YYYY-MM-DD')  });}
    else if (type === "rptDt")     if (data.length>1) this.setState({ selMinRptDate: moment(data[0]).format('YYYY-MM-DD') ,selMaxRptDate: moment(data[1]).format('YYYY-MM-DD')  });
  };

  applyFilter = () => {
    this.getReportSummary().then(res => {
      // console.log(res);
      if (res.STATUS !== "FAIL") this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse() });
    });
  };

  removeFilter = () => {
    this.setState(
      { selRptID: "", selCust: null, selTest: null, selMinRcvDate: '1900-01-01',
      selMaxRcvDate: '2199-01-01',
      selMinRptDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
      selMaxRptDate: moment().format('YYYY-MM-DD')}, ()=>this.getReportSummary().then(res => {
          // console.log(res);
          if (res.STATUS !== "FAIL") this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse() });
        }) 
      ) 
    // );
  };

  confirmCharge = () => {
    console.log(this.state.ReportDtl);

    var newData=this.state.ReportDtl;
    newData.push({
    
    testNM: this.state.otherChargeNm,
    price: this.state.otherChargeFee
  });
  

   this.setState({chargeModalOpen:false,otherChargeNm:'',otherChargeFee:0})

  }

  toggleSent = () =>{
    this.setState({emailSent:!this.state.emailSent})
  }
  
  downloadInvoice = data => {
    this.getReport(data.INVOICE_ID+".pdf").then(res => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res, data.INVOICE_ID + ".pdf");
    } else  {
      const fileURL = URL.createObjectURL(res);
      var anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.setAttribute("type", "hidden");
      anchor.download = data.INVOICE_ID + ".pdf";
      anchor.href=fileURL;
      anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
       anchor.click();
       setTimeout(function() {
        URL.revokeObjectURL(fileURL);
       }, 100);
  }
    });
  };

  viewInvoice = data => {
    this.getReport(data.INVOICE_ID+".pdf").then(res => {
      if (window.navigator && window.navigator.msSaveOrOpenBlob) { // for IE
        window.navigator.msSaveOrOpenBlob(res, data.INVOICE_ID + ".pdf");
    } else  {
      const fileURL = URL.createObjectURL(res);
      var anchor = document.createElement('a');
      document.body.appendChild(anchor);
      anchor.setAttribute("type", "hidden");
      //   anchor.download = data.ReportID + "-R" + data.CURR_REV + ".pdf";
        anchor.href=fileURL;
        anchor.target='_blank';
      //   anchor.dataset.downloadurl = ['text/plain', anchor.download, anchor.href].join(':');
         anchor.click();
       setTimeout(function() {
        URL.revokeObjectURL(fileURL);
       }, 100);
  }
    });
  };

  componentDidMount() {
    this.setState( {selMinRcvDate: '1900-01-01',
    selMaxRcvDate: '2199-01-01',
    selMinRptDate: moment().subtract(7,'d').format('YYYY-MM-DD'),
    selMaxRptDate: moment().format('YYYY-MM-DD')});
    this.getReportSummary().then(res => {
      // console.log(res);
      if (res.STATUS !== "FAIL") this.setState({ ReportSum: _.sortBy(res.RESULT,'REPORT_DATE').reverse() });
    });
    this.getAllCustomers().then(res => {
      this.setState({ custOptions: res });
    });
    this.getAllTests().then(res => {
      var allTests=res.concat(Constants.MiscItems);
      this.setState({ testOptions: allTests });
    });
    
    this.getInvID().then (res =>{
      if (res.STATUS==='SUCCESS') 
      {
      this.setState({InvoiceID:parseInt(res.RESULT[0].max_inv_id)+1});
      }   else 
      this.errorpop.current.handleOpen();
      });
  }

  handleClosePop = (data,index) =>
  {
   var newData = this.state.ReportDtl;
   newData[index].popupOpen=false;
   this.setState({ReportDtl:newData, newSeq:''});
  }

  handleOpenPop = (data,index) =>
  {
    var newData = this.state.ReportDtl;
    for (var i in newData) newData[i].popupOpen=false;
   newData[index].popupOpen=true;
   this.setState({ReportDtl:newData});
    }

showChargeInput = (data) =>
{
  this.state.ReportSum.map((data,index)=>{
  if (data.editCharge==true && data.oldValue!==data.CHARGE_NO) {
      data.editCharge=false;
      axios.post("/api/uptReportCharge",
             {"ChargeNo":data.CHARGE_NO, "ReportID":data.ReportID}
              ).then(res => {
        }).catch((error)=>{
        this.errorpop.current.handleOpen();
      });
  } else data.editCharge=false;
  });
  data.editCharge=true;
  data.oldValue=data.CHARGE_NO;
  this.setState({ReportSum:this.state.ReportSum});
}

handleChargeEnter = (event,data) => {
  if (event.key==='Enter') {
   if (data.oldValue!==data.CHARGE_NO)  
   axios.post("/api/uptReportCharge",
          {"ChargeNo":data.CHARGE_NO, "ReportID":data.ReportID}
           ).then(res => {
     }).catch((error)=>{
     this.errorpop.current.handleOpen();
   });
   data.editCharge=false;
   this.setState({ReportSum:this.state.ReportSum});
  }
}

addChargeDropdown = (e,data) =>{
  // console.log(data.value);
  // console.log(this.state.ReportDtl)
  // console.log(this.state.testOptions);
  axios.get("/api/getPrice?custID="+this.state.ReportDtl[0].cust_id).then(res=>{
    // console.log(res.data);
    // console.log(_.where(this.state.testOptions,{value:data.value})[0].key);
  var chargeDtl={
    seqid:'',
    group_sample_id:'',
    testNM:_.where(this.state.testOptions,{value:data.value})[0].text,
    test:data.value,
    price: _.where(res.data,{test_id:_.where(this.state.testOptions,{value:data.value})[0].key.toString()}).length==0?null:_.where(res.data,{test_id:_.where(this.state.testOptions,{value:data.value})[0].key.toString()})[0].unit_price
  }
  var newDtl=this.state.ReportDtl;
  newDtl=newDtl.concat(chargeDtl);
  this.setState({ReportDtl:newDtl})
})
}

render() {
    const { sort_col, sort_dir } = this.state;

    var nmIcon = null;
    if (sort_col !== "custNM") nmIcon = <Icon name='sort' />;

    var rcvDTIcon = null;
    if (sort_col !== "RECEIVE_DATE") rcvDTIcon = <Icon name='sort' />;


    var rptDTIcon = null;
    if (sort_col !== "REPORT_DATE") rptDTIcon = <Icon name='sort' />;

  var AddChargeModal=(
         <Modal
          size="small"
          open={this.state.chargeModalOpen}
          onClose={()=>{this.setState({chargeModalOpen:false})}}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Enter Charge</Modal.Header>
          <Modal.Content>
            <Modal.Description>
            <Form>
            <Form.Field
                  control={Input}
                  label="Charge Name: "
                  value={this.state.otherChargeNm}
                  onChange={(e, data) => this.saveData("otherChargeNm", data)}
                />
                 <Form.Field
                  control={Input}
                  label="Charge Fee: "
                  value={this.state.otherChargeFee}
                  onChange={(e, data) => this.saveData("otherChargeFee", data, 'decimal')}
                />
            </Form>
            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button icon secondary onClick={()=>this.setState({chargeModalOpen:false,otherChargeNm:'',otherChargeFee:0})}>
              Cancel <Icon name="delete" />
            </Button>
            <Button icon primary onClick={()=>this.confirmCharge()}>
              Confirm <Icon name="check" />
            </Button>
          </Modal.Actions>
        </Modal>
   );
    var InfoUpdateModal = null;
    if (this.state.ModStep === "invoiceInfo")
      InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Enter Invoice Info</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>The following are the information part of report</p>
              <Form>
                <Form.Group widths="equal">
                <Form.Field
                    control={Input}
                    label="Invoice ID"
                    value={this.state.InvoiceID}
                    onChange={(e, data) => this.setState({InvoiceID:data.value})}
                  />
                  <Form.Field
                    control={Flatpickr}
                    label="Invoice Date"
                    options={{
                      defaultDate: moment(this.state.InvoiceDate).format(
                        "YYYY-MM-DD"
                      )
                    }}
                    value={moment(this.state.InvoiceDate).format("YYYY-MM-DD")}
                    onChange={(e, data) => this.saveData("InvoiceDate", data)}
                  />
                   <Form.Field
                    control={Flatpickr}
                    label="Due Date"
                    options={{
                      defaultDate: moment(this.state.InvoiceDate).format(
                        "YYYY-MM-DD"
                      )
                    }}
                    value={moment(this.state.DueDate).format("YYYY-MM-DD")}
                    onChange={(e, data) => this.saveData("DueDate", data)}
                  />
                   {/* <Form.Field
                  control={Input}
                  label="Total Sponge Charge"
                  value={this.state.SpongeFee}
                  onChange={(e, data) => this.saveData("SpongeFee", data)}
                />
                 <Form.Field
                  control={Input}
                  label="Total Shipping Charge"
                  value={this.state.ShippingFee}
                  onChange={(e, data) => this.saveData("ShippingFee", data, 'decimal')}
                />
                 <Form.Field
                  control={Input}
                  label="Other Charge"
                  value={this.state.OtherFee}
                  onChange={(e, data) => this.saveData("OtherFee", data, 'decimal')}
                /> */}
                </Form.Group>
                <Form.Group widths="equal">
                <Form.Field
                  control={TextArea}
                  label="Bill To Detail"
                  autoHeight
                  value={this.state.BillToDtl}
                  onChange={(e, data) => this.saveData("BillToDtl", data)}
                />
               <Form.Field
                  control={TextArea}
                  label="Ship To Detail"
                  autoHeight
                  value={this.state.ShipToDtl}
                  onChange={(e, data) => this.saveData("ShipToDtl", data)}
                />
                  </Form.Group>
              </Form>

            </Modal.Description>
          </Modal.Content>
          <Modal.Actions>
            <Button icon secondary onClick={this.handleClose}>
              Cancel <Icon name="delete" />
            </Button>
            <Button icon primary onClick={this.showSamples}>
              Next <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        </Modal>
      )
    else if (this.state.ModStep === "samplePrice")
     { 
      //  console.log(this.state.ReportDtl);
      var seqid=0;
      var newid=false;
           var sampleBody=this.state.ReportDtl.map((data, index) => {
        console.log(data);

        var maxrow=1;
        if (data.seqid!=='') 
        { maxrow=_.where(this.state.ReportDtl,{seqid:data.seqid}).length;
        maxrow=maxrow+_.where(this.state.ReportDtl,{seqid:data.seqid,tst_result:'PP'}).length;
           
        if (data.price===null)
        if (_.where(this.state.priceDtl,{cust_id:data.cust_id,test_id:data.test.replace("t","")}).length===0) 
        data.price=null
        else 
        data.price=_.where(this.state.priceDtl,{cust_id:data.cust_id,test_id:data.test.replace("t","")})[0].unit_price;

        if (data.cfmprice===null)
        if (_.where(this.state.priceDtl,{cust_id:data.cust_id,test_id:data.test.replace("t","")}).length===0) 
        data.cfmprice=null
        else 
        data.cfmprice=_.where(this.state.priceDtl,{cust_id:data.cust_id,test_id:data.test.replace("t","")})[0].confirm_price;
           }

        var popup= ""
        var delButton= ""
        // console.log(data);
        delButton=
      <Button className='delButton' icon circular compact size='mini' 
          onClick={()=>{
            var newArr=this.state.ReportDtl;
            newArr.splice(index,1);
            this.setState({ReportDtl:newArr});
          }}
          > <Icon name='trash' /> 
          </Button>
        
        if (data.seqid==='') newid=true 
        else if (data.seqid!==seqid) 
       {seqid=data.seqid; newid=true}
       else newid=false;
      console.log(data.cfmprice)
      if (data.seqid==='')
      
      return data.test!=='t1004'?(
       <Table.Row key={"d" + index}>
       <Table.Cell rowSpan={maxrow}>{data.seqid}</Table.Cell>
       <Table.Cell rowSpan={maxrow}>{data.group_sample_id}</Table.Cell>
       <Table.Cell>{delButton}{data.testNM}</Table.Cell>
       <Table.Cell><Input error={data.UnfilledPrice===true} size="mini" value={data.price} onChange={(event,d)=>this.savePrice(index,d)}/></Table.Cell>
       </Table.Row>
      ):(
        <Table.Row key={"d" + index}>
        <Table.Cell rowSpan={maxrow}>{data.seqid}</Table.Cell>
        <Table.Cell rowSpan={maxrow}>{data.group_sample_id}</Table.Cell>
        <Table.Cell><div>{delButton}<Input size="mini" fluid value={data.testNM} onChange={(event,d)=>this.saveNM(index,d)}/></div></Table.Cell>
        <Table.Cell><Input error={data.UnfilledPrice===true} size="mini" value={data.price} onChange={(event,d)=>this.savePrice(index,d)}/></Table.Cell>
        </Table.Row>
       );
      
      else if (newid)  
        if  ((data.tst_result==='PP'))   return (  
        <React.Fragment>
          <Table.Row key={"d" + index}>
  <Table.Cell rowSpan={maxrow}>{data.seqid}</Table.Cell>
  <Table.Cell rowSpan={maxrow}>{data.group_sample_id}</Table.Cell>
  <Table.Cell>{data.testNM}</Table.Cell>
  <Table.Cell><Input size="mini" error={data.UnfilledPrice===true} value={data.price} onChange={(event,d)=>this.savePrice(index,d)}/></Table.Cell>
  </Table.Row>
            <Table.Row key={"d" + index+".1"}>
            <Table.Cell>{data.testNM+ " Confirmation"}</Table.Cell>
            <Table.Cell><Input size="mini" error={data.UnfilledCfmPrice===true} value={data.cfmprice} onChange={(event,d)=>this.savecfmPrice(index,d)}/></Table.Cell>

            </Table.Row>
            </React.Fragment>
          );
          else return (  
            <Table.Row key={"d" + index}>
    <Table.Cell rowSpan={maxrow}>{data.seqid}</Table.Cell>
    <Table.Cell rowSpan={maxrow}>{data.group_sample_id}</Table.Cell>
    <Table.Cell>{data.testNM}</Table.Cell>
    <Table.Cell><Input error={data.UnfilledPrice===true} size="mini" value={data.price} onChange={(event,d)=>this.savePrice(index,d)}/></Table.Cell>
    </Table.Row>
          );
        else      if (data.tst_result==='PP')   
      return  (
      <React.Fragment>
      <Table.Row key={"d" + index}>
       <Table.Cell>{data.testNM}</Table.Cell>
      <Table.Cell><Input size="mini" error={data.UnfilledPrice===true}  value={data.price} onChange={(event,d)=>this.savePrice(index,d)}/></Table.Cell>
    </Table.Row>
     <Table.Row key={"d" + index+".1"}>
     <Table.Cell>{data.testNM+" Confirmation"}</Table.Cell>
     <Table.Cell><Input size="mini" error={data.UnfilledCfmPrice===true}  value={data.cfmprice} onChange={(event,d)=>this.savecfmPrice(index,d)}/></Table.Cell>

  </Table.Row>
  </React.Fragment>
  );
  else 
  {
   return ( <Table.Row key={"d" + index}>
  <Table.Cell>{data.testNM}</Table.Cell>
 <Table.Cell><Input size="mini" error={data.UnfilledPrice===true} value={data.price} onChange={(event,d)=>this.savePrice(index,d)}/></Table.Cell>
</Table.Row>)

  }
      })
      InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Update Price for Sample</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>The following are the detail sample information of report</p>
              <Message hidden={!this.state.invoicingError}
    error
    header='There was some errors with the current invoice'
    list={[
      'Not all items are properly priced',
      'You can find the issue highlighted',
    ]}
  />
            </Modal.Description>

            <Table celled size="small">
              <Table.Header>
                <Table.Row>
                <Table.HeaderCell width={2}>No.</Table.HeaderCell>
            <Table.HeaderCell width={2}>Sample ID</Table.HeaderCell>
           <Table.HeaderCell width={2}>Test</Table.HeaderCell>
            <Table.HeaderCell width={1}>Price</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {sampleBody}
              </Table.Body>
            </Table>
            <div className="spacer" />
          </Modal.Content>
          <Modal.Actions>
            <Button icon secondary onClick={this.handleClose}>
              Cancel <Icon name="delete" />
            </Button>
            <Button icon color='green' onClick={()=>{ this.setState({ModStep:'invoiceInfo'}) }}>
              <Icon name="chevron left" /> Back
            </Button>
            <Button.Group color='teal'>
            <Button> Add Charge </Button>
            <Dropdown
      onChange={(e,d)=>this.addChargeDropdown(e,d)}
      className='button icon'
      upward
      floating
      options={Constants.MiscItems}
      trigger={<React.Fragment />}
    /> </Button.Group>
            <Button icon primary onClick={this.saveInvoice} loading={this.state.loadingSave}>
              Next <Icon name="chevron right" />
            </Button>
          </Modal.Actions>
        </Modal>
      );

      }

    else if (this.state.ModStep === 'previewInvoice')
    {
      // console.log(this.state.previewURL);
    InfoUpdateModal=(
      <Modal size='fullscreen' open={this.state.modalOpen}
     onClose={this.handleClose}
     closeOnDimmerClick={false}>
     <Modal.Header>Preview Invoice</Modal.Header>
     <Modal.Content scrolling>
        <Modal.Description>
         <Header></Header>
         <p>Please preview invoice</p>
       </Modal.Description>
       <iframe title='report preview' className='pdframe' id='pdfframe' type="application/pdf"
        src={this.state.linkified}> 
      <p style={{ 'font-size' : '110%'}}><em><strong>ERROR: </strong>  
An iframe should be displayed here but your browser version does not support iframes. </em>Please update your browser to its most recent version and try again.</p>
    </iframe>

     </Modal.Content>
     <Modal.Actions>
     <Button secondary onClick={this.handleClose} >
         Cancel <Icon name='delete' />
       </Button>
       <Button icon color='green' onClick={()=>{ this.setState({ModStep:'samplePrice'}) }}>
              <Icon name="chevron left" /> Back
            </Button>
       <Button primary onClick={this.confirmInvoice}>
         Next <Icon name='chevron right' />
       </Button>
     </Modal.Actions>
   </Modal>
    )
   
    }  
    else if (this.state.ModStep === "downloadInvoice")
      InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Download Invoice</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>The invoice has been successfully generated.</p>
              <p>
                Please use the download button below to download the report.
              </p>
              <p>
                The report can also be safely shared through the following link.
                Use "Copy" Button to save to clipboard
              </p>
            </Modal.Description>
            <a href={this.state.linkified} target="_blank">{this.state.linkified}</a>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.handleClose}>
              Close <Icon name="delete" />
            </Button>
            <CopyToClipboard
              text={this.state.linkified}
              onCopy={() => this.setState({ copied: true })}
            >
              <Button onClick={this.handleClose} color="green">
                Copy <Icon name="copy" />
              </Button>
            </CopyToClipboard>
            <Button
              primary
              onClick={() =>
                this.downloadInvoice({
                  INVOICE_ID: this.state.invoiceID
                })
              }
            >
              Download <Icon name="download" />
            </Button>
          </Modal.Actions>
        </Modal>
      );
    else if (this.state.ModStep === "showlink")
      InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>Link of Report</Modal.Header>
          <Modal.Content scrolling>
            <Modal.Description>
              <Header />
              <p>
                The report can be safely shared through the following link. Use
                "Copy" Button to save to clipboard
              </p>
            </Modal.Description>

            <a href={this.state.linkified} target="_blank">{this.state.linkified}</a>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.handleClose}>
              Close <Icon name="delete" />
            </Button>
            <CopyToClipboard
              text={this.state.linkified}
              onCopy={() => this.setState({ copied: true })}
            >
              <Button primary onClick={this.handleClose}>
                Copy <Icon name="copy" />
              </Button>
            </CopyToClipboard>
          </Modal.Actions>
        </Modal>
      );
      else if (this.state.ModStep === "mailReport")
      {
     InfoUpdateModal = (
        <Modal
          size="fullscreen"
          open={this.state.modalOpen}
          onClose={this.handleClose}
          closeOnDimmerClick={false}
        >
          <Modal.Header>{this.state.sentType==='Inv'?'Email Invoice':'Email Report'}</Modal.Header>
          <Modal.Content scrolling>
          <Segment basic>
       <Dimmer inverted active={this.state.emailLoader}>
        <Loader inverted>Loading</Loader>
      </Dimmer>
          <Form>
          <Form.Field>
          <Checkbox toggle label='Sent offline'  onChange={this.toggleSent} checked={this.state.emailSent} />
          </Form.Field>
          <Form.Field
                   control={Input}
                   label="Send to:"
                   value={this.state.mail_email}
                   disabled={this.state.emailSent}
                   onChange={(e, data) => this.setState({mail_email:data.value})}
                 />
                     <Form.Field
                   control={Input}
                   label="Subject:"
                   value={this.state.mail_subject}
                   disabled={this.state.emailSent}
                   onChange={(e, data) => this.setState({mail_subject:data.value})}
                 />       
                <Form.Field
                  control={TextArea}
                  label="Content:"
                  autoHeight
                  value={this.state.mail_content}
                  disabled={this.state.emailSent}
                  onChange={(e, data) => this.setState({mail_content:data.value})}
                />
          </Form>
          </Segment>
          </Modal.Content>
          <Modal.Actions>
            <Button secondary onClick={this.handleClose}>
              Close <Icon name="delete" />
            </Button>
            <Button primary onClick={this.sendEmail}>
                Send <Icon name="mail" />
              </Button>
          </Modal.Actions>
        </Modal>
      );
      }
      
    var databody = this.state.ReportSum.map((data, index) => {
      console.log(data);
     var downloadButton="";
     var emailButton="";
     var invoiceButton="";
     var rptButton='';
     var invEmailButton="";
     rptButton=<Button icon  data-tooltip="View Report" onClick={() => this.viewReport(data)}>
     <Icon name="eye" />
   </Button>;
    
if (data.RPT_SENT==='Y') 
invEmailButton=<Button icon  data-tooltip="Send Report" onClick={() => this.emailRpt(data)}>
  <Icon.Group><Icon color='green' name="send" />
  <Icon color='green' size='large' corner='top right' name='check' />
  </Icon.Group>
</Button>;
else 
invEmailButton=<Button icon  data-tooltip="Send Report" onClick={() => this.emailRpt(data)}>
<Icon name="send" />
</Button>;


      if (data.INVOICE_ID) {
       downloadButton=<Button icon  data-tooltip="Download Invoice" onClick={() => this.downloadInvoice(data)}>
        <Icon name="download" />
      </Button>
             invoiceButton=<Button icon  data-tooltip="View Invoice" onClick={() => this.viewInvoice(data)}>
             <Icon name="list alternate outline" />
           </Button>
       emailButton= <Button icon data-tooltip="Send Invoice"  onClick={() => this.emailInv(data) }> 
       <Icon name="mail" />
       </Button>
       if (data.INV_SENT==='Y') 
       emailButton=<Button icon data-tooltip="Send Invoice"  onClick={() => this.emailInv(data) }> 
       <Icon.Group><Icon color='green' name="mail" />
       <Icon color='green' size='large' corner='top right' name='check' />
       </Icon.Group></Button>;
      }
      var addChargeButton=<Icon name='pencil' size='small' color='green' onClick={()=>this.showChargeInput(data)}/>
      var ChargeInput=<Input size='small' value={data.CHARGE_NO}
        onChange={(e,d)=>{data.CHARGE_NO=d.value;this.setState({ReportSum:this.state.ReportSum})}
      }
       onKeyPress={(e)=>{this.handleChargeEnter(e,data)}}
       />
      return (
        <Table.Row key={"r" + index}>
          <Table.Cell>{data.ReportID.endsWith('-')?data.ReportID.substr(0,data.ReportID.length-1):data.ReportID}</Table.Cell>
          <Table.Cell>{data.custNM}</Table.Cell>
          <Table.Cell>{moment(data.RECEIVE_DATE).format("MMM DD,YYYY")}</Table.Cell>
          <Table.Cell>
            {moment(data.REPORT_DATE).format("MMM DD,YYYY")}
          </Table.Cell>
          <Table.Cell>{data.INVOICE_ID?data.INVOICE_ID:''}</Table.Cell>
          {/* <Table.Cell>{data.editCharge?ChargeInput:[addChargeButton,data.CHARGE_NO]}</Table.Cell> */}
          <Table.Cell>
            {rptButton}
            {invEmailButton}
            {invoiceButton}
            <Button icon  data-tooltip="Create Invoice" onClick={() => this.sendInvoice(data)}>
              <Icon name="dollar" />
            </Button>
{/*downloadButton*/}
{emailButton}
           </Table.Cell>
        </Table.Row>
      );
    });

    return (
      <div>
        <Form>
          <Form.Group>
            {/* <Form.Field
              control={Input}
              label="Report ID"
              placeholder="Search by Report ID"
              fluid
              value={this.state.selRptID?this.state.selRptID:''}
              onChange={(e, data) => this.saveOption("rptID", data)}
              width={4}
            /> */}
            <Form.Field
              control={Dropdown}
              label="Customer"
              placeholder="Select Customer"
              fluid
              search
              options={this.state.custOptions}
              selection
              value={this.state.selCust}
              onChange={(e, data) => this.saveOption("customer", data)}
              width={8}
            />
            {/* <Form.Field
              control={Dropdown}
              label="Test"
              placeholder="Select Test"
              fluid
              search
              selection
              value={this.state.selTest}
              options={this.state.testOptions}
              onChange={(e, data) => this.saveOption("test", data)}
              width={4}
            /> */}
            {/* <Form.Field
              control={Flatpickr}
              label="Receive Date"
              value={[this.state.selMinRcvDate, this.state.selMaxRcvDate]}
              options={{
               mode:"range",
               defaultDate: [this.state.selMinRcvDate, this.state.selMaxRcvDate]
               }}
              onChange={(selectedDates) => this.saveOption("rcvDt", selectedDates)}
              width={4}
            /> */}
            <Form.Field
              control={Flatpickr}
              label="Report Date"
              value={[this.state.selMinRptDate, this.state.selMaxRptDate]}
              options={{
                altInput: true,
                altFormat: "M j, Y",
                dateFormat: "Y-m-d",
                mode:"range",
                defaultDate: [this.state.selMinRptDate, this.state.selMaxRptDate]
                }}
              onChange={(selectedDates, dateStr, instance) => this.saveOption("rptDt", selectedDates)}
              width={4}
            />
            <Form.Field> 
            <label> Show Uninvoiced Only </label>
            <Checkbox checked={!this.state.showInvoiced} width={2} onChange={()=>this.setState({showInvoiced:!this.state.showInvoiced})} />
            </Form.Field>
            <Button
              icon
              className="search-button"
              primary
              onClick={this.applyFilter}
            >
              <Icon name="search" />
            </Button>
            <Button
              icon
              className="search-button"
              secondary
              onClick={this.removeFilter}
            >
              <Icon name="delete" />
            </Button>
            
          </Form.Group>{" "}
        </Form>
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell width={3}>Report ID</Table.HeaderCell>
              <Table.HeaderCell width={4} sorted={sort_col === "custNM" ? sort_dir : null}
                onClick={this.handleSort("custNM")} > Customer  {nmIcon}</Table.HeaderCell>
                <Table.HeaderCell width={1} sorted={sort_col === "RECEIVE_DATE" ? sort_dir : null}
                onClick={this.handleSort("RECEIVE_DATE")} >Receive Date  {rcvDTIcon}</Table.HeaderCell>
                <Table.HeaderCell width={1} sorted={sort_col === "REPORT_DATE" ? sort_dir : null}
                onClick={this.handleSort("REPORT_DATE")} >Report Date  {rptDTIcon}</Table.HeaderCell>
              <Table.HeaderCell width={2}>Invoice#</Table.HeaderCell>
              {/* <Table.HeaderCell>Charge No.</Table.HeaderCell> */}
              <Table.HeaderCell width={5}>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{databody}</Table.Body>
        </Table>
        {InfoUpdateModal}
        {AddChargeModal}
        <Errorpop ref={this.errorpop} />
        <Confirm open={this.state.emailconfirm} content={this.state.emailmsg} 
        onCancel={this.closeemailconfirm} onConfirm={this.closeemailconfirm} />
        </div>
    );
  }
}
