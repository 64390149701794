export const MiscItems=[{
    "key":1000,
    "value":"t1000",
    "text":"Pick Up Fee"
},
{
    "key":1001,
    "value":"t1001",
    "text":"Disposal Fee"
},
{
    "key":1002,
    "value":"t1002",
    "text":"Sponge Fee"
},
{
    "key":1003,
    "value":"t1003",
    "text":"Credit"
},
{
    "key":1004,
    "value":"t1004",
    "text":"Other Fee"
}]
