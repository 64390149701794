import React, { Component } from "react";
import {
  Button,Icon, Form, Select, Input, Message, Divider, Confirm, Checkbox
} from "semantic-ui-react";


import "./QCTest.css";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";
import { exists } from "fs";

const dupErrorHeader='Duplicates are found in the inventory list.';
const dupErroBody='Please remove the duplicates before submission.';
const dateErrorHeader='A QC Test for this date has already been entered';
const dateErroBody='Please delete the current test result before submission.';
const nopersonErrorHeader='Receiver Name is not entered';
const nopersonErroBody='Please enter the receiver name before submission.';
const quantErrorHeader='Quantity value is invalid';
const quantErroBody='Please correct the quantity entry before submission.';
const rangeErrorHeader='The higher ranger can\'t be smaller than lower range';
const rangeErroBody='Please correct the quantity entry before submission.';

const analyte=[{key:1, value:1, text:'Total Plate Count'},
{key:2, value:2, text:'Total Coliform'},
{key:3, value:3, text:'E.coli'},
{key:4, value:4, text:'Staphylococcus'},
{key:5, value:5, text:'Yeast'},
{key:6, value:6, text:'Enterobacteriaceae'},
{key:7, value:7, text:'Mold'},
{key:8, value:8, text:'Lactic Acid Bacteria'},
{key:9, value:9, text:'Bacillus cereus'}]


export default class QCTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTrans: [{qc_key:1, lower:0, higher:0}],
      lot_no: 0,
      receiveperson:'',
      error: false,  
      errorHeader: '',
      errorBody:'',
      receiveDate: moment().format("YYYY-MM-DD"),
      lotList:[],
      openConfirm:false
    };
    this.errorpop = React.createRef();
  }

  isValidFloat = (str) => {
    return /^[+-]?((\.\d+)|(\d+(\.\d+)?))$/.test(str);
  }

  componentDidMount() {
   axios.get('/api/getRecentQCLot').then((res)=>{
    var lotList=[];
    res.data.map((data,index)=>{
     lotList.push({key:index, value:data.lot_no, text:data.lot_no})
    })
    var lot_no=lotList[0].value;
    this.setState({lot_no:lot_no});
    this.setState({lotList:lotList});
    if (lotList.length>0) {
      axios.post('/api/getQCLot',{lot_no:lot_no}).then((res)=>{ 
      var transList=[];  
    res.data.map((data,index)=>{
        transList.push({qc_key:data.analyte_key, lower:data.lower_limit, higher:data.upper_limit,value:-1,comment:''});      
      })
          this.setState( { currentTrans: transList});
    })
    .catch((error)=>{
      this.errorpop.current.handleOpen();
      })
    }
   })
   .catch((error)=>{
    this.errorpop.current.handleOpen();
})

}

changeLot = (lot_no) =>{
  this.setState({lot_no:lot_no});
  axios.post('/api/getQCLot',{lot_no:lot_no}).then((res)=>{ 
    var transList=[];  
  res.data.map((data,index)=>{
      transList.push({qc_key:data.analyte_key, lower:data.lower_limit, higher:data.upper_limit,value:-1,comment:''});      
    })
        this.setState( { currentTrans: transList});
  })
  .catch((error)=>{
    this.errorpop.current.handleOpen();
    })
}

  
  updateVal = (data, d, type) => {
    var Trans=this.state.currentTrans;
    if (type==='value') 
    { if (d.value==='-') data.value='-';
    else if (this.isValidFloat(d.value)||d.value==='') 
       if (this.isValidFloat(d.value) && d.value!=='-') data.value=parseFloat(d.value);
       else if (d.value==='-') data.value='-';
       else data.value='' ;
      }

    else data.comment=d.value;
    this.setState({currentTrans:Trans});
  }

  updateSupply = (data, d) => {
    var Trans=this.state.currentTrans;
    data.qc_key=d.value;
    this.setState({currentTrans:Trans});
  }

  saveData = () => {
    var dup=false;
    var quanterror=false;
    var rangeerror=false;
    var dateerror=false;
    var currTime=moment();
    var receiveTime=moment(this.state.receiveDate)
    console.log(receiveTime);
    receiveTime.set({'hour' : currTime.hour(),'minute':currTime.minute(),'second':currTime.second()});
  console.log(receiveTime);
  axios.post("/api/checkQCTestDate",{test_date:receiveTime.format('YYYY-MM-DD')}).then(res=>{
  if (res.data.length>0 && res.data[0].cnt>0)  dateerror=true;
  this.state.currentTrans.map((data,index)=>{
    if (_.where(this.state.currentTrans,{qc_key:data.qc_key}).length>1) dup=true;
    if (!this.isValidFloat(data.lower) || !this.isValidFloat(data.higher) || !this.isValidFloat(data.value)) quanterror=true;
     else if (parseFloat(data.lower)>parseFloat(data.higher)) rangeerror=true;
    })
    if (dup) this.setState({error:true,errorHeader:dupErrorHeader,errorBody:dupErroBody}) 
     else if (quanterror) this.setState({error:true,errorHeader:quantErrorHeader,errorBody:quantErroBody}) 
     else if (rangeerror) this.setState({error:true,errorHeader:rangeErrorHeader,errorBody:rangeErroBody}) 
     else if (dateerror) this.setState({error:true,errorHeader:dateErrorHeader,errorBody:dateErroBody}) 
     else if (this.state.receiveperson==='') 
     this.setState({error:true,errorHeader:nopersonErrorHeader,errorBody:nopersonErroBody}) 
      else
    { 

    var saveBody={
      lot_no: this.state.lot_no,
      tester: this.state.receiveperson,
      testdate: receiveTime.format('YYYY-MM-DD hh:mm:ss'),
      trans: this.state.currentTrans
    }
    console.log(saveBody);

    this.setState({error:false});
    axios.post("/api/addQCTest",saveBody).then(res=>{
      axios.post('/api/getQCLot',{lot_no:_.where(this.state.lotList,{key:0})[0].text}).then((res)=>{ 
        var transList=[];  
      res.data.map((data,index)=>{
          transList.push({qc_key:data.analyte_key, lower:data.lower_limit, higher:data.upper_limit,value:-1,comment:''});      
        })
            this.setState( { openConfirm:true,
              currentTrans: transList, 
              lot_no: this.state.lotList[0].value,
              receiveperson:'',
              error: false,  
              errorHeader: '',
              errorBody:'',
              receiveDate: moment().format("YYYY-MM-DD")});
      })
      .catch((error)=>{
        this.errorpop.current.handleOpen();
        })
    }).catch((error)=>{
      this.errorpop.current.handleOpen();
  })
}
}).catch((error)=>{
  this.errorpop.current.handleOpen();
})
}

render() {
  var topForm=<Form.Group>
         <Form.Field control={Select} label='Choose Lot Number' options={this.state.lotList}
          value={this.state.lot_no}
          onChange={(e,d)=>this.changeLot(d.value)}/>
         <Form.Field control={Input} label='Enter the tester name' value={this.state.receiveperson}
            onChange={(e,d)=>this.setState({receiveperson:d.value})}/>
              <Form.Field
              control={Flatpickr}
              label="Test Date"
              options={{ defaultDate: moment().format("YYYY-MM-DD") }}
              width={3}
              value={this.state.receiveDate}
              onChange={(e, data) => this.setState({ receiveDate: data })}
            />
       </Form.Group>
    var formList=[];
        this.state.currentTrans.map((data,index)=>{
     formList.push(     <Form.Group widths={16}>
         <Form.Field width={4} className='uneditable' control={Select} label={index===0?'QC Analyte':false} options={analyte} value={data.qc_key}/>
         <Form.Field width={2} className='uneditable' control={Input} label={index===0?'Lower limit':false} value={data.lower}/>
         <Form.Field width={2} className='uneditable' control={Input} label={index===0?'Higher limit':false} value={data.higher}/>
         <Form.Field width={2} control={Input} label={index===0?'Test Value':false} value={data.value}
            onChange={(e,d)=>this.updateVal(data,d,'value')}/>
         <Form.Field width={1} className='uneditable' control={Icon} label={index===0?'Pass':false} 
         size='large'
         color={(data.value<0)?'black':(data.value>=data.lower && data.value<=data.higher)?'green':'red'}
         name={(data.value<0)?'dont':(data.value>=data.lower && data.value<=data.higher)?'check':'close'}/>
         {/* <Form.Field width={1} label={index===0?'N/A':false}><Checkbox/></Form.Field> */}
         <Form.Field width={4} control={Input} label={index===0?'Comment':false} value={data.comment}
            onChange={(e,d)=>this.updateVal(data,d,'comment')}/>
       </Form.Group>)
         })

      var message=   <Divider hidden />;
      if (this.state.error) 
        message=  <Message
        negative
        header={this.state.errorHeader}
        content={this.state.errorBody}
      />


    return (
      <div>
      <Form className='receiveForm'>
       {topForm}
       {formList}
       {message}
  <Button icon color='red' onClick={()=>this.setState({
      currentTrans: [{qc_key:1, lower:0, higher:0}],
      lotno: 0,
      receiveperson:'',
      error: false,  
      Action : 'R'
    })}>
      Reset <Icon name="undo" />
    </Button>
    <Button icon color="green" onClick={()=>this.saveData()}>
      Save <Icon name="save" />
    </Button>
    </Form>
        <Errorpop ref={this.errorpop} />
        <Confirm open={this.state.openConfirm}
        content='QC Test data has been saved successfully'
        cancelButton={false}
        onConfirm={()=>this.setState({openConfirm:false})}
        />
        </div>
    );
  }
}
