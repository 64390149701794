import React, { Component } from "react";
import {
  Table,
  Button,
  Icon,
  Dropdown,
  Form,
  Header
} from "semantic-ui-react";

import "./InvRpt.css";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import Errorpop from "../error/Errorpop";
import {Bar} from "react-chartjs-2";

export default class InvRpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trendData : [],
      invData : [],
      selInv:'1'
      };
    this.errorpop = React.createRef();
  }



 isValidFloat = (str) => {
  return /^\-?[0-9]+(e[0-9]+)?(\.)?([0-9]+)?$/.test(str);
}

  
  applyFilter = () => {
    axios.post("/api/getInvHist",{inv_key:this.state.selInv}).then(res => {
      this.setState({ trendData: res.data });
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  };

  removeFilter = () => {
    this.setState( {
      selTest:'t0'});
    axios.post("/api/getInvHist",{inv_key:this.state.selInv}).then(res => {
      this.setState({ trendData: res.data });
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
    // );
  };



  componentDidMount() {

    axios.get("/api/getDistinctMaterial").then(res => {
      this.setState({ invData: res.data });
      }).catch((error)=>{
        console.log(error);
    });
    axios.post("/api/getInvHist",{inv_key:this.state.selInv}).then(res => {
      this.setState({ trendData: res.data });
      })
  }

  
render() {
    var invLabel='';
    console.log(this.state.selInv);
    if (this.state.selInv!==null && this.state.invData.length>0) 
    {console.log(this.state.invData);
      console.log(this.state.selInv);
    invLabel=_.where(this.state.invData,{value:parseInt(this.state.selInv)})[0].text;
    }

    const { sort_col, sort_dir } = this.state;

    var nmIcon = null;
    if (sort_col !== "supplyNM") nmIcon = <Icon name='sort' />;


    var endamtIcon = null;
    if (sort_col !== "end_inv_amt") endamtIcon = <Icon name='sort' />;

    var current_year= moment().year();
    var databody = [];
    for (var i=0;i<3;i++) 
      {
        var monthdata= [];
        for (var j=1;j<=12;j++) 
         { var cntdata=_.where(this.state.trendData,
          {trans_year:current_year-i, trans_month:j,operation:'U'});
          if (cntdata.length>0) 
        monthdata.push(<Table.Cell> {-cntdata[0].amt} </Table.Cell>) 
        else 
        monthdata.push(<Table.Cell> </Table.Cell>) ;
         }
        var rowdata=<Table.Row key={"r" + i}><Table.Cell>{current_year-i}</Table.Cell>{monthdata}</Table.Row>
        databody.push(rowdata);
      }
      var labels=[];
      var dataarrays=[];
      this.state.trendData.map((data,index)=>{
    if (data.operation=='U') {
labels.push(moment(data.trans_month.toString(),'MM').format('MMM')+' '+
data.trans_year.toString());
dataarrays.push(-data.amt); }
      })

    return (
      <div>
      
      <Form>
          <Form.Group>
            <Form.Field
              control={Dropdown}
              label="Inventory"
              placeholder="Select Inventory"
              fluid
              search
              options={this.state.invData}
              selection
              value={parseInt(this.state.selInv)}
              onChange={(e, data) =>  this.setState({selInv:data.value.toString()},this.applyFilter)}
              width={8}
            />
            {/* <Button
              icon
              className="search-button"
              primary
              onClick={this.applyFilter}
            >
              <Icon name="search" />
            </Button> */}
            {/* <Button
              icon
              className="search-button"
              secondary
              onClick={this.removeFilter}
            >
              <Icon name="delete" />
            </Button> */}
            
          </Form.Group>{" "}
        </Form>
    <Header>{invLabel}</Header>
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell >Year</Table.HeaderCell>
            <Table.HeaderCell >Jan</Table.HeaderCell>
            <Table.HeaderCell >Feb</Table.HeaderCell>
            <Table.HeaderCell >Mar</Table.HeaderCell>
            <Table.HeaderCell >Apr</Table.HeaderCell>
            <Table.HeaderCell >May</Table.HeaderCell>
            <Table.HeaderCell >Jun</Table.HeaderCell>
            <Table.HeaderCell >Jul</Table.HeaderCell>
            <Table.HeaderCell >Aug</Table.HeaderCell>
            <Table.HeaderCell >Sep</Table.HeaderCell>
            <Table.HeaderCell >Oct</Table.HeaderCell>
            <Table.HeaderCell >Nov</Table.HeaderCell>    
            <Table.HeaderCell >Dec</Table.HeaderCell>          
            </Table.Row>
          </Table.Header>
          <Table.Body>{databody}</Table.Body>
        </Table>
        <Bar
        data={{labels:labels,
          datasets:[{label:null, 
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            data:dataarrays}]}}
       options= {{
              legend: {
                  display: false
              },
                scales: {
                    yAxes: [{
                      ticks: { beginAtZero: true }
                     }]}
                  }
                  }/>

        <Errorpop ref={this.errorpop} />
        </div>
    );
  }
}
