import React, { Component } from "react";
import {
  Modal, Confirm, Button,Icon, Form, Select, Input, Message, Divider
} from "semantic-ui-react";


import "./QCRecv.css";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import "flatpickr/dist/themes/material_green.css";
import Flatpickr from "react-flatpickr";
import Errorpop from "../error/Errorpop";
import { exists } from "fs";

const dupErrorHeader='Duplicates are found in the inventory list.';
const dupErroBody='Please remove the duplicates before submission.';
const lotErrorHeader='The same lot has been entered already';
const lotErrorBody='Please delete the existing lot before re-enter.';
const nopersonErrorHeader='Receiver Name is not entered';
const nopersonErroBody='Please enter the receiver name before submission.';
const quantErrorHeader='Quantity value is invalid';
const quantErroBody='Please correct the quantity entry before submission.';
const rangeErrorHeader='The higher ranger can\'t be smaller than lower range';
const rangeErroBody='Please correct the quantity entry before submission.';

const analyte=[{key:1, value:1, text:'Total Plate Count'},
{key:2, value:2, text:'Total Coliform'},
{key:3, value:3, text:'E.coli'},
{key:4, value:4, text:'Staphylococcus'},
{key:5, value:5, text:'Yeast'},
{key:6, value:6, text:'Enterobacteriaceae'},
{key:7, value:7, text:'Mold'},
{key:8, value:8, text:'Lactic Acid Bacteria'},
{key:9, value:9, text:'Bacillus cereus'}]


export default class QCRecv extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTrans: [{qc_key:1, lower:0, higher:0}],
      lotno: 0,
      receiveperson:'',
      error: false,  
      errorHeader: '',
      errorBody:'',
      receiveDate: moment().format("YYYY-MM-DD"),
      openConfirm:false
    };
    this.errorpop = React.createRef();
  }

  isValidFloat = (str) => {
    return /^\-?[0-9]+(e[0-9]+)?(\.)?([0-9]+)?$/.test(str);
  }

  componentDidMount() {
    var transList=[];  
    analyte.map((data,index)=>{
        transList.push({qc_key:data.key, lower:0, higher:0});      
      })
          this.setState( { currentTrans: transList});
     }

  
  updateVal = (data, d, type) => {
    var Trans=this.state.currentTrans;
    if (type==='higher') data.higher=d.value;
    else data.lower=d.value;
    this.setState({currentTrans:Trans});
  }

  updateSupply = (data, d) => {
    var Trans=this.state.currentTrans;
    data.qc_key=d.value;
    this.setState({currentTrans:Trans});
  }

  saveData = () => {
    var dup=false;
    var quanterror=false;
    var rangeerror=false;
    var loterror=false;
    var currTime=moment();
    var receiveTime=moment(this.state.receiveDate)
    console.log(receiveTime);
    receiveTime.set({'hour' : currTime.hour(),'minute':currTime.minute(),'second':currTime.second()});
axios.post('/api/checkQCLot',{lot_no:this.state.lotno}).then((res)=>{
   if (res.data.length>0 && res.data[0].cnt>0)  loterror=true;
  this.state.currentTrans.map((data,index)=>{
    if (_.where(this.state.currentTrans,{qc_key:data.qc_key}).length>1) dup=true;
    if (!this.isValidFloat(data.lower) || !this.isValidFloat(data.higher)) quanterror=true;
     else if (parseFloat(data.lower)>parseFloat(data.higher)) rangeerror=true;
    })
    if (dup) this.setState({error:true,errorHeader:dupErrorHeader,errorBody:dupErroBody}) 
     else if (quanterror) this.setState({error:true,errorHeader:quantErrorHeader,errorBody:quantErroBody}) 
     else if (loterror) this.setState({error:true,errorHeader:lotErrorHeader,errorBody:lotErrorBody}) 
     else if (rangeerror) this.setState({error:true,errorHeader:rangeErrorHeader,errorBody:rangeErroBody}) 
     else if (this.state.receiveperson==='') 
     this.setState({error:true,errorHeader:nopersonErrorHeader,errorBody:nopersonErroBody}) 
      else
    { 

    var saveBody={
      tranType : 'R',
      lot: this.state.lotno,
      receiver: this.state.receiveperson,
      receivedate: receiveTime.format('YYYY-MM-DD hh:mm:ss'),
      trans: this.state.currentTrans
    }
    console.log(saveBody);

    this.setState({error:false});
    axios.post("/api/QCTrans",saveBody).then(res=>{
      var transList=[];  
      analyte.map((data,index)=>{
          transList.push({qc_key:data.key, lower:0, higher:0});      
        })
            this.setState( { currentTrans: transList});
      this.setState({
        openConfirm: true,
        currentTrans: transList,
        lotno: 0,
        receiveperson:'',
        error: false,  
        Action : 'R',
        receiveDate: moment().format("YYYY-MM-DD")
      })
    }).catch((error)=>{
      this.errorpop.current.handleOpen();
  })
}
}).catch((error)=>{
  this.errorpop.current.handleOpen();
})

}

render() {
  var topForm=<Form.Group>
         <Form.Field control={Input} label='Enter Lot Number' value={this.state.lotno}
          onChange={(e,d)=>this.setState({lotno:d.value})}/>
         <Form.Field control={Input} label='Enter the reciver name' value={this.state.receiveperson}
            onChange={(e,d)=>this.setState({receiveperson:d.value})}/>
              <Form.Field
              control={Flatpickr}
              label="Receive Date"
              options={{ defaultDate: moment().format("YYYY-MM-DD") }}
              width={3}
              value={this.state.receiveDate}
              onChange={(e, data) => this.setState({ receiveDate: data })}
            />
       </Form.Group>
    var formList=[];
        this.state.currentTrans.map((data,index)=>{
     formList.push(     <Form.Group widths='equal'>
         <Form.Field className='uneditable' inline control={Select} label='QC Analyte' options={analyte} value={data.qc_key}
          onChange={(e,d)=>this.updateSupply(data,d)}/>
         <Form.Field inline control={Input} label='Lower Acceptance limit' value={data.lower}
            onChange={(e,d)=>this.updateVal(data,d,'lower')}/>
         <Form.Field inline control={Input} label='Higher Acceptance limit' value={data.higher}
            onChange={(e,d)=>this.updateVal(data,d,'higher')}/>
       </Form.Group>)
         })

      var message=   <Divider hidden />;
      if (this.state.error) 
        message=  <Message
        negative
        header={this.state.errorHeader}
        content={this.state.errorBody}
      />


    return (
      <div>
      <Form className='receiveForm'>
       {topForm}
       {formList}
       {message}
  <Button icon color='red' onClick={()=>this.setState({
      currentTrans: [{qc_key:1, lower:0, higher:0}],
      lotno: 0,
      receiveperson:'',
      error: false,  
      Action : 'R'
    })}>
      Reset <Icon name="undo" />
    </Button>
    <Button icon color="green" onClick={()=>this.saveData()}>
      Save <Icon name="save" />
    </Button>
    </Form>
        <Errorpop ref={this.errorpop} />
        <Confirm open={this.state.openConfirm}
        content='QC Receive data has been saved successfully'
        cancelButton={false}
        onConfirm={()=>this.setState({openConfirm:false})}
        />
        </div>
    );
  }
}
