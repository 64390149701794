import React, { Component } from "react";
import logo from "./logo.svg";
import Loginform from "./login/loginform";
import Mainpage from './mainpage/Mainpage';
import Cookies from "universal-cookie";
import "./App.css";
const cookies = new Cookies();

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loginPage: null,
      sysScreen: null
    };
    this.checkAuth = this.checkAuth.bind(this);
  }

  checkAuth = async () => {
    const response = await fetch("/api/verifytoken", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "x-access-token": cookies.get("auth-token")
      }
    });
    const body = await response.json();
    console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  componentWillMount() {
    this.checkAuth().then(result => {
      if (result.passed) {
        var sysScreen=<Mainpage appContext={this}/>;
        this.setState({loginPage:null,sysScreen:sysScreen});
          // cookies.set('id', result.id, { path: '/' });
      } else {
        var loginPage = null;
        loginPage = <Loginform appContext={this} />;
        this.setState({
          loginPage: loginPage
        });
      }
    });
  }

  render() {
    return (
      <div className="App">
        <link
          rel="stylesheet"
          href="//cdnjs.cloudflare.com/ajax/libs/semantic-ui/2.3.3/semantic.min.css"
        />
        {this.state.loginPage}
        {this.state.sysScreen}
      </div>
    );
  }
}

export default App;
