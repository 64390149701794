import React, { Component } from "react";
import { Header, Table, Button, Modal, Input, Message } from "semantic-ui-react";
import _ from "underscore";
import "./AdminUser.css";
import Errorpop from "../error/Errorpop";

export default class AdminUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: [],
      chgPswdOpen: false,
      chgPswdUser: null,
      addUserOpen: null,
      newName: "",
      newPswd: "",
      maxkey:null,
      hidemsg: true,
      errmsg: '',
      newFirstName:'',
      newLastName:'',
      username:'',
      userid:''
    };
    this.handleAddUser = this.handleAddUser.bind(this);
    this.handleDelUser = this.handleDelUser.bind(this);
    this.handleChgPswd = this.handleChgPswd.bind(this);
    this.submitNewUser = this.submitNewUser.bind(this);
    this.nameChange = this.nameChange.bind(this);
    this.pswdChange = this.pswdChange.bind(this);
    this.fnChange = this.fnChange.bind(this);
    this.lnChange = this.lnChange.bind(this);
    this.errorpop = React.createRef();
    this.submitAdd=this.submitAdd.bind(this);
    this.submitDel=this.submitDel.bind(this);
    this.submitUpd=this.submitUpd.bind(this);
    this.submitChgPswd=this.submitChgPswd.bind(this);
  }

  openChgPswd = () => {
    this.setState({ chgPswdOpen: true });
  };

  closeChgPswd = () => {
    this.setState({ chgPswdOpen: false });
  };

  openAddUser = () => {
    this.setState({ addUserOpen: true });
  };

  closeAddUser = () => {
    this.setState({ addUserOpen: false });
    this.setState({ hidemsg: true });
  };

  handleAddUser = () => {
    this.openAddUser();
  };

  nameChange(event) {
    this.setState({ newName: event.target.value });
  }

 fnChange(event) {
    this.setState({ newFirstName: event.target.value });
  }

  lnChange(event) {
    this.setState({ newLastName: event.target.value });
  }

  pswdChange(event) {
    this.setState({ newPswd: event.target.value });
  }

  handleDelUser = username => {

    this.delUsers(username).then((result)=>this.submitDel(result,username))

  };

  delUsers = async (name) => {
    const response = await fetch("/api/delUsers?text="+name, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  submitDel= (result,username) => {
    if (result.status==='ERROR') 
    {
      console.log('error');
              this.errorpop.current.handleOpen();
      }
      else {
    var userList = this.state.userData;
    userList = _.reject(userList, function(data) {
      return data.text === username;
    });
    this.setState({ userData: userList });
  }
  }

  handleChgPswd = username => {
    this.openChgPswd();
    this.setState({ chgPswdUser: username });
  };


  submitChgPswd(){
    
    this.updUsers(this.state.chgPswdUser, this.state.newPswd)
    .then((result)=>this.submitUpd(result))
  }

  submitUpd= (result) => {
    if (result.status==='ERROR') 
    {
      console.log('error');
              this.errorpop.current.handleOpen();
      }
      else {
        this.closeChgPswd();
      }
  }

  
  updUsers = async (text,pswd) => {
    const response = await fetch("/api/updUsers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
          'text': text,
        'password': pswd
      })
    });
    const body = await response.json();
//    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  submitNewUser() {
    if (this.state.newName==='')
    { this.setState({hidemsg:false});
     this.setState({errmsg:'User name can not be empty'}) }
  else
  if (this.state.newPswd==='')
  { this.setState({hidemsg:false});
   this.setState({errmsg:'Password can not be empty'}) }
  else {
    //console.log(this.state.newName + "/" + this.state.newPswd);
   this.checkUser(this.state.newName).then((result)=>
   { if (result[0].cnt===0) {
     this.addUsers(this.state.maxkey+1,'u'+(this.state.maxkey+1), this.state.newName, this.state.newPswd,this.state.newFirstName,this.state.newLastName)
    .then((result)=>this.submitAdd(result))}
  else 
   { this.setState({hidemsg:false});
   this.setState({errmsg:'User Id Already exists'}) } })
  }
  }

  checkUser=async(text)=>{
    const response = await fetch("/api/checkUsers?text="+text, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
//    if (response.status !== 200) throw Error(body.message);
    return body;
  }

  addUsers = async (key,unit,text,pswd,fn,ln) => {
    const response = await fetch("/api/addUsers", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'key':key,
        'unit': unit,
        'text': text,
        'password': pswd,
        'fn': fn,
        'ln': ln
      })
    });
    const body = await response.json();
//    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  submitAdd = (result) => {
    if (result.status==='ERROR') 
    {
      console.log('error');
              this.errorpop.current.handleOpen();
      }
      else {
    var olddata = this.state.userData;
    olddata.push({ text: this.state.newName });
    this.setState({ userData: olddata, newName: "", newPswd: "" });
    this.closeAddUser();
      }
  }


  getUserNames = async () => {
    const response = await fetch("/api/getUsers", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getUserName=async()=>{
    const response=await 
    fetch('/api/getname', {
      method: 'GET',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const body=await response.json();
    console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
    }

  componentDidMount() {
   
    this.getUserName().then(res=> {this.setState({username:res.name, userid:res.id});
    this.getUserNames().then(res => {
      if (res.status==='ERROR'){
console.log('error');
        this.errorpop.current.handleOpen();
      }
      else {
      console.log(res);
      this.setState({ userData: res });
      var maxkeynow=parseInt(_.max(res,function(ele){return ele.key}).key,10);
      if (maxkeynow) this.setState({maxkey:maxkeynow})
      else this.setState({maxkey:0});
      console.log(this.state.maxkey);
      }
    });
  })

  }

  render() {

    var userTableRows = this.state.userData.map((data, index) => {
      var canManage=true;
      console.log(this.state.userid);
      console.log(data.text);
      if (this.state.userid!=='admin' && this.state.userid!=='superadmin' && this.state.userid!==data.text) canManage=false;
      return (
        <Table.Row key={"user" + index}>
          <Table.Cell>{data.text}</Table.Cell>
          <Table.Cell>
            <Button disabled={!canManage} onClick={() => this.handleDelUser(data.text)}>
              {" "}
              Remove User{" "}
            </Button>
            <Button disabled={!canManage} onClick={() => this.handleChgPswd(data.text)}>
              {" "}
              Reset Password{" "}
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });

    var ChangePswdModal = (
      <Modal
        size="small"
        className="modal"
        open={this.state.chgPswdOpen}
        onClose={this.closeChgPswd}
        closeOnDimmerClick={false}
      >
        <Modal.Header>
          {"Change Passwod for User " + this.state.chgPswdUser}
        </Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Header as="h3"> Please type the new password below: </Header>{" "}
          </div>
          <div>
            {" "}
            <Input
              className="passField"
              value={this.state.newPswd}
              placeholder="Password"
              onChange={this.pswdChange}
            />
          </div>
          <div>
            {" "}
            <Button primary onClick={this.submitChgPswd}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeChgPswd}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    var AddUserModal = (
      <Modal
        size="small"
        className="modal"
        open={this.state.addUserOpen}
        onClose={this.closeAddUser}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Create New User</Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Header as="h3"> Please Add New User </Header>{" "}
          </div>
          <div>
            {" "}
            <Input
              label="User Name"
              className="nameField"
              placeholder="User Name"
              value={this.state.newName}
              onChange={this.nameChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Password"
              className="passField"
              placeholder="Password"
              value={this.state.newPswd}
              onChange={this.pswdChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="First Name"
              className="nameField"
              placeholder="Password"
              value={this.state.newFirstName}
              onChange={this.fnChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Last Name"
              className="nameField"
              placeholder="lastname"
              value={this.state.newLastName}
              onChange={this.lnChange}
            />
          </div>
          <Message
      error
      header='Action Forbidden'
      content={this.state.errmsg}
      hidden={this.state.hidemsg}
    />
          <div>
            {" "}
            <Button primary onClick={this.submitNewUser}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeAddUser}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    return (
      <div>
        <Header as="h1">User Management</Header>
        <Button onClick={this.handleAddUser}> Add User </Button>
        {AddUserModal}
        {ChangePswdModal}
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine> User Name </Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{userTableRows}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
      </div>
    );
  }
}
