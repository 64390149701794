import React, { Component } from "react";
import {
  Table,
  Button,
  Icon,
  Dropdown,
  Form,
  Header
} from "semantic-ui-react";

import "./TestRpt.css";
import _ from "underscore";
import moment from "moment";
import axios from "axios";
// import Cookies from "universal-cookie";
// const cookies = new Cookies();
import Errorpop from "../error/Errorpop";
import {Bar} from "react-chartjs-2";

export default class TestRpt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      trendData : [],
      testData : [],
      selTest:'-1',
      custData : [],
      selCust:'0'
      };
    this.errorpop = React.createRef();
  }

  
    handleSort = clickedColumn => () => {
    const { sort_col, InvtRpt, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        InvtRpt: _.sortBy(InvtRpt, [clickedColumn]),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      InvtRpt: InvtRpt.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


 isValidFloat = (str) => {
  return /^\-?[0-9]+(e[0-9]+)?(\.)?([0-9]+)?$/.test(str);
}

  
  applyFilter = () => {
    var search_con={};
    if (this.state.selTest!=='-1') search_con.test=this.state.selTest;
    if (this.state.selCust!=='0') search_con.cust=this.state.selCust;
    axios.post("/api/getTestCount",search_con).then(res => {
      this.setState({ trendData: res.data });
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  };

  removeFilter = () => {
    this.setState( {
      selTest:'t0'});
    axios.post("/api/getTestCount",{test:null}).then(res => {
      this.setState({ trendData: res.data });
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
    // );
  };



  componentDidMount() {

    axios.get("/api/getTest").then(res => {
      var testData=res.data;
      testData.unshift({'key':'-1','value':'-1','text':'All Tests'});
      this.setState({ testData: testData });
      
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
    axios.get("/api/getCustomer").then(res => {
      var custData=res.data;
      custData.unshift({'key':'0','value':'0','text':'All Customers'});
      this.setState({ custData: custData });
      
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });

    axios.post("/api/getTestCount",{}).then(res => {
      this.setState({ trendData: res.data });
      }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  }

  
render() {
    var testLabel='';
    console.log(this.state.selTest);
    if (this.state.selTest!==null && this.state.testData.length>0) testLabel=_.where(this.state.testData,{value:this.state.selTest})[0].text;

    const { sort_col, sort_dir } = this.state;

    var nmIcon = null;
    if (sort_col !== "supplyNM") nmIcon = <Icon name='sort' />;


    var endamtIcon = null;
    if (sort_col !== "end_inv_amt") endamtIcon = <Icon name='sort' />;

    var current_year= moment().year();
    var databody = [];
    for (var i=0;i<3;i++) 
      {
        var monthdata= [];
        for (var j=1;j<=12;j++) 
         { var cntdata=_.where(this.state.trendData,
          {year_of_test:current_year-i, month_of_test:j});
          if (cntdata.length>0) 
        monthdata.push(<Table.Cell> {cntdata[0].cnt + '\n$'+cntdata[0].amt} </Table.Cell>) 
        else 
        monthdata.push(<Table.Cell> </Table.Cell>) ;
         }
        var rowdata=<Table.Row key={"r" + i}><Table.Cell>{current_year-i}</Table.Cell>{monthdata}</Table.Row>
        databody.push(rowdata);
      }
      var labels=[];
      var dataarrays=[];
      var amtarray=[];
      this.state.trendData.map((data,index)=>{
labels.push(moment(data.month_of_test.toString(),'MM').format('MMM')+' '+
data.year_of_test.toString());
dataarrays.push(data.cnt);
amtarray.push(data.amt);
      })

    return (
      <div>
      
      <Form>
          <Form.Group>
            <Form.Field
              control={Dropdown}
              label="Test"
              placeholder="Select Test"
              fluid
              search
              options={this.state.testData}
              selection
              value={this.state.selTest}
              onChange={(e, data) =>  this.setState({selTest:data.value},this.applyFilter)}
              width={8}
            />
              <Form.Field
              control={Dropdown}
              label="Customer"
              placeholder="Select Customer"
              fluid
              search
              options={this.state.custData}
              selection
              value={this.state.selCust}
              onChange={(e, data) =>  this.setState({selCust:data.value},this.applyFilter)}
              width={8}
            />
            {/* <Button
              icon
              className="search-button"
              primary
              onClick={this.applyFilter}
            >
              <Icon name="search" />
            </Button> */}
            {/* <Button
              icon
              className="search-button"
              secondary
              onClick={this.removeFilter}
            >
              <Icon name="delete" />
            </Button> */}
            
          </Form.Group>{" "}
        </Form>
    <Header>{testLabel}</Header>
        <Table sortable celled>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell >Year</Table.HeaderCell>
            <Table.HeaderCell >Jan</Table.HeaderCell>
            <Table.HeaderCell >Feb</Table.HeaderCell>
            <Table.HeaderCell >Mar</Table.HeaderCell>
            <Table.HeaderCell >Apr</Table.HeaderCell>
            <Table.HeaderCell >May</Table.HeaderCell>
            <Table.HeaderCell >Jun</Table.HeaderCell>
            <Table.HeaderCell >Jul</Table.HeaderCell>
            <Table.HeaderCell >Aug</Table.HeaderCell>
            <Table.HeaderCell >Sep</Table.HeaderCell>
            <Table.HeaderCell >Oct</Table.HeaderCell>
            <Table.HeaderCell >Nov</Table.HeaderCell>    
            <Table.HeaderCell >Dec</Table.HeaderCell>          
            </Table.Row>
          </Table.Header>
          <Table.Body>{databody}</Table.Body>
        </Table>
        <Bar
        data={{labels:labels,
          datasets:[{label:'Count', 
          yAxisID: 'A',
            backgroundColor: 'rgba(255,99,132,0.2)',
            borderColor: 'rgba(255,99,132,1)',
            borderWidth: 1,
            data:dataarrays},
            {label:'Amount', 
            yAxisID: 'B',
              backgroundColor: 'blue',
              borderColor: 'rgba(255,99,132,1)',
              borderWidth: 1,
              data:amtarray}]}}
       options= {{
              legend: {
                  display: false
              },
              scales:{yAxes: [
                {
                  id: 'A',
                  type: 'linear',
                  position: 'left',
                  scalePositionLeft: true
                },
                {
                  id: 'B',
                  type: 'linear',
                  position: 'right',
                  scalePositionLeft: false
                }
              ]}
              }}/>

        <Errorpop ref={this.errorpop} />
        </div>
    );
  }
}
