import React,{Component} from 'react';
import {render} from 'react-dom';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import './index.css';
import App from './App';
// import External from './External/External';
import Report from './Report/Report';
import registerServiceWorker from './registerServiceWorker';

class About extends Component {
    render(){
        return (<h1>About page</h1>);
    }
}



render(  
    <Router>  
        <Switch>
        <Route exact path="/" component={App}/>
        <Route path="/about" component={About}/>
        {/* <Route path="/external" component={External}/> */}
        <Route path="/report/:rptid" component={Report}/>
        <Redirect from="*" to="/"/>
        </Switch>
    </Router>, document.getElementById('root'));
registerServiceWorker();
