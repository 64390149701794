import React, { Component } from "react";
import { Header, Table, Dropdown, Form, Button, Message, Checkbox,Icon,  Input} from "semantic-ui-react";
import _ from "underscore";
import "./PriceHist.css";
import Errorpop from "../error/Errorpop";
import moment from "moment";
import * as Constants from "../common/constants.js";

export default class PriceHist extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custData: [],
      testData: [],
      priceData: [],
      custID:null,
      sort_col: null,
      sort_dir: null,
      saveButtonDisabled: true
    };
  }

  handleSort = clickedColumn => () => {
    const { sort_col, priceData, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        priceData: _.sortBy(priceData, function (i) { return i[clickedColumn].toLowerCase(); }),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      priceData: priceData.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };


  getCusts = async () => {
    const response = await fetch("/api/getCustomer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getPrices = async (custID) => {
    const response = await fetch("/api/getAllPrice?custID="+custID, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  pickCustomer = (e, data) => {
    console.log(JSON.stringify(data.value))  ;
    var custKey=data.value;
    var custName=data.text;
    this.setState({
      custID: data.value,
      saveButtonDisabled : false
    });
    this.getPrices(data.value).then(res => {
      console.log(res);
      
      var fullPriceData=[];
      res.map((data,index)=>{
        console.log(data);
        console.log(custName);
        console.log(this.state.testData);
        // console.log(_.where(this.state.priceData,{cust_id:this.state.custID,test_id:data.key.toString()}));
 
        fullPriceData.push({
            cust_nm: _.where(this.state.custData,{key:parseInt(custKey)})[0].text,
            tst_nm: _.where(this.state.testData,{key:parseInt(data.test_id)})[0].text,
          unit_price: data.unit_price,
          confirm_price: data.confirm_price,
          start_date: data.start_date?moment(data.start_date).format("YYYY-MM-DD hh:mm:ss"):'',
          end_date:data.end_date?moment(data.end_date).format("YYYY-MM-DD hh:mm:ss"):''
        })
      })


      this.setState({ priceData: fullPriceData });
    });
  };

  componentDidMount() {
    this.getCusts().then(res => {
      console.log(res);
      res=_.sortBy(res, function (i) { return i["text"].toLowerCase(); });
      this.setState({ custData: res });
    });
    this.getTests().then(res => {
        console.log(res);
        res=_.sortBy(res, function (i) { return i["text"].toLowerCase(); });
        this.setState({ testData: res.concat(Constants.MiscItems) });
      });
  }


    render() {
    const { sort_col, priceData, sort_dir } = this.state;
 console.log(priceData);
// console.log(status);

// if (this.state.custID) 


// console.log(fullPriceData);

var custTableRows=priceData.map((data,index) => {
  return (
    <Table.Row key={"c" + index}
      >
      <Table.Cell>{data.cust_nm}</Table.Cell>
      <Table.Cell>{data.tst_nm}</Table.Cell>
      <Table.Cell>{data.start_date}</Table.Cell>
      <Table.Cell>{data.end_date}</Table.Cell>
      <Table.Cell>{data.unit_price}</Table.Cell>
      <Table.Cell>{data.confirm_price}</Table.Cell>
    </Table.Row>
  );
})

var nmIcon = null;
if (sort_col !== "tst_nm") nmIcon = <Icon name='sort' />;

    return (
      <div>
        <Header as="h1">Price History</Header>
        <Form className="receiveForm">
          <Form.Group widths="equal">
            <Form.Field
              control={Dropdown}
              label="Select Customer"
              placeholder="Select Customer"
              width={4}
              search
              selection
              options={this.state.custData}
              onChange={this.pickCustomer}
              value={this.state.custID}
            />
            </Form.Group>
            </Form>

      <Table fixed celled padded sortable size='small'>
          <Table.Header>
            <Table.Row>
            <Table.HeaderCell width={4}> Customer </Table.HeaderCell>
              <Table.HeaderCell singleLine
                sorted={sort_col === "tst_nm" ? sort_dir : null}
                onClick={this.handleSort("tst_nm")} width={3}
              > Test Name {nmIcon} </Table.HeaderCell>
               <Table.HeaderCell singleLine width={4}
              > Start Date</Table.HeaderCell>
                              <Table.HeaderCell singleLine width={4}
              > End Date </Table.HeaderCell>
                <Table.HeaderCell singleLine width={1}
              > Price </Table.HeaderCell>
                              <Table.HeaderCell width={1}
              > Confirmation Price </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{custTableRows}</Table.Body>
        </Table>  
        <Errorpop ref={this.errorpop} />
        <Button icon className='save-button' primary
              onClick={this.saveData} 
              disabled={this.state.saveButtonDisabled}>
              Save
            </Button>
      </div>

    );
  }
}
