import React, { Component } from "react";
import { Header, Table, Button, Modal, Input, TextArea, Grid, Icon, Checkbox , Message} from "semantic-ui-react";
import _ from "underscore";
import "./AdminCust.css";
import Errorpop from "../error/Errorpop";

export default class AdminTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custData: [],
      addCustOpen: null,
      updCustOpen: null,
      newCustNm: "",
      newCode: "",
      newContact: "",
      newAddr: "",
      newTel:"",
      newFax:"",
      newEmail:"",
      maxkey: null,
      currkey: null,
      sort_col: null,
      sort_dir: null,
      showDeleted: false,
      hidemsg: true
    };
    this.handleAddCust = this.handleAddCust.bind(this);
    this.handleDelCust = this.handleDelCust.bind(this);
    this.submitNewCust = this.submitNewCust.bind(this);
    this.nmChange = this.nmChange.bind(this);
    this.codeChange = this.codeChange.bind(this);
    this.contactChange = this.contactChange.bind(this);
    this.addrChange = this.addrChange.bind(this);
    this.errorpop = React.createRef();
    this.openUpdCust = this.openUpdCust.bind(this);
    this.closeUpdCust = this.closeUpdCust.bind(this);
    this.handleEditCust = this.handleEditCust.bind(this);
    this.submitUpdCust = this.submitUpdCust.bind(this);
    this.submitUpd = this.submitUpd.bind(this);
    this.updCusts = this.updCusts.bind(this);
    this.emailChange = this.emailChange.bind(this);
    this.telChange = this.telChange.bind(this);
    this.faxChange = this.faxChange.bind(this);
    this.changeToggle=this.changeToggle.bind(this);
  }

  handleSort = clickedColumn => () => {
    const { sort_col, custData, sort_dir } = this.state;

    if (sort_col !== clickedColumn) {
      this.setState({
        sort_col: clickedColumn,
        custData: _.sortBy(custData, function (i) { return i[clickedColumn].toLowerCase(); }),
        sort_dir: "ascending"
      });

      return;
    }

    this.setState({
      custData: custData.reverse(),
      sort_dir: sort_dir === "ascending" ? "descending" : "ascending"
    });
  };

  changeToggle = () =>{
   console.log('click');
   console.log(this.state.showDeleted);
    this.setState({showDeleted:!this.state.showDeleted})
  }

  openAddCust = () => {
    this.setState({ addCustOpen: true });
  };

  closeAddCust = () => {
    this.setState({ addCustOpen: false });
  this.setState({hidemsg:true});
  this.setState({
   newCustNm: "", newCode: "", newContact: "",
    newAddr: "", newTel:"", newFax:"", newEmail:""
  });
  };

  handleAddCust = () => {
    this.openAddCust();
  };

  openUpdCust = (key) => {
    console.log(this.state.showDeleted);
    this.setState({
      updCustOpen: true,
      newCustNm: _.where(this.state.custData, { 'key': key })[0].text,
      newCode: _.where(this.state.custData, { 'key': key })[0].code,
      newContact: _.where(this.state.custData, { 'key': key })[0].contact,
      newAddr: _.where(this.state.custData, { 'key': key })[0].address,
      newEmail: _.where(this.state.custData, { 'key': key })[0].email,
      newTel: _.where(this.state.custData, { 'key': key })[0].tel,
      newFax: _.where(this.state.custData, { 'key': key })[0].fax,
      currkey: key
    });
  };

  closeUpdCust = () => {
    this.setState({ updCustOpen: false });
    this.setState({hidemsg:true});
    this.setState({
      newCustNm: "", newCode: "", newContact: "",
       newAddr: "", newTel:"", newFax:"", newEmail:""
     });
  };

  handleEditCust = (key) => {
    this.openUpdCust(key);
  };

  nmChange(event) {
    this.setState({ newCustNm: event.target.value });
  }

  codeChange(event) {
    this.setState({ newCode: event.target.value.toUpperCase() });
  }

  contactChange(event) {
    this.setState({ newContact: event.target.value });
  }

  addrChange(event) {
    this.setState({ newAddr: event.target.value });
  }

  emailChange(event) {
    this.setState({ newEmail: event.target.value });
  }

  telChange(event) {
    this.setState({ newTel: event.target.value });
  }

  faxChange(event) {
    this.setState({ newFax: event.target.value });
  }


  submitNewCust() {

    console.log(this.state.maxkey);
    if (this.state.newCustNm==='' || this.state.newCode==='')
    {
    this.setState({errmsg:'Customer Name or Code is empty'});
     this.setState({hidemsg:false});
     return true;
  }
  else if (this.state.newCode.length>5) 
  {
    this.setState({errmsg:'Customer Code should be no longer than 5 characters'});
     this.setState({hidemsg:false});
     return true;
  }
  this.setState({hidemsg:true});

  this.checkDupCust(this.state.newCode,this.state.maxkey + 1).then((res)=>{
     if (res[0].cnt>0)     {
      this.setState({errmsg:'Customer Code Already Exists'});
       this.setState({hidemsg:false});
       return true;
    }
    this.addCusts(this.state.maxkey + 1, this.state.maxkey + 1, this.state.newCustNm, 
      this.state.newCode, this.state.newContact, this.state.newAddr,this.state.newEmail,
      this.state.newTel, this.state.newFax
    )
      .then((result) => this.submitAdd(result))
    })
  }

  submitAdd = (result) => {
    if (result.status === 'ERROR') {
      console.log('error');
      this.errorpop.current.handleOpen();
    }
    else {
      console.log(this.state.newCustNm + "/" + this.state.newCode);
      var olddata = this.state.custData;
      olddata.push({
        key: this.state.maxkey + 1, value: this.state.maxkey + 1,
        text: this.state.newCustNm, code: this.state.newCode, contact: this.state.newContact,
        address: this.state.newAddr, email: this.state.newEmail, tel: this.state.newTel,
        fax: this.state.newFax, deleted:null
      });
      this.setState({
        custData: olddata, newCustNm: "", newCode: "", newContact: "",
        newAddr: "", maxkey: this.state.maxkey + 1,  newState:"", newTel:'', newEmail:'', newFax:''
      });
      console.log(olddata);
      this.closeAddCust();
    }
  }

  checkDupCust = async (code,key) => {
    const response = await fetch("/api/checkCustDup?code="+code+"&key="+key, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
    const body = await response.json();
    //    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  addCusts = async (key, value, text, code, cntc, addr,email,tel,fax) => {
    const response = await fetch("/api/addCust", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'key': key,
        'value': value,
        'text': text,
        'code': code,
        'cntc': cntc,
        'addr': addr,
        'email': email,
        'tel': tel,
        'fax': fax
      })
    });
    const body = await response.json();
    //    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  submitUpdCust() {
    console.log(this.state.currkey);
    if (this.state.newCustNm==='' || this.state.newCode==='')
    {
    this.setState({errmsg:'Customer Name or Code is empty'});
     this.setState({hidemsg:false});
     return true;
  }
  else if (this.state.newCode.length>5) 
  {
    this.setState({errmsg:'Customer Code should be no longer than 5 characters'});
     this.setState({hidemsg:false});
     return true;
  }

  this.setState({hidemsg:true});
  this.checkDupCust(this.state.newCode,this.state.currkey).then((res)=>{
    if (res.status === 'ERROR') {
      console.log('error');
      this.errorpop.current.handleOpen();
      return true;
    }
    if (res[0].cnt>0)     {
     this.setState({errmsg:'Customer Code Already Exists'});
      this.setState({hidemsg:false});
      return true;
   }
    this.updCusts(this.state.currkey, this.state.currkey, this.state.newCustNm, 
      this.state.newCode, this.state.newContact, this.state.newAddr,
    this.state.newEmail, this.state.newTel, this.state.newFax)
      .then((result) => this.submitUpd(result))
    })
  }

  submitUpd = (result) => {
    if (result.status === 'ERROR') {
      console.log('error');
      this.errorpop.current.handleOpen();
    }
    else {
      console.log(this.state.newCustNm + "/" + this.state.newCode);
      var olddata = this.state.custData;
      for (var i in olddata) {
        if (olddata[i].key === this.state.currkey) {
          olddata[i].text = this.state.newCustNm;
          olddata[i].code = this.state.newCode;
          olddata[i].contact = this.state.newContact;
          olddata[i].address = this.state.newAddr;
          olddata[i].tel = this.state.newTel;
          olddata[i].fax = this.state.newFax;
          olddata[i].email = this.state.newEmail;
        }
      }

      this.setState({
        custData: olddata, newCustNm: "", newCode: "", newContact: "",
        newAddr: "", newTel:"", newFax:"", newEmail:""
      });
      // console.log(olddata);
      this.closeUpdCust();
    }
  }

  updCusts = async (key, value, text, code, cntc, addr, email,tel, fax) => {
    const response = await fetch("/api/updCust", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        'key': key,
        'value': value,
        'text': text,
        'code': code,
        'cntc': cntc,
        'addr': addr,
        'email': email,
        'tel': tel,
        'fax': fax
      })
    });
    const body = await response.json();
    //    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  handleDelCust = key => {
    this.delCusts(key).then((result) => this.submitDel(result, key))
    //  console.log(this.state.testData)
  };

  submitDel = (result, key) => {
    var custList = this.state.custData;
    if (result.status === 'ERROR') {
      console.log('error');
      this.errorpop.current.handleOpen();
    }
    else {
      for (var i in custList) {
        if ( custList[i].key=== key )
        {custList[i].deleted='Y'}
      }
      this.setState({ custData: custList });
    }
  }

  getCusts = async () => {
    const response = await fetch("/api/getAllCustomer", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  delCusts = async (key) => {
    const response = await fetch("/api/delCust?key=" + key, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleUndelCust = key => {
    this.undelCusts(key).then((result) => this.submitUnDel(result, key))
    //  console.log(this.state.testData)
  };

  submitUnDel = (result, key) => {
    var custList = this.state.custData;
    if (result.status === 'ERROR') {
      console.log('error');
      this.errorpop.current.handleOpen();
    }
    else {
      for (var i in custList) {
        if ( custList[i].key=== key )
        {custList[i].deleted=null}
      }
      this.setState({ custData: custList });
    }
  }

  undelCusts = async (key) => {
    const response = await fetch("/api/undelCust?key=" + key, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  componentDidMount() {
    this.getCusts().then(res => {
      console.log(res);
      res=_.sortBy(res, function (i) { return i["text"].toLowerCase(); });
      this.setState({ custData: res });
      var maxkeynow = parseInt(_.max(res, function (ele) { return ele.key }).key);
      //  this.setState({maxkey:maxkeynow});
      if (maxkeynow) this.setState({ maxkey: maxkeynow })
      else this.setState({ maxkey: 0 });
    });
  }

  render() {
    const { sort_col, sort_dir } = this.state;
    var displayData=[];
    if (this.state.showDeleted) {displayData=this.state.custData}
    else {displayData=_.where(this.state.custData,{'deleted':null});}

    var custTableRows = displayData.map((data, index) => {
      var status=false;
      var optionButton=null;
      if (data.deleted==='Y') 
      { status=true ;
        optionButton=<Button icon onClick={() => this.handleUndelCust(data.key)}>
        <Icon name="undo alternate" />
        </Button>;
      }
      else  {status=false;
        optionButton=<Button icon onClick={() => this.handleDelCust(data.key)}>
        <Icon name="trash alternate" />
        </Button>;
      }
// console.log(data);
// console.log(status);

      return (
        <Table.Row key={"c" + index}
          error={status}>
          <Table.Cell>{data.text}</Table.Cell>
          <Table.Cell>{data.code}</Table.Cell>
          <Table.Cell>{data.contact}</Table.Cell>
          <Table.Cell>{data.address}</Table.Cell>
          <Table.Cell>{data.email.replace(new RegExp(/,/gi),'\n')}</Table.Cell>
          <Table.Cell>{data.tel}</Table.Cell>
          <Table.Cell>{data.fax}</Table.Cell>
          <Table.Cell className='buttonCell'>
            {/*    <Button onClick={() => this.handleDelCust(data.key)} className='rmvButt'>
              {" "}
              Remove Customer{" "}
      </Button> */}
            <Button icon onClick={() => this.handleEditCust(data.key)}>
            <Icon name="edit" />
            </Button>
            {optionButton}
          </Table.Cell>
        </Table.Row>
      );
    });


    var AddCustModal = (
      <Modal
        size="small"
        className="modal"
        open={this.state.addCustOpen}
        onClose={this.closeAddCust}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Add New Customer</Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Header as="h3" className="addheader"> Please Add New Customer </Header>{" "}
          </div>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Customer Name:
              </Header>
            <Input
              //              label="Customer Name"
              className="custField"
              placeholder="Name"
              value={this.state.newCustNm}
              onChange={this.nmChange}
            />
          </div>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Customer Code:
              </Header>
            <Input
              className="custField"
              placeholder="Code"
              value={this.state.newCode}
              onChange={this.codeChange}
            />
          </div>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Customer Contact:
              </Header>
            <Input
              className="custField"
              placeholder="Contact Person"
              value={this.state.newContact}
              onChange={this.contactChange}
            />
          </div>
          <div>
            {" "}
            <Header as='h4'>
              Customer Address Detail
              </Header>
            <TextArea
              rows={3}
              autoHeight
              className="txtField"
              placeholder="Contact Address Detail"
              value={this.state.newAddr}
              onChange={this.addrChange}
            />
          </div>
          <Grid columns={3}>
            <Grid.Column>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Email:
              </Header>
            <Input
              className="gridField"
              placeholder="Email Address"
              value={this.state.newEmail}
              onChange={this.emailChange}
            />
          </div>
          </Grid.Column>
          <Grid.Column>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Telephone:
              </Header>
            <Input
              className="gridField"
              placeholder="Telephone"
              value={this.state.newTel}
              onChange={this.telChange}
            />
          </div>
          </Grid.Column>
          <Grid.Column>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Fax: 
              </Header>
            <Input
              className="gridField"
              placeholder="Fax"
              value={this.state.newFax}
              onChange={this.faxChange}
            />
          </div> 
          </Grid.Column>  
          </Grid>   
          <Message
      error
      header='Action Forbidden'
      content={this.state.errmsg}
      hidden={this.state.hidemsg}
    />      
          <div>
            {" "}
            <Button primary onClick={this.submitNewCust}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeAddCust}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    var EditCustModal = (
      <Modal
        size="small"
        className="modal"
        open={this.state.updCustOpen}
        onClose={this.closUpdCust}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Edit Customer</Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Header as="h3" className="addheader"> Please Update Customer Information </Header>{" "}
          </div>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Customer Name:
              </Header>
            <Input
              //              label="Customer Name"
              className="custField"
              placeholder="Name"
              value={this.state.newCustNm}
              onChange={this.nmChange}
            />
          </div>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Customer Code:
              </Header>
            <Input
              className="custField"
              placeholder="Code"
              value={this.state.newCode}
              onChange={this.codeChange}
            />
          </div>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Customer Contact:
              </Header>
            <Input
              className="custField"
              placeholder="Contact Person"
              value={this.state.newContact}
              onChange={this.contactChange}
            />
          </div>
          <div>
            {" "}
            <Header as='h4'>
              Customer Address Detail
              </Header>
            <TextArea
              rows={3}
              autoHeight
              className="txtField"
              placeholder="Contact Address Detail"
              value={this.state.newAddr}
              onChange={this.addrChange}
            />
          </div>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Email:
              </Header>
            <Input
              className="gridField"
              placeholder="Email Address"
              value={this.state.newEmail}
              onChange={this.emailChange}
            />
          </div>
          <Grid columns={2}>
                
                  <Grid.Column>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Telephone:
              </Header>
            <Input
              className="gridField"
              placeholder="Telephone"
              value={this.state.newTel}
              onChange={this.telChange}
            />
          </div>
          </Grid.Column>
          <Grid.Column>
          <div>
            {" "}
            <Header as='h4' className='custHeader'>
              Fax: 
              </Header>
            <Input
              className="gridField"
              placeholder="Fax"
              value={this.state.newFax}
              onChange={this.faxChange}
            />
          </div> 
          </Grid.Column>  
          </Grid> 
          <Message
      error
      header='Action Forbidden'
      content={this.state.errmsg}
      hidden={this.state.hidemsg}
    />     
          <div>
            {" "}
            <Button primary onClick={this.submitUpdCust}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeUpdCust}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    return (
      <div className='CustBlock'>
        <Header as="h1">Customer Management</Header>
        <Button className='addNew' onClick={this.handleAddCust} color='green'> Add Customer</Button>
        <Checkbox className='showdeleted' toggle label='Show Deleted Customers' 
        checked={this.state.showDeleted} onChange={()=>this.changeToggle()}/>
        {AddCustModal}
        {EditCustModal}
        <Table celled padded sortable size='small'>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine
                sorted={sort_col === "text" ? sort_dir : null}
                onClick={this.handleSort("text")}
              > Customer Name </Table.HeaderCell>
              <Table.HeaderCell singleLine
                sorted={sort_col === "code" ? sort_dir : null}
                onClick={this.handleSort("code")}> Customer Code </Table.HeaderCell>
              <Table.HeaderCell singleLine
              > Customer Contact </Table.HeaderCell>
              <Table.HeaderCell singleLine> Customer Address </Table.HeaderCell>
              <Table.HeaderCell > Email </Table.HeaderCell>
              <Table.HeaderCell singleLine> Telephone </Table.HeaderCell>
              <Table.HeaderCell singleLine> Fax </Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{custTableRows}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
      </div>
    );
  }
}
