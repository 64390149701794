import React, { Component } from "react";
import {
  Image,
  Header,
  Label,
  Menu,
  Container,
  Dropdown,
  Button
} from "semantic-ui-react";
import "./Mainpage.css";
import ReceivePage from "../receivepage/ReceivePage";
import LoadDraft from "../loaddraft/LoadDraft";
// import ReviewPage from "../reviewpage/ReviewPage";
import AdminTest from "../admin/AdminTest";
import AdminUnit from "../admin/AdminUnit";
import AdminUser from "../admin/AdminUser";
import AdminExtUser from "../admin/AdminExtUser";
import AdminCust from "../admin/AdminCust";
import AdminPrice from "../admin/AdminPrice";
import AdminExternalSite from "../admin/AdminExternalSite";
import AdminMaterial from "../admin/AdminMaterial";
// import StartProcess from "../startproc/StartProcess";
// import EndProcess from "../endproc/EndProcess";
import CreateReport from "../createrpt/CreateReport";
import ReviewRpt from "../reviewrpt/ReviewRpt";
// import ConfirmPos from "../ConfirmPos/ConfirmPos";
import UpdateSample from "../UpdateSample/UpdateSample";
import CreateInvoice from "../CreateInvoice/CreateInvoice";
import LoadSubmission from "../loadSubmission/LoadSubmission";
import RecvInvt from "../inventory/RecvInvt";
import InvtLog from "../inventory/InvtLog";
import InvtRpt from "../inventory/InvtRpt";
import QCRecv from "../qc/QCRecv";
import QCLog from "../qc/QCLog";
import QCTest from "../qc/QCTest";
import QCTestLog from "../qc/QCTestLog";
import TestRpt from "../Report/TestRpt";
import InvRpt from "../Report/InvRpt";
import PriceHist from "../CreateInvoice/PriceHist";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class Mainpage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initData: [],
      page: <ReceivePage initData={[]} />,
      activeItem: "receive"
    };
    this.setInitData = this.setInitData.bind(this);
    this.getUserName = this.getUserName.bind(this);
  }

  getUserName=async()=>{
    const response=await 
    fetch('/api/getname', {
      method: 'GET',
      headers:{
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const body=await response.json();
    console.log(body);
    if (response.status !== 200) throw Error(body.message);
    return body;
    }

    logUserOut=async()=>{
      const response=await 
      fetch('/api/logoutUser', {
        method: 'GET',
        headers:{
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
      const body=await response.json();
      console.log(body);
      if (response.status !== 200) throw Error(body.message);
      return body;
      }

  setInitData(data) {
    this.setState({ page: <ReceivePage initData={data} /> });
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
    if (name === "receive")
      this.setState({ page: <ReceivePage initData={[]} /> });
    // else if (name === "startproc") this.setState({ page: <StartProcess /> });
    // else if (name === "endproc") this.setState({ page: <EndProcess /> });
    else if (name === "continue")
      this.setState({ page: <LoadDraft onDraftSelected={this.setInitData} /> });
    // else if (name === "confirm") this.setState({ page: <ConfirmPos /> });
    else if (name === "update") this.setState({ page: <UpdateSample  onDraftSelected={this.setInitData}/> });
      else if (name === "createrpt") this.setState({ page: <CreateReport /> });
      else if (name === "reviewrpt") this.setState({ page: <ReviewRpt /> });
    else if (name === "admintest") this.setState({ page: <AdminTest /> });
    else if (name === "adminunit") this.setState({ page: <AdminUnit /> });
    else if (name === "adminuser") this.setState({ page: <AdminUser /> });
    else if (name === "admincust") this.setState({ page: <AdminCust /> });
    else if (name === "adminprice") this.setState({ page: <AdminPrice /> });
    else if (name === "adminextuser") this.setState({ page: <AdminExtUser /> });
    else if (name === "adminextsite") this.setState({ page: <AdminExternalSite /> });
    else if (name === "adminmaterial") this.setState({ page: <AdminMaterial /> });
    else if (name === "creatinv") this.setState({ page: <CreateInvoice /> });
    else if (name ==="receiveinv") this.setState({page:<RecvInvt />});
    else if (name ==="invlog") this.setState({page:<InvtLog />});
    else if (name ==="invrpt") this.setState({page:<InvtRpt />});
    else if (name ==="receiveqc") this.setState({page:<QCRecv />})
    else if (name ==="receiveqclog") this.setState({page:<QCLog />})
    else if (name ==="testqc") this.setState({page:<QCTest />})
    else if (name ==="testqclog") this.setState({page:<QCTestLog />})
    else if (name ==="testrpt") this.setState({page:<TestRpt />})
    else if (name ==="invtrpt") this.setState({page:<InvRpt />})
    else if (name ==="pricehist") this.setState({page:<PriceHist />})
    else if (name === "recsubmission") this.setState({page: <LoadSubmission onDraftSelected={this.setInitData} userid={this.state.userid} />});
  };

  logout = () => {
    cookies.remove("id");
    cookies.remove("auth-token");
    this.logUserOut().then(res=>{
    window.location.reload();
    });
  };

  componentWillMount () {
    this.getUserName().then(res=>{this.setState({username:res.name, userid:res.id})})
   .catch(error=>{return error});
  }

  render() {
    const { activeItem } = this.state;
    var       usermenu = (
      <Dropdown item text="Admin">
        <Dropdown.Menu>
          <Dropdown.Item
            name="adminuser"
            active={activeItem === "adminuser"}
            onClick={this.handleItemClick}
          >
            Manage User
          </Dropdown.Item>
          <Dropdown.Item
            name="adminunit"
            active={activeItem === "adminunit"}
            onClick={this.handleItemClick}
          >
            Manage Unit
          </Dropdown.Item>
          <Dropdown.Item
            name="admintest"
            active={activeItem === "admintest"}
            onClick={this.handleItemClick}
          >
            Manage Test
          </Dropdown.Item>
          <Dropdown.Item
            name="adminmaterial"
            active={activeItem === "adminmaterial"}
            onClick={this.handleItemClick}
          >
            Manage Supplies
          </Dropdown.Item>
          <Dropdown.Item
            name="admincust"
            active={activeItem === "admincust"}
            onClick={this.handleItemClick}
          >
            Manage Customer
          </Dropdown.Item>
          <Dropdown.Item
            name="adminextuser"
            active={activeItem === "adminextuser"}
            onClick={this.handleItemClick}
          >
            Manage External Users
          </Dropdown.Item>
          <Dropdown.Item
            name="adminextsite"
            active={activeItem === "adminextsite"}
            onClick={this.handleItemClick}
          >
            Manage External Website
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
    var finMenu="";
    var analysisMenu="";
    if (this.state.userid === "admin" || this.state.userid === 'superadmin') {
       finMenu =  (<Dropdown item text="Invoice">
      <Dropdown.Menu>
        <Dropdown.Item
          as="a"
          name="creatinv"
          active={activeItem === "creatinv"}
          onClick={this.handleItemClick}
        >
          Create Invoice
        </Dropdown.Item>
        <Dropdown.Item
      name="adminprice"
      active={activeItem === "adminprice"}
      onClick={this.handleItemClick}
    >
      Manage Pricing
    </Dropdown.Item>
    <Dropdown.Item
      name="pricehist"
      active={activeItem === "pricehist"}
      onClick={this.handleItemClick}
    >
      Check Price History
    </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>);
      analysisMenu=(            <Dropdown item text="Analysis">
      <Dropdown.Menu>
        <Dropdown.Item
          as="a"
          name="testrpt"
          active={activeItem === "testrpt"}
          onClick={this.handleItemClick}
        >
          Test Trending
          </Dropdown.Item>
          <Dropdown.Item
          as="a"
          name="invtrpt"
          active={activeItem === "invtrpt"}
          onClick={this.handleItemClick}
        >
          Inventory Trending
          </Dropdown.Item>
         </Dropdown.Menu>
    </Dropdown>);
    }
    

    return (
      <div>
        <Menu fixed="top">
          <div className="corpHeader">
            <Header as="h2">
              <Image
                size="small"
                src="Corp_Icon_Header.jpg"
                style={{ marginRight: "1.5em" }}
              />
              FSR Laboratory
            </Header>
          </div>
          <Container>
            <Dropdown item text="Sample">
              <Dropdown.Menu>
                <Dropdown.Item
                  as="a"
                  name="receive"
                  active={activeItem === "receive"}
                  onClick={this.handleItemClick}
                >
                  New
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  name="recsubmission"
                  active={activeItem === "recsubmission"}
                  onClick={this.handleItemClick}
                >
                  Receive
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  name="continue"
                  active={activeItem === "continue"}
                  onClick={this.handleItemClick}
                >
                  Continue
                </Dropdown.Item>
                {/* <Dropdown.Item
                  as="a"
                  name="confirm"
                  active={activeItem === "confirm"}
                  onClick={this.handleItemClick}
                >
                  Confirm
                </Dropdown.Item> */}
                <Dropdown.Item
                  as="a"
                  name="update"
                  active={activeItem === "update"}
                  onClick={this.handleItemClick}
                >
                  Update
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>

              {/* <Dropdown item text="Test">
              <Dropdown.Menu>
                <Dropdown.Item
                  as="a"
                  name="startproc"
                  active={activeItem === "startproc"}
                  onClick={this.handleItemClick}
                >
                  Start
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  name="endproc"
                  active={activeItem === "endproc"}
                  onClick={this.handleItemClick}
                >
                  Complete
                </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown> */}

             <Dropdown item text="Report">
              <Dropdown.Menu>
                <Dropdown.Item
                  as="a"
                  name="createrpt"
                  active={activeItem === "createrpt"}
                  onClick={this.handleItemClick}
                >
                  Create
                </Dropdown.Item>
                <Dropdown.Item
                  as="a"
                  name="reviewrpt"
                  active={activeItem === "reviewrpt"}
                  onClick={this.handleItemClick}
                >
                  Review
                </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            {finMenu}
            <Dropdown item text="Invetory">
              <Dropdown.Menu>
                <Dropdown.Item
                  as="a"
                  name="receiveinv"
                  active={activeItem === "receiveinv"}
                  onClick={this.handleItemClick}
                >
                  Receive/Adjust
                  </Dropdown.Item>
                <Dropdown.Item
              name="invlog"
              active={activeItem === "invlog"}
              onClick={this.handleItemClick}
            >
              Inventory Log
            </Dropdown.Item>
            <Dropdown.Item
              name="invrpt"
              active={activeItem === "invrpt"}
              onClick={this.handleItemClick}
            >
              Inventory Report
            </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
            <Dropdown item text="QC">
              <Dropdown.Menu>
                <Dropdown.Item
                  as="a"
                  name="receiveqc"
                  active={activeItem === "receiveqc"}
                  onClick={this.handleItemClick}
                >
                  Receive
                  </Dropdown.Item>
                  <Dropdown.Item
                  as="a"
                  name="receiveqclog"
                  active={activeItem === "receiveqclog"}
                  onClick={this.handleItemClick}
                >
                  Receive Log
                  </Dropdown.Item>
                  <Dropdown.Item
                  as="a"
                  name="testqc"
                  active={activeItem === "testqc"}
                  onClick={this.handleItemClick}
                >
                 Test
                  </Dropdown.Item>
                  <Dropdown.Item
                  as="a"
                  name="testqclog"
                  active={activeItem === "testqclog"}
                  onClick={this.handleItemClick}
                >
                 Test Log
                  </Dropdown.Item>
                </Dropdown.Menu>
            </Dropdown>
{analysisMenu}
            {usermenu}
          </Container>
          <div className="uName">
            <Button as="div" labelPosition="left">
              <Label basic className="loginTag">
                Logged in as {this.state.username}
              </Label>
              <Button color="red" onClick={this.logout}>
                Logout
              </Button>
            </Button>
          </div>
        </Menu>
        <Container style={{ marginTop: "5em" }}>{this.state.page}</Container>
      </div>
    );
  }
}
