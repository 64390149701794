import React, { Component } from "react";
import { Header, Table, Button, Modal, Input,Select , Form, Icon} from "semantic-ui-react";
import _ from "underscore";
import "./AdminMaterial.css";
import Errorpop from "../error/Errorpop";
import axios from "axios";

const quantityOption = [
  {key:1,value:1,text:'1'},
  {key:2,value:2,text:'2'},
  {key:3,value:3,text:'3'},
  {key:4,value:4,text:'4'},
  {key:5,value:5,text:'5'},
  {key:6,value:6,text:'6'},
  {key:7,value:7,text:'7'},
  {key:8,value:8,text:'8'},
  {key:9,value:9,text:'9'},
  {key:10,value:10,text:'10'},
]

const isValidFloat = (str) => {
  return /^\-?[0-9]+(e[0-9]+)?(\.)?([0-9]+)?$/.test(str);
}


export default class AdminMaterial extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testData: [],
      materialData : [],
      ModalOpen:false,
      addNew:true,
      newTest: "",
      newCode: "",
      newDur: 0,
      newMethod: '',
      newCfmMethod: '',
      currkey: null,
      hidemsg: true,
      currentSupply:{},
      newPrice:0.0
    };
    this.errorpop = React.createRef();
  }


  componentDidMount() {
    axios.get("/api/getTest").then(res => {
      console.log(res.data);
      this.setState({ testData: res.data });
    // this.setState({ maxkey: maxkeynow });
   axios.get("/api/getMaterial").then(res=> {
     console.log(res.data);
     this.setState({materialData: res.data})
    }).catch ((error)=>{
    this.errorpop.current.handleOpen();
   })

    }).catch((error)=>{
      this.errorpop.current.handleOpen();
    });
  }


  handleAddSupply = () => {
   
    this.setState({ModalOpen:true,addNew:true,
      currentSupply: { key:0, name:'',unit_price:0.0, alert_limit:1000, usage: [ {test_key : 't0', quantity: 1}  ] } });
  }

  updateName = (data) =>{
    var currentSupply=this.state.currentSupply;
    currentSupply.name=data.value;
    this.setState({currentSupply:currentSupply});
  }

  updatePrice = (data) =>{
    var currentSupply=this.state.currentSupply;
    if (data.value==='' ||isValidFloat(data.value)) currentSupply.unit_price=data.value;
    this.setState({currentSupply:currentSupply});
  }

  updateLimit = (data) =>{
    var currentSupply=this.state.currentSupply;
    if (data.value==='' || isValidFloat(data.value)) currentSupply.alert_limit=data.value;
    this.setState({currentSupply:currentSupply});
  }


  addSupply = () => {
    var currentSupply=this.state.currentSupply;
    currentSupply.usage.push({test_key : 't0', quantity: 1});
    this.setState({currentSupply:currentSupply});
  }

  updateTest = (data,d) => {
    var currentSupply=this.state.currentSupply;
     data.test_key=d.value;
     this.setState({currentSupply:currentSupply});
  }

  updateVal = (data,d) => {
    var currentSupply=this.state.currentSupply;
    data.quantity=d.value;
    this.setState({currentSupply:currentSupply});
  }

  removeTest = (index) => {
    var currentSupply=this.state.currentSupply;
    currentSupply.usage.splice(index,1);
    this.setState({currentSupply:currentSupply});
  }

  handleEditSupply = (inputdata) => {
    var currentSupply={ name:'', usage: [ ] };
    // console.log(inputdata);
    this.state.materialData.map((data, index) => {
      if (data.key===inputdata.key) {
        console.log(data);
        currentSupply.key=data.key;
        currentSupply.name=data.name;
        currentSupply.unit_price=data.unit_price;
        currentSupply.alert_limit=data.alert_limit;
        currentSupply.usage.push({test_key : data.used_by_test, quantity: data.num_used});
      }
  })
  this.setState({ModalOpen:true,addNew:false,currentSupply:currentSupply});
}

submitSupply = () =>{
  console.log("submit");
  if (this.state.currentSupply.key===0)
     axios.post("/api/addMaterial",this.state.currentSupply).then(
       res=>{
         console.log(res);
         axios.get("/api/getMaterial").then(res=> {
          console.log(res.data);
          this.setState({materialData: res.data})
          this.setState({ModalOpen:false})
         }).catch ((error)=>{
         this.errorpop.current.handleOpen();
        })
       }
     )
     .catch ((error)=>{
      this.errorpop.current.handleOpen();
     })
 else 
 axios.post("/api/updateMaterial",this.state.currentSupply).then(
  res=>{
    console.log(res);
    axios.get("/api/getMaterial").then(res=> {
     console.log(res.data);
     this.setState({materialData: res.data})
      this.setState({ModalOpen:false})
    }).catch ((error)=>{
    this.errorpop.current.handleOpen();
   })
  }
)
.catch ((error)=>{
 this.errorpop.current.handleOpen();
})
}


handleDelSupply = (key) => {
  axios.post("/api/deleteMaterial",{key:key}).then(
    res=>{
      console.log(res);
      axios.get("/api/getMaterial").then(res=> {
       console.log(res.data);
       this.setState({materialData: res.data})
      }).catch ((error)=>{
      this.errorpop.current.handleOpen();
     })
    }
  )
  .catch ((error)=>{
   this.errorpop.current.handleOpen();
  })
}


  render() {

    var listTableRows = this.state.materialData.map((data, index) => {
      var firstCell=null;
      var priceCell=null;
      var limitCell=null;
      var lastCell=null;
      if (index==0 || this.state.materialData[index].key!==this.state.materialData[index-1].key)
          { firstCell= <Table.Cell rowSpan={data.cnt}>{data.name}</Table.Cell>
          priceCell= <Table.Cell rowSpan={data.cnt}>{data.unit_price}</Table.Cell>;
          limitCell= <Table.Cell rowSpan={data.cnt}>{data.alert_limit}</Table.Cell>;
          lastCell= <Table.Cell rowSpan={data.cnt}>
          <Button icon onClick={() => this.handleEditSupply(data)}>
          <Icon name="edit" />
          </Button>
          <Button icon onClick={() => this.handleDelSupply(data.key)}>
          <Icon name="trash alternate" />
          </Button>
        </Table.Cell>
          }
      //     console.log(this.state.testData);
      // console.log(_.where(this.state.testData,{key:data.used_by_test}));
      return (
        <Table.Row key={"t" + index}>
         {firstCell}
         {priceCell}
        {limitCell}
          <Table.Cell>{_.where(this.state.testData,{value:data.used_by_test})[0].text}</Table.Cell>
          <Table.Cell>{data.num_used}</Table.Cell>
          {lastCell}
        </Table.Row>
      );
    });
   
    var formList=[];

    if (this.state.currentSupply.usage) 
      this.state.currentSupply.usage.map((data,index)=>{
      if (index===0)  formList.push(     <Form.Group widths='equal'>
       <Form.Field control={Select} label='Pick A Test' options={this.state.testData} value={data.test_key}
        onChange={(e,d)=>this.updateTest(data,d)}/>
       <Form.Field control={Select} label='Pick A Quantity' options={quantityOption} value={data.quantity}
          onChange={(e,d)=>this.updateVal(data,d)}/>
       <Button disabled primary onClick={()=>this.removeTest(index)}>
           {" "}
           Remove{" "}
         </Button>
     </Form.Group>)
     else 
     formList.push(     <Form.Group widths='equal'>
       <Form.Field control={Select} label='Pick A Test' options={this.state.testData} value={data.test_key} 
       onChange={(e,d)=>this.updateTest(data,d)}/>
       <Form.Field control={Select} label='Pick A Quantity'  options={quantityOption} value={data.quantity} 
         onChange={(e,d)=>this.updateVal(data,d)}/>
       <Button primary onClick={()=>this.removeTest(index)}>
           {" "}
           Remove{" "}
         </Button>
     </Form.Group>)
    })
    console.log(this.state.currentSupply);
    var SupplyModal = (
      <Modal
        size="large"
        className="modal"
        open={this.state.ModalOpen}
        onClose={()=>this.setState({ModalOpen:false})}
        closeOnDimmerClick={false}
      >
        <Modal.Header>{this.state.addNew?"Add New Supply":"Update Supply"}</Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Input
              label="Supply Name"
              className="nameField"
              placeholder="Name"
              value={this.state.currentSupply.name}
              onChange={(e, d) => this.updateName(d)}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Price"
              className="nameField"
              placeholder="Price"
              value={this.state.currentSupply.unit_price}
              onChange={(e, d) => this.updatePrice(d)}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Alert Limit"
              className="nameField"
              placeholder="Alert Limit"
              value={this.state.currentSupply.alert_limit}
              onChange={(e, d) => this.updateLimit(d)}
            />
          </div>
          <Form>
            {formList}
        </Form>
         <div>
            {" "}
            <Button primary onClick={this.addSupply}>
              {" "}
              Add Test {" "}
            </Button>
            <Button color='green' onClick={()=>this.submitSupply()}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={()=>this.setState({ModalOpen:false})}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );
  
    return (
      <div className="TestBlock">
        <Header as="h1">Supply Management</Header>
        <Button className='addNew'  onClick={this.handleAddSupply} color='green'> Add Supply</Button>
        {SupplyModal}
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine> Supply Name </Table.HeaderCell>
              <Table.HeaderCell singleLine> Unit Price </Table.HeaderCell>
              <Table.HeaderCell singleLine> Alert Limit </Table.HeaderCell>
              <Table.HeaderCell singleLine> Test </Table.HeaderCell>
              <Table.HeaderCell singleLine> Quantity </Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{listTableRows}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
      </div>
    );
  }
}
