import React, { Component } from "react";
import { Header, Table, Button, Modal, Input, Message } from "semantic-ui-react";
import _ from "underscore";
import "./AdminTest.css";
import Errorpop from "../error/Errorpop";

export default class AdminTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testData: [],
      addTestOpen: null,
      newTest: "",
      newCode: "",
      newDur: 0,
      newMethod: '',
      newCfmMethod: '',
      maxkey: null,
      updTestOpen: null,
      currkey: null,
      hidemsg: true
    };
    this.handleAddTest = this.handleAddTest.bind(this);
    this.handleDelTest = this.handleDelTest.bind(this);
    this.submitNewTest = this.submitNewTest.bind(this);
    this.testChange = this.testChange.bind(this);
    this.codeChange = this.codeChange.bind(this);
    this.durChange = this.durChange.bind(this);
    this.errorpop = React.createRef();
    this.openUpdTest=this.openUpdTest.bind(this);
    this.handleEditTest=this.handleEditTest.bind(this);
    this.submitUpdTest=this.submitUpdTest.bind(this);
    this.submitUpd=this.submitUpd.bind(this);
  }


  openAddTest = () => {
    this.setState({ addTestOpen: true });
  };

  closeAddTest = () => {
    this.setState({ addTestOpen: false });
    this.setState({hidemsg:true});
    this.setState({
      newTest: "", newCode: "", newDur: "",
       newMethod: ""
     });
  };

  openUpdTest = key => {
    this.setState({
      newTest: _.where(this.state.testData, { key: key })[0].text,
      newCode: _.where(this.state.testData, { key: key })[0].code,
      newDur: _.where(this.state.testData, { key: key })[0].dflt_dur,
      newMethod: _.where(this.state.testData, { key: key })[0].test_method,
      currkey: key,
      updTestOpen: true
    });
  };

  closeUpdTest = () => {
    this.setState({ updTestOpen: false });
    this.setState({hidemsg:true});
    this.setState({
      newTest: "", newCode: "", newDur: "",
       newMethod: ""
     });
  };

  handleEditTest = key => {
    this.openUpdTest(key);
    //  console.log(this.state.testData)
  };

  handleAddTest = () => {
    this.openAddTest();
  };

  testChange(event) {
    this.setState({ newTest: event.target.value });
  }

  codeChange(event) {
    this.setState({ newCode: event.target.value.toUpperCase() });
  }

  methodChange = (event) => {
    this.setState({ newMethod: event.target.value });
  }

  cfmmethodChange = (event) => {
    this.setState({ newCfmMethod: event.target.value });
  }

  durChange(event) {
    if (!isNaN(event.target.value))
      this.setState({ newDur: event.target.value });
  }


  checkDupTest = async (code,key) => {
    const response = await fetch("/api/checkTestDup?code="+code+"&key="+key, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    }
  );
    const body = await response.json();
    //    if (response.status !== 200) throw Error(body.message);
    return body;
  };


  submitNewTest() {
    console.log(this.state.newTest);
    if (this.state.newTest==='' || this.state.newCode==='')
    {
    this.setState({errmsg:'Test Name or Code is empty'});
     this.setState({hidemsg:false});
     return true;
  }
  else if (this.state.newCode.length>5) 
  {
    this.setState({errmsg:'Test Code should be no longer than 5 characters'});
     this.setState({hidemsg:false});
     return true;
  }
  this.setState({hidemsg:true});
  this.checkDupTest(this.state.newCode,this.state.maxkey + 1).then((res)=>{
    if (res[0].cnt>0)     {
     this.setState({errmsg:'Test Code Already Exists'});
      this.setState({hidemsg:false});
      return true;
   }
    this.addTests(
      this.state.maxkey + 1,
      "t" + (this.state.maxkey + 1),
      this.state.newTest,
      this.state.newCode,
      this.state.newDur,
      this.state.newMethod,
      this.state.newCfmMethod
    ).then(result => this.submitAdd(result));
  })
  }

  submitAdd = result => {
    if (result.status === "ERROR") {
      console.log("error");
      this.errorpop.current.handleOpen();
    } else {
      // console.log(this.state.newTest + "/" + this.state.newCode);
      var olddata = this.state.testData;
      olddata.push({
        key: this.state.maxkey + 1,
        value: "t" + (this.state.maxkey + 1),
        text: this.state.newTest,
        code: this.state.newCode,
        dflt_dur: this.state.newDur,
        test_method: this.state.newMethod,
        confirm_method: this.state.newCfmMethod
      });
      this.setState({
        testData: olddata,
        newTest: "",
        newCode: "",
        maxkey: this.state.maxkey + 1,
        newDur: 0,
        newMethod: "",
        newCfmMethod: ""
      });
      // console.log(olddata);
      this.closeAddTest();
    }
  };

  submitUpdTest() {
    // console.log(this.state.currkey);
    // console.log(this.state.newDur);

    if (this.state.newTest==='' || this.state.newCode==='')
    {
    this.setState({errmsg:'Test Name or Code is empty'});
     this.setState({hidemsg:false});
     return true;
  }
  else if (this.state.newCode.length>5) 
  {
    this.setState({errmsg:'Test Code should be no longer than 5 characters'});
     this.setState({hidemsg:false});
     return true;
  }
  this.setState({hidemsg:true});

  this.checkDupTest(this.state.newCode,this.state.currkey).then((res)=>{
    if (res.status === 'ERROR') {
      console.log('error');
      this.errorpop.current.handleOpen();
      return true;
    }
    if (res[0].cnt>0)     {
     this.setState({errmsg:'Test Code Already Exists'});
      this.setState({hidemsg:false});
      return true;
   }

    this.updTest(
      this.state.currkey,
      "t"+this.state.currkey,
      this.state.newTest,
      this.state.newCode,
      this.state.newDur,
      this.state.newMethod,
      this.state.newCfmMethod
    ).then(result => this.submitUpd(result));
  })
  }


  submitUpd = result => {
    if (result.status === "ERROR") {
      
      this.errorpop.current.handleOpen();
    } else {
     // console.log(this.state.newCustNm + "/" + this.state.newCode);
      var olddata = this.state.testData;
      for (var i in olddata) {
        if (olddata[i].key === this.state.currkey) {
          olddata[i].text = this.state.newTest;
          olddata[i].code = this.state.newCode;
          olddata[i].dflt_dur = this.state.newDur;
          olddata[i].test_method = this.state.newMethod;
          olddata[i].confirm_method= this.state.newCfmMethod
        }
      }
      // console.log(olddata);
      this.setState({
        testData: olddata,
        newTest: "",
        newCode: "",
        newDur: 0,
        newMethod: "",
        newCfmMethod: ""
      });
      // console.log(olddata);
      this.closeUpdTest();
    }
  };

  updTest = async (key, value, text, code, dur,meth,cfm) => {
    const response = await fetch("/api/updTest", {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        key: key,
        value: value,
        text: text,
        code: code,
        dur: dur,
        meth: meth,
        cfm: cfm
      })
    });
    const body = await response.json();
    //    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  handleDelTest = key => {
    this.delTests(key).then(result => this.submitDel(result, key));
    //  console.log(this.state.testData)
  };

  submitDel = (result, key) => {
    var testList = this.state.testData;
    if (result.status === "ERROR") {
      console.log("error");
      this.errorpop.current.handleOpen();
    } else {
      testList = _.reject(testList, function(data) {
        return data.key === key;
      });
      this.setState({ testData: testList });
    }
  };

  addTests = async (key, value, text, code, dur,meth, cfm) => {
    const response = await fetch(
      "/api/addTest?key=" +
        key +
        "&value=" +
        value +
        "&text=" +
        text +
        "&code=" +
        code +
        "&dur=" +
        dur + "&meth="+ meth+"&cfm="+cfm,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }
    );
    const body = await response.json();
    //    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  getTests = async () => {
    const response = await fetch("/api/getTest", {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  delTests = async key => {
    const response = await fetch("/api/delTest?key=" + key, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      }
    });
    const body = await response.json();
    if (response.status !== 200) throw Error(body.message);
    return body;
  };

  componentDidMount() {
    this.getTests().then(res => {
      console.log(res);
      this.setState({ testData: res });
      var maxkeynow = parseInt(
        _.max(res, function(ele) {
          return ele.key;
        }).key
      );
      if (maxkeynow) this.setState({maxkey:maxkeynow})
      else this.setState({maxkey:0});
     // this.setState({ maxkey: maxkeynow });
    });
  }

  render() {
    var testTableRows = this.state.testData.map((data, index) => {
      return (
        <Table.Row key={"t" + index}>
          <Table.Cell>{data.text}</Table.Cell>
          <Table.Cell>{data.code}</Table.Cell>
          <Table.Cell>{data.dflt_dur}</Table.Cell>
          <Table.Cell>{data.test_method}</Table.Cell>
          <Table.Cell>{data.confirm_method}</Table.Cell>
          <Table.Cell>
            <Button onClick={() => this.handleEditTest(data.key)}>
              {" "}
              Edit Test{" "}
            </Button>
            <Button onClick={() => this.handleDelTest(data.key)}>
              {" "}
              Remove Test{" "}
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    });

    var UpdTestModal = (
      <Modal
        size="small"
        className="modal"
        open={this.state.updTestOpen}
        onClose={this.closeUpdTest}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Edit New Test</Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Header as="h3"> Please Edit New Test </Header>{" "}
          </div>
          <div>
            {" "}
            <Input
              label="Test Name"
              className="nameField"
              placeholder="Name"
              value={this.state.newTest}
              onChange={this.testChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Test Code"
              className="passField"
              placeholder="Code"
              value={this.state.newCode}
              onChange={this.codeChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Default Duration (Hours)"
              className="nameField"
              placeholder="Dur"
              value={this.state.newDur}
              onChange={this.durChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Test Method"
              className="methodField"
              placeholder="Method"
              value={this.state.newMethod?this.state.newMethod:''}
              onChange={this.methodChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Confirm Method"
              className="methodField"
              placeholder="Confirm Method"
              value={this.state.newCfmMethod?this.state.newCfmMethod:''}
              onChange={this.cfmmethodChange}
            />
          </div>
          <Message
      error
      header='Action Forbidden'
      content={this.state.errmsg}
      hidden={this.state.hidemsg}
    />    
          <div>
            {" "}
            <Button primary onClick={this.submitUpdTest}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeUpdTest}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    var AddTestModal = (
      <Modal
        size="small"
        className="modal"
        open={this.state.addTestOpen}
        onClose={this.closeAddTest}
        closeOnDimmerClick={false}
      >
        <Modal.Header>Create New Test</Modal.Header>
        <Modal.Content>
          <div>
            {" "}
            <Header as="h3"> Please Add New Test </Header>{" "}
          </div>
          <div>
            {" "}
            <Input
              label="Test Name"
              className="nameField"
              placeholder="Name"
              value={this.state.newTest}
              onChange={this.testChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Test Code"
              className="passField"
              placeholder="Code"
              value={this.state.newCode}
              onChange={this.codeChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Default Duration (Hours)"
              className="nameField"
              placeholder="Dur"
              value={this.state.newDur}
              onChange={this.durChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Test Method"
              className="methodField"
              placeholder="Method"
              value={this.state.newMethod}
              onChange={this.methodChange}
            />
          </div>
          <div>
            {" "}
            <Input
              label="Confirm Method"
              className="methodField"
              placeholder="Confirm Method"
              value={this.state.newCfmMethod}
              onChange={this.cfmmethodChange}
            />
          </div>
          <Message
      error
      header='Action Forbidden'
      content={this.state.errmsg}
      hidden={this.state.hidemsg}
    />    
          <div>
            {" "}
            <Button primary onClick={this.submitNewTest}>
              {" "}
              OK{" "}
            </Button>
            <Button secondary onClick={this.closeAddTest}>
              {" "}
              Cancel{" "}
            </Button>
          </div>
        </Modal.Content>
      </Modal>
    );

    return (
      <div className="TestBlock">
        <Header as="h1">Test Management</Header>
        <Button onClick={this.handleAddTest}> Add Test</Button>
        {AddTestModal}
        {UpdTestModal}
        <Table celled padded>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine> Test Name </Table.HeaderCell>
              <Table.HeaderCell singleLine> Test Code </Table.HeaderCell>
              <Table.HeaderCell singleLine> Default Hours </Table.HeaderCell>
              <Table.HeaderCell singleLine> Test Method </Table.HeaderCell>
              <Table.HeaderCell singleLine> Confirm Method </Table.HeaderCell>
              <Table.HeaderCell>Action</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>{testTableRows}</Table.Body>
        </Table>
        <Errorpop ref={this.errorpop} />
      </div>
    );
  }
}
